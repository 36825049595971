import styles from "./ShareModal.module.scss";
import CopyIcon from "../../icons/discount/copy";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setShareModal} from "../../../store/slices/modals.slice";
import {copyToClipboard} from "../../../utils/helpers";

const ShareModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={styles.modal} onClick={() => dispatch(setShareModal(false))}>
      <div className={styles.modalContent}>
        <div className={styles.textWrapper}>
          <div className={styles.modalTitle}>{t('linkModal')}</div>
          <div className={styles.modalDescription}>https://hiviewer.com/details</div>
        </div>
        <div
          className={styles.copyIconWrapper}
          onClick={() => {
            copyToClipboard('https://hiviewer.com/details')
            dispatch(setShareModal(false))
          }}
        >
          <CopyIcon/>
        </div>
      </div>
    </div>
  )
};

export default ShareModal;
