import { useState } from "react"

import styles from "../../_styles/InfoBlock.module.scss"
import ChooseInfoBlock from "../rightData/ChooseInfoBlock";
import ButtonIcon from "../../../../components/icons/ButtonIcon";
import {useGetVideoDetailsQuery} from "../../../../store/api/filmAPI";
import {EN, UA} from "../../../../constants/local";
import {useTranslation} from "react-i18next";
import useLikeClick from "../../../../hooks/useLikeClick";
import useFavouriteClick from "../../../../hooks/useFavouriteClick";
import {useDispatch} from "react-redux";
import {setShareModal} from "../../../../store/slices/modals.slice";

interface IProps {
  voteCount: number
  currentItemId: number
  clickHandler: () => void
  inViewport: boolean,
  item: any
}

const ChooseFooter = ({
  voteCount,
  currentItemId,
  clickHandler,
  inViewport,
  item
}: IProps) => {
  const [hoverButton, setHoverButton] = useState(false)

  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { likesList, likeHandler } = useLikeClick();
  const { favoritesList, favouritesHandler } = useFavouriteClick();

  const { data, isLoading } = useGetVideoDetailsQuery({
    queries: { language: i18n.language === 'en' ? EN : UA },
    id: currentItemId,
  },
    {
      skip: !inViewport
    }
  )
  if (isLoading) {
    return <div />
  }

  const shareHandler = () => {
    dispatch(setShareModal(true))
  }


  return (
    <div className={styles.footerContent}>
      <ChooseInfoBlock
        item={item}
        voteCount={voteCount}
        likeHandler={likeHandler}
        liked={likesList.data?.results.includes(data?.id)}
        reviewHandler={clickHandler}
        favouritesHandler={favouritesHandler}
        shareHandler={shareHandler}
        inFavourites={favoritesList.data?.results.includes(data?.id)}
      />
      <button
        onClick={clickHandler}
        className={styles.footerContentButton}
        onMouseLeave={() => setHoverButton(false)}
        onMouseEnter={() => setHoverButton(true)}>
        <ButtonIcon stroke={hoverButton} />
      </button>
    </div>
  )
}

export default ChooseFooter
