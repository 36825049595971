const NotFound = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={83}
      height={84}
      fill="none"
    >
      <path
        stroke="#282828"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={7}
        d="M3.5 23v-9.5A9.5 9.5 0 0 1 13 4h9.5m-19 57v9.5A9.5 9.5 0 0 0 13 80h9.5m38-76H70a9.5 9.5 0 0 1 9.5 9.5V23m-19 57H70a9.5 9.5 0 0 0 9.5-9.5V61M27.25 32.5h.047m28.453 0h.047M29.625 56.487a16.626 16.626 0 0 1 23.75 0"
      />
    </svg>
  )
}
export default NotFound;
