import styles from '../styles/profile.module.scss';
import Button from "../../../components/shared/Button/Button";
import {useDispatch} from "react-redux";
import {setOpenModal} from "../../../store/slices/modals.slice";
import {useTranslation} from "react-i18next";

const CardRemoveModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const removeHandler = () => {
    dispatch(setOpenModal('cardRemoveConfirm'))
  }

  return (
    <div className={styles.removeCardModalWrapper}>
      <div className={styles.removeCardModalTextContent}>
        <div>{t('removeCardConfirmTitle')}</div>
      </div>
      <div className={styles.removeCardModalDescr}>{t('removeCardConfirmDesc')}</div>
      <div>
        <Button title={t('cancel')} submit={() => {}} className={styles.removeCardModalSubmit}/>
        <Button title={t('remove')} submit={removeHandler} className={styles.removeCardModalCancel}/>
      </div>
    </div>
  )
}

export default CardRemoveModal;
