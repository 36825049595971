import React from "react";
import styles from "./UsersPage.module.scss";
import useWindowSize from "../../../hooks/useWindowSize";
import { ROUTES } from "../../../constants/routes";
import Header from "../../../components/header/Header";
import AdminSideBar from "../../../components/admin/AdminSideBar/AdminSideBar";
import { GridSection } from "../../../components/admin/GridSection/GridSection";
import AdminFooter from "../../../components/admin/AdminFooter/AdminFooter";
import Footer from "../../../components/footer/Footer";

const USERS = [
  { title: "Нові реєстрації", counter: "1 072" },
  { title: "Підключені", counter: "1 050" },
  { title: "Не підключені", counter: "22" },
];

const UsersPage = () => {
  const { windowSize } = useWindowSize();
  const isNotMobile = windowSize !== "mobile";

  return (
    <section className={styles.wrapper}>
      <Header className={styles.headerWrapper} backButtonClassName={styles.backButton} />
      {isNotMobile && <AdminSideBar />}
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <GridSection withFilter={true} sectionTitle="Користувачі" data={USERS} withBorderBottom={false} />
        </div>
      </div>
      {isNotMobile ? <Footer source={ROUTES.USERS} /> : <AdminFooter />}
    </section>
  );
};

export default UsersPage;
