interface IProps {
  className?: string
}
const FilterHeaderHash = ({className}: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
    >
      <path
        className={className}
        fill="#888"
        d="M16 5c0-.55-.45-1-1-1h-3V1c0-.55-.45-1-1-1s-1 .45-1 1v3H6V1c0-.55-.45-1-1-1S4 .45 4 1v3H1c-.55 0-1 .45-1 1s.45 1 1 1h3v4H1c-.55 0-1 .45-1 1s.45 1 1 1h3v3c0 .55.45 1 1 1s1-.45 1-1v-3h4v3c0 .55.45 1 1 1s1-.45 1-1v-3h3c.55 0 1-.45 1-1s-.45-1-1-1h-3V6h3c.55 0 1-.45 1-1Zm-6 5H6V6h4v4Z"
      />
    </svg>
  )
}

export default FilterHeaderHash;
