import { ComponentProps } from "react";

interface IProps extends ComponentProps<"svg"> {}

const Cooperation3 = ({ ...props }: IProps) => (
  <svg width="100%" height="auto" viewBox="0 0 220 140" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.5 22.5H219.5V132C219.5 136.142 216.142 139.5 212 139.5H8.00001C3.85787 139.5 0.5 136.142 0.5 132V22.5Z"
          fill="#222222" stroke="#333333"/>
    <rect x="163" y="38" width="20" height="10" rx="2" fill="#444444"/>
    <rect x="188" y="38" width="22" height="10" rx="2" fill="#444444"/>
    <rect x="163" y="53" width="33" height="10" rx="2" fill="#444444"/>
    <rect x="163" y="68" width="15" height="10" rx="2" fill="#444444"/>
    <rect x="183" y="68" width="27" height="10" rx="2" fill="#444444"/>
    <path d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H212C216.142 0.5 219.5 3.85786 219.5 8V22.5H0.5V8Z" fill="#111111"
          stroke="#333333"/>
    <circle cx="10.5" cy="11.5" r="1.5" fill="#555555"/>
    <circle cx="16.5" cy="11.5" r="1.5" fill="#555555"/>
    <circle cx="22.5" cy="11.5" r="1.5" fill="#555555"/>
    <rect x="15" y="84" width="133" height="15" rx="4" fill="#E50914"/>
    <rect x="49" y="47" width="13" height="3" fill="#444444"/>
    <rect x="49" y="54" width="25" height="3" fill="#444444"/>
    <rect x="49" y="61" width="19" height="3" fill="#444444"/>
    <rect x="15" y="38" width="24" height="36" rx="4" fill="#444444"/>
    <rect x="49" y="118" width="13" height="3" fill="#444444"/>
    <rect x="49" y="125" width="25" height="3" fill="#444444"/>
    <rect x="49" y="132" width="19" height="3" fill="#444444"/>
    <path d="M15 113C15 110.791 16.7909 109 19 109H35C37.2091 109 39 110.791 39 113V139H15V113Z" fill="#444444"/>
    <rect x="123" y="47" width="13" height="3" fill="#444444"/>
    <rect x="123" y="54" width="25" height="3" fill="#444444"/>
    <rect x="123" y="61" width="19" height="3" fill="#444444"/>
    <rect x="89" y="38" width="24" height="36" rx="4" fill="#444444"/>
    <rect x="123" y="118" width="13" height="3" fill="#444444"/>
    <rect x="123" y="125" width="25" height="3" fill="#444444"/>
    <rect x="123" y="132" width="19" height="3" fill="#444444"/>
    <path d="M89 113C89 110.791 90.7909 109 93 109H109C111.209 109 113 110.791 113 113V139H89V113Z" fill="#444444"/>
  </svg>

);
export default Cooperation3;
