import axiosInstance from "../../api/axios";

const useLikes = () => {
  const like = async (id: number) => {
    try {
      await axiosInstance.post(`/movies/${id}/likes`);
    } catch (e) {
      console.log(e, 'e');
    }
  }
  const unLike = async (id: number) => {
    try {
      await axiosInstance.delete(`/movies/${id}/likes`);
    } catch (e) {
      console.log(e, 'e');
    }
  }

  return {
    like,
    unLike
  }
};

export default useLikes;
