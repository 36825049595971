const AvatarBg = () => {
return (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={80}
    fill="none"
  >
    <circle cx={40} cy={40} r={40} fill="url(#a)"/>
    <defs>
      <linearGradient
        id="a"
        x1={80}
        x2={0}
        y1={0}
        y2={81.52}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FB6801"/>
        <stop offset={1} stopColor="#ED0A52"/>
      </linearGradient>
    </defs>
  </svg>
)
}

export default AvatarBg;
