import classnames from "classnames";
import styles from './Button.module.scss';

interface IProps {
  title: string,
  submit: any,
  className?: string,
  disabled?: boolean
}
const Button = ({
  title,
  submit,
  className,
  disabled
}: IProps) => {
  return (
    <button
      disabled={disabled}
      className={classnames(styles.button, className)}
      onClick={submit}
    >
      {title}
    </button>
  )
};

export default Button;
