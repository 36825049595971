import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type DefaultState = {
  currentTab: number
}

const initialState: DefaultState = {
  currentTab: 1,
}

export const NavigationSlice = createSlice({
  name: "navigation",
  initialState: initialState,
  reducers: {
    setAccountTab: (state, action: PayloadAction<number>) => {
      state.currentTab = action.payload
    },
  },
})

export default NavigationSlice.reducer
export const { setAccountTab } = NavigationSlice.actions
