import React from 'react';
import styles from '../styles/Details.module.scss';
interface IProps {
  title: string;
}
const Heading = ({title}: IProps) => {
  return (
    <div className={styles.headingText}>{title}</div>
  )
}

export default Heading
