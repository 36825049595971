const LikedRevItemWite = ({extraClass}: {extraClass: string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={18}
      fill="none"
      className={extraClass}
    >
      <path
        fill="#FAFAFA"
        d="M12.364 6.51h5.818C19.186 6.51 20 7.3 20 8.278v1.86c0 .231-.047.46-.137.674l-2.813 6.643a.91.91 0 0 1-.84.548H.91a.897.897 0 0 1-.91-.885v-8.84c0-.488.407-.884.91-.884h3.164c.296 0 .573-.14.743-.374L9.775.19a.463.463 0 0 1 .574-.14L12 .85c.955.465 1.45 1.515 1.186 2.522l-.822 3.137Z"
      />
    </svg>
  )
}

export default LikedRevItemWite;
