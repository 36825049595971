import {routesWithoutFiltersHandler} from "../../constants/structure";
import { useLocation } from "react-router-dom";
import { PropsWithChildren } from "react";

interface IProps extends PropsWithChildren{
  hasFilter?: boolean;
  source?: string;
  isMobile?: boolean;
}

export const FilterLayout = ({ children, source, isMobile }: IProps) => {
  const { pathname } = useLocation();

  if (source === 'modal') {
    return null
  }

  return !routesWithoutFiltersHandler(isMobile as boolean).includes(pathname) ? <>{children}</> : null;
};
