import {useGetCurrentVideoQuery} from "../store/api/filmAPI";
import {EN} from "../constants/local";

const useFilmsEN = (id: number, allowed: boolean) => {
  const { data, isLoading } = useGetCurrentVideoQuery({
      queries: { language: EN },
      id: id
    },
    {
      skip: !allowed
    }
  );


  return { enData: data, isLoading }
};

export default useFilmsEN;
