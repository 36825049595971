import styles from "./FiltersHeader.module.scss";
import FilterHeaderFilter from "../../../../components/icons/forYou/FilterHeaderFilter";
import {useAppSelector} from "../../../../store/store";

interface IProps {
  goToFilters: () => void
}

const FiltersButton = ({ goToFilters }: IProps) => {
  const genres = useAppSelector((state: any) => state.filters.genres)
  const period = useAppSelector((state: any) => state.filters.period)

  const filterCounterHandler = (gen: number[], per: number[]) => {
    let counter = 0;
    if (per[0] !== 1980 ||  per[1] !== 2024) {
      counter += 1;
    };

    counter += gen.length;

    return counter;
  }
  return (
    <div
      onClick={goToFilters}
      className={`
        ${styles.filterButtonWrapper}
        ${filterCounterHandler(genres, period) !== 0 ? styles.filterButtonWrapperActive : ''}
      `}
    >
      <FilterHeaderFilter className={styles.iconClass}/>
      {filterCounterHandler(genres, period) !== 0
        ? <div className={styles.filtersButtonCounter}>{
          filterCounterHandler(genres, period)
        }</div> : <div/>}
    </div>
  )
};

export default FiltersButton;
