import React from "react";
import classNames from "classnames";

import styles from "./LikeButton.module.scss";
import LikeIcon from "../../icons/innerPageIcons/like";

type Direction = "column" | "row";

interface ILikeButtonProps {
  isMobile: boolean;
  direction: Direction;
  className?: string;
  count?: number;
  clickHandler: (e: any, item: any, liked: boolean) => void;
  liked: boolean;
  item: any;
}

const LikeButton = ({
  isMobile,
  direction,
  className,
  count,
  clickHandler,
  liked,
  item
}: ILikeButtonProps) => {
  const wrapperMods = classNames(styles.likesWrapper, className, {
    [styles.likesWrapperRow]: direction === "row",
    [styles.likesWrapperColumn]: direction === "column",
  });


  return (
    <div className={wrapperMods} onClick={(e) => clickHandler(e, item, liked)}>
      <LikeIcon className={`${styles.likeIcon} ${liked ? styles.likedIcon : ''}`} />
      <p className={styles.counter}>{count}</p>
    </div>
  );
};

export default LikeButton;
