import FormInput from "../FormInput/FormInput";
import styles from './PhoneEmailInput.module.scss';
import {useTranslation} from "react-i18next";

interface IProps {
  value: string;
  setValue: (value: string) => void;
  error: string;
}

const PhoneEmailInput = ({ value, setValue, error }: IProps) => {
  const {t} = useTranslation();

  return (
    <div className={styles.inputWrapper}>
      {error && <div className={styles.errorMsg}>{error}</div>}
      <FormInput
        setValue={setValue}
        value={value}
        placeHolder={t('phoneEmail')}
        name={t('phoneEmail')}
      />
    </div>
  )
}

export default PhoneEmailInput;
