import React from 'react';
import styles from "./MobileComponents.module.css";
import MutedIconBg from "../../../../components/icons/MutedIconBg";
import { CSSTransition } from 'react-transition-group';
import LoaderIcon from '../../../../components/icons/mainLoader/loader.svg';
import useWindowSize from "../../../../hooks/useWindowSize";

interface IProps {
  ready: boolean,
  setMuted: any,
  muted: any,
  mutedIconHandler: () => void,
  forcePlayHAndler?: () => void
}

const MobileCover = ({ ready, setMuted, muted, mutedIconHandler, forcePlayHAndler }: IProps) => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";
  const loaderSize = isMobile
    ? {width: '170px', height: '53px'} : {width: '340px', height: '105px'}

  return (
    <>
      <div
        className={styles.cover}
        onClick={(e) => {
          if (ready) {
            e.stopPropagation();
            e.preventDefault();
            setMuted(!muted);
            mutedIconHandler();
          }

          if (forcePlayHAndler) {
            forcePlayHAndler();
          }
        }}
      >
        <CSSTransition
          in={ready && muted}
          timeout={300}
          classNames={{
            enter: styles.fadeEnter,
            enterActive: styles.fadeEnterActive,
            exit: styles.fadeExit,
            exitActive: styles.fadeExitActive,
          }}
          unmountOnExit
        >
          <div className={styles.mutedIconWrapper}>
            <MutedIconBg/>
          </div>
        </CSSTransition>
        {!ready && <div className={styles.loaderBg}>
          <object type="image/svg+xml" data={LoaderIcon} style={loaderSize}>svg-animation</object>
        </div>}
      </div>
    </>
  );
};

export default MobileCover;
