const Search = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={76}
      height={76}
      fill="none"
    >
      <path
        fill="#282828"
        fillRule="evenodd"
        d="M34.604 5.117a29.487 29.487 0 0 0-20.85 50.34 29.487 29.487 0 1 0 41.7-41.703 29.487 29.487 0 0 0-20.85-8.637Zm-24.469 5.019a34.603 34.603 0 0 1 50.692 47.048L76 72.385 72.379 76 57.21 60.804A34.603 34.603 0 0 1 0 34.605c0-9.178 3.646-17.98 10.135-24.47Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Search;
