import axiosInstance from "../api/axios";
import {useDispatch} from "react-redux";
import {setUserData} from "../store/api/authSlice";

interface UserProfile {
  email: string;
  username: string;
  birth_day: string | null;
  birth_month: string | null;
  birth_year: string | null;
  address: string | null;
  sex: 'male' | 'female';
  active: boolean;
  fname: string | null;
  lname: string | null;
  accept_notifications: boolean;
  picture_url: string | null;
}

const useAccount = () => {
  const dispatch = useDispatch();

  const getAccountDetails = async () => {
    try {
      const response = await axiosInstance.get<UserProfile>('/user/profile/');
      console.log(response, 'response')
      dispatch(setUserData(response.data));
    } catch (e) {
      console.log(e);
    }
  }

  const updateAccountDetails = async (data: any) => {
    try {
      const response = await axiosInstance.patch<UserProfile>('/user/profile/', data);
      dispatch(setUserData(response.data));
    } catch (e) {
      console.log(e);
    }
  }

  return {
    getAccountDetails,
    updateAccountDetails
  }
};

export default useAccount;
