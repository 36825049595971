const CloseModalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
    >
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeWidth={2}
        d="M15 15 1 1m14 0L1 15"
      />
    </svg>
  )
}

export default CloseModalIcon;
