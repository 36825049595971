import styles from "./AdminSideBar.module.scss";
import { adminSideBarMenuItems } from "../../../constants/structure";
import MenuWrapper from "../../semantic/MenuWrapper";
import AdminSideBarIcon from "./AdminSideBarIcon";
import { Link, useLocation } from "react-router-dom";

const AdminSideBar = () => {
  const { pathname } = useLocation();

  const focusHandler = () => {
    const chooseWrapper = document.getElementById("choose_section");

    if (chooseWrapper) {
      chooseWrapper.focus();
    }
  };

  return (
    <div className={styles.wrapper} onClick={focusHandler}>
      <div className={styles.topSidebarWrapper}>
        <MenuWrapper>
          {adminSideBarMenuItems.map((item: any) => (
            <li key={item.title} className={styles.itemSemanticWrapper}>
              <Link to={`/${item.href}`} className={styles.itemWrapper}>
                <AdminSideBarIcon iconName={item.path} selectedItem={pathname === `/${item.path}`} />
                <div className={`${styles.itemTitle} ${pathname === `/${item.path}` ? styles.itemTitleActive : ""}`}>
                  {item.title}
                </div>
              </Link>
            </li>
          ))}
        </MenuWrapper>
      </div>
    </div>
  );
};

export default AdminSideBar;
