const SupportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
    >
      <path
        fill="#888"
        d="M13.773 5.09h.773c.803 0 1.454.652 1.454 1.455v2.91c0 .803-.651 1.454-1.454 1.454h-.773A5.82 5.82 0 0 1 8 16v-1.454a4.364 4.364 0 0 0 4.364-4.364V5.818a4.364 4.364 0 1 0-8.728 0v5.091H1.455A1.455 1.455 0 0 1 0 9.455v-2.91c0-.803.651-1.454 1.455-1.454h.772a5.82 5.82 0 0 1 11.546 0ZM1.455 6.546v2.91h.727v-2.91h-.727Zm12.363 0v2.91h.728v-2.91h-.728Zm-8.902 4.208.771-1.234c.67.42 1.463.663 2.313.663.85 0 1.642-.243 2.313-.663l.771 1.234A5.791 5.791 0 0 1 8 11.636a5.79 5.79 0 0 1-3.084-.883Z"
      />
    </svg>
  )
}

export default SupportIcon;
