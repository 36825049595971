import React, { FC } from "react";

import styles from "./Heading.module.scss";
import classnames from "classnames";
import ContentSwitcher from "../ContentSwitcher/ContentSwitcher";

export interface HeadingProps {
  title: string;
  counter?: string;
  classNameTitle?: string;
  classNameWrapper?: string;
  contentSwitcher?: boolean;
  setActiveTab?: (tab: string) => void;
  activeTab?: string;
  videosCounter?: number;
  imgsCounter?: number;
}

const Heading: FC<HeadingProps> = ({
  title,
  counter,
  classNameTitle,
  classNameWrapper,
  contentSwitcher,
  setActiveTab,
  activeTab,
  videosCounter,
  imgsCounter,
}) => {
  const hasCounter = Boolean(counter);

  return (
    <div className={styles.headingWrapper}>
      {hasCounter ? (
        <div className={classnames(styles.wrapper, classNameWrapper)}>
          <h2 className={classnames(styles.title, classNameTitle)}>{title}</h2>
          <p className={styles.counter}>{counter ? counter : 0}</p>
        </div>
      ) : (
        <h2 className={classnames(styles.title, classNameTitle)}>{title}</h2>
      )}
      {contentSwitcher && (
        <ContentSwitcher
          videosCounter={videosCounter}
          imgsCounter={imgsCounter}
          setActiveTab={setActiveTab as (tab: string) => void}
          activeTab={activeTab}
        />
      )}
    </div>
  );
};

export default Heading;
