import styles from "../../foryou/_components/DesctopComponents/Media.module.scss";
import Heading from "../../../components/shared/Heading";
import React from "react";
import {useTranslation} from "react-i18next";
import AddReview from "../../../components/icons/details/AddReview";
import LikeRevItem from "../../../components/icons/details/LikeRevItem";
import useWindowSize from "../../../hooks/useWindowSize";
import {truncateString} from "../../../utils/helpers";
import DotsLoader from "../../../components/icons/DotsLoader";
import LikedRevItem from "../../../components/icons/details/LikedRevItem";

type Review = {
  id: number;
  external_id: string;
  author: string;
  content: string;
  created_at: string;
  updated_at: string;
  url: string;
  language: string;
  liked: boolean;
  replies: any[];
  user: null | User;
  like_count?: number;
};

type User = {
  id: number;
  name: string;
  avatarUrl?: string;
};


interface IProps {
  setOpenModal: any
  setCurrentReview: any
  data: Review[]
  loadingState: boolean
  likeReviewHandler: (id: number, liked:boolean) => void
}

const Reviews = ({
   setOpenModal,
   setCurrentReview,
   data,
   loadingState,
   likeReviewHandler
}: IProps) => {
  const { t } = useTranslation();
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";
  console.log(data, 'data')
  return (
    <div className={styles.wrapper}>
      <Heading
        title={t('reviews')}
        counter={`${data?.length || 0}`}
      />
      <div className={styles.cardWrapperRev}>
        {!isMobile && (
          <div
            onClick={() => {
              setCurrentReview(undefined)
              setOpenModal(true)
            }}
            className={styles.addReviewButton}
          >
            <AddReview/>
          </div>
        )}
        {loadingState ? (
          <DotsLoader/>
        ) : (
          data?.map((review: Review, index: number) => (
            <div
              key={index}
              className={styles.reviewCard}
              onClick={() => {
                setCurrentReview(review)
                setOpenModal(true)
              }}>
              <div className={styles.reviewText}>{truncateString(review?.content, isMobile ? 120 : 180)}</div>
              <div className={styles.authorWrapper}>
                <div className={styles.reviewAuthor}>
                  <div className={styles.av}>{review?.author[0]}</div>
                  <div className={styles.authorName}>{review?.author}</div>
                </div>
                {!isMobile && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                      likeReviewHandler(review.id, review.liked)
                    }}
                    className={styles.reviewVotes}
                  >
                    <LikedRevItem extraClass={`${styles.hoveredRev} ${review.liked && styles.likedRev}`} />
                    <LikeRevItem/>
                    {review?.like_count !== 0 && <span>{review?.like_count}</span>}
                  </div>
                )}
              </div>
            </div>
          ))
        )}
      </div>
      {isMobile && (
        <div
          onClick={() => {
            setCurrentReview(undefined)
            setOpenModal(true)
          }}
          className={styles.addReviewButtonMobile}
        >
          <AddReview/>
        </div>
      )}
    </div>
  )
}

export default Reviews;
