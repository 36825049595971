const masterLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={33}
      height={20}
      fill="none"
    >
      <path fill="#FF5F00" d="M20.962 17.861h-8.923V2.138h8.923v15.723Z"/>
      <path
        fill="#EB001B"
        d="M12.604 10c0-3.19 1.523-6.031 3.895-7.862A10.283 10.283 0 0 0 10.197 0C4.565 0 0 4.477 0 10s4.565 10 10.197 10c2.286.003 4.507-.75 6.302-2.139-2.371-1.83-3.895-4.672-3.895-7.861Z"
      />
      <path
        fill="#F79E1B"
        d="M33 10c0 5.523-4.565 10-10.197 10a10.286 10.286 0 0 1-6.303-2.138c2.372-1.831 3.895-4.672 3.895-7.862 0-3.19-1.523-6.03-3.895-7.861A10.286 10.286 0 0 1 22.803 0C28.435 0 33 4.477 33 10"
      />
    </svg>
  )
};

export default masterLogo
