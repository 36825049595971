import {DEFAULT_WHITE, MAIN_WHITE} from "../constants/colors";

export const stokeHandler = (hovered: boolean, selected: boolean): string => {
  if (selected) {
    return DEFAULT_WHITE;
  }
  if (hovered) {
    return MAIN_WHITE;
  }

  return ''
};

export const timeFormat = (duration: any) => {
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  let ret = "";
  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
};

export const debounce = (func: any, delay: number) => {
  let timeoutId: any;
  return function(...args: any[]) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}
export function truncateString(input: string, max: number): string {
  if (input.length > max) {
    return input.substring(0, max) + '...';
  } else {
    return input;
  }
}

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(() => {
  }).catch((err) => {
    console.error('Ошибка при копировании текста: ', err);
  });
};
