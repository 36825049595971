import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type DefaultState = {
  modal: string,
  successToast: boolean,
  shareModal: boolean,
}

const initialState: DefaultState = {
  modal: '',
  successToast: false,
  shareModal: false,
}

export const ModalSlice = createSlice({
  name: "modals",
  initialState: initialState,
  reducers: {
    setOpenModal: (state, action: PayloadAction<string>) => {
      state.modal = action.payload
    },
    setSuccessToast: (state, action: PayloadAction<boolean>) => {
      state.successToast = action.payload
    },
    setShareModal: (state, action: PayloadAction<boolean>) => {
      state.shareModal = action.payload
    }
  },
})

export default ModalSlice.reducer
export const { setOpenModal, setSuccessToast, setShareModal } = ModalSlice.actions
