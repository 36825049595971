const Selected = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={77}
      height={100}
      fill="none"
    >
      <path
        fill="#282828"
        fillRule="evenodd"
        d="M.5 3.333C.5 1.493 1.98 0 3.804 0h69.392C75.02 0 76.5 1.492 76.5 3.333v93.334a3.34 3.34 0 0 1-1.558 2.83 3.28 3.28 0 0 1-3.205.16L38.5 83.163 5.263 99.658a3.28 3.28 0 0 1-3.205-.162A3.34 3.34 0 0 1 .5 96.666V3.334Zm6.609 3.334v84.645L37.04 76.456a3.279 3.279 0 0 1 2.918 0L69.89 91.312V6.667H7.11Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Selected;
