import styles from '../styles/profile.module.scss';
import Button from "../../../components/shared/Button/Button";
import {useTranslation} from "react-i18next";
const CardAddedModal = () => {
  const {t} = useTranslation();

  return (
    <div className={styles.addCardModalWrapper}>
      <div className={styles.addCardModalTextContent}>
        <div className={styles.addCardModalTitle}>{t('cardAdded')}</div>
        <div className={styles.addCardModalDescr}>{t('chooseYourSub')}</div>
      </div>
      <Button title={t('chooseSub')} submit={() => {}} className={styles.addCardModalSubmit} />
    </div>
  )
}

export default CardAddedModal;
