import Header from "../../components/header/Header";
import styles from "./styles/profile.module.scss";
import SideBar from "../../components/sideBar/SideBar";
import AvatarBg from "../../components/icons/avatarbg";
import Arrow from "../../components/icons/profile/arrow";
import Gift from "../../components/icons/profile/gift";
import Sale from "../../components/icons/profile/Sale";
import Footer from "../../components/footer/Footer";
import AccountSettings from "./components/AccountSettings";
import PrepaymentPage from "./components/Prepayment";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {ROUTES} from "../../constants/routes";
import {useAppSelector} from "../../store/store";
import ModalWrapper from "../../components/shared/ModalWrapper/ModalWrapper";
import CardAddedModal from "./components/CardAddedModal";
import CardRemoveModal from "./components/CardRemoveModal";
import CardRemoveConfirmModal from "./components/CardRemoveConfirmModal";
import RemoveSubscriptionModal from "./components/RemoveSubscriptionModal";
import SuccessRemoveSubscriptionModal from "./components/SuccessRemoveSubscriptionModal";
import useWindowSize from "../../hooks/useWindowSize";
import ChevronRight from "../../components/icons/ChevronRight";
import {setAccountTab} from "../../store/slices/navigation.slice";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {useLogin} from "../../hooks/useLogin";
import UploadPhoto from "../../components/icons/profile/uploadPhoto";


const Profile = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modal } = useAppSelector((state: any) => state.modals)
  const { currentTab } = useAppSelector((state: any) => state.navigation)
  const { windowSize } = useWindowSize();
  const { logout } = useLogin();

  const firstName = useAppSelector((state: any) => state.auth.userData.username);


  const currentTabHandler = (value: number) => {
    dispatch(setAccountTab(value))
  }

  const settingsHandler = () => {
    if (windowSize === 'mobile') {
      navigate(ROUTES.MOBILE_ACCOUNT_SETTINGS);
      return;
    }

    currentTabHandler(2)
  }

  const paymentHandler = () => {
    if (windowSize === 'mobile') {
      navigate(ROUTES.MOBILE_PAYMENT_DETAILS);
      return;
    }

    currentTabHandler(1)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={styles.profilePageWrapper}>
        <Header/>
        <SideBar/>
        <div className={styles.profilePageContentWrapper}>
          <div className={styles.pageLeftContent}>
            <div className={styles.avatarBlock}>
              <div className={styles.avatarMain}>
                <AvatarBg/>
                <span>{(firstName || 'User')[0]}</span>
                <div className={styles.uploadPhotoHover}>
                  <UploadPhoto />
                </div>
              </div>
              <div>
                <div className={styles.avatarBlockName}>{firstName || 'User'}</div>
                <div className={styles.avatarBlockId}>ID:91477713</div>
              </div>
            </div>
            <div className={styles.uploadPhotoBtn}>Завантажити своє фото</div>
            {windowSize !== 'mobile' && (
              <div className={styles.navigationBlock}>
                <div className={`${currentTab === 1 ? styles.active : ''}`} onClick={paymentHandler}>
                  {t('subAndPay')}
                </div>
                <div className={`${currentTab === 2 ? styles.active : ''}`} onClick={settingsHandler}>
                  {t('settings')}
                </div>
              </div>
            )}
            {windowSize === 'mobile' && (
              <div className={styles.navigationBlock}>
                <div className={styles.mobileNavItem} onClick={paymentHandler}>
                  <span>{t('subAndPay')}</span>
                  <ChevronRight/>
                </div>
                <div className={styles.mobileNavItem} onClick={settingsHandler}>
                  <span>{t('settings')}</span>
                  <ChevronRight/>
                </div>
              </div>
            )}
            <div className={styles.prePayWrapper}>
              <div className={styles.saleBlock}>
                <div
                  onClick={() => navigate(ROUTES.DISCOUNT)}
                  className={styles.saleBlockInvite}
                ><Gift/> {t('inviteFriend')}</div>
                <Arrow/>
                <div className={styles.saleBlockWrapper}>
                  <div className={styles.saleBlockSale}><Sale/> {t('offerTo')} 3.03</div>
                </div>
              </div>
            </div>
            {windowSize === 'mobile' && (
              <div className={styles.links}>
                <span>{t('Subscriptions')}</span>
                <Link to={ROUTES.AGREEMENT}>{t('agreement')}</Link>
                <span>{t('PrivacyPolicy')}</span>
                <Link to={ROUTES.ABOUT_US}>{t('Aboutus')}</Link>
                <Link to={ROUTES.COOPERATION}>{t('Cooperation')}</Link>
                <Link to={ROUTES.SUPPORT}>{t('Usersupport')}</Link>
              </div>
            )}
            <div
              onClick={() => {
                logout();
                navigate(ROUTES.FOR_YOU);
              }}
              className={styles.logOut}
            >
              {t('logOut')}
            </div>
          </div>
          {windowSize !== 'mobile' && (
            <div className={styles.pageRightContent}>
              {currentTab === 2 && <AccountSettings/>}
              {currentTab === 1 && <PrepaymentPage/>}
            </div>
          )}
        </div>
      </div>
      <Footer source={''}/>
      {modal && (
        <ModalWrapper>
          {modal === 'cardAdded' && (
            <CardAddedModal />
          )}
          {modal === 'cardRemove' && (
            <CardRemoveModal />
          )}
          {modal === 'cardRemoveConfirm' && (
            <CardRemoveConfirmModal />
          )}
          {modal === 'removeSubscription' && (
            <RemoveSubscriptionModal />
          )}
          {modal === 'removeSubscriptionSuccess' && (
            <SuccessRemoveSubscriptionModal />
          )}
        </ModalWrapper>
      )}
    </>
  )
}

export default Profile;
