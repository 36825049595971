import {FC, PropsWithChildren, useEffect, useState} from "react";
import {InterfaceContext, InterfaceContextType} from "./InterfaceContext";
import useAccount from "../../../hooks/useAccount";

export const InterfaceProvider: FC<PropsWithChildren> = ({ children }) => {
    const [enabledScroll, setEnabledScroll] = useState(false);
    const [height, setHeight] = useState(0);
    const [horizontal, setHorizontal] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const token = localStorage.getItem('accessToken');

    const {getAccountDetails} = useAccount();

    useEffect(() => {
      setIsMobile(window.matchMedia("(max-width: 1023px)").matches)
    }, []);

    useEffect(() => {
      if (token) {
        getAccountDetails();
      }
    }, [getAccountDetails, token])

    const value: InterfaceContextType = {
        enabledScroll,
        setEnabledScroll,
        height,
        setHeight,
        horizontal,
        setHorizontal,
        isMobile,
        setIsMobile,
    };

    return <InterfaceContext.Provider value={value}>{children}</InterfaceContext.Provider>;
};
