const Check = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
    >
      <path
        fill="#EF3500"
        d="M6.75 12.127 4.148 9.525a.748.748 0 1 0-1.058 1.057l3.135 3.135a.747.747 0 0 0 1.058 0l7.935-7.935a.748.748 0 0 0-1.058-1.057l-7.41 7.402Z"
      />
    </svg>
  )
};

export default Check
