import styles from '../../../pages/foryou/_styles/InfoBlock.module.scss';
import { ComponentProps, forwardRef } from "react"

interface IProps {
  pathStroke?: string
  isMobile?: boolean
}

export const ShareDetails = forwardRef<SVGSVGElement, IProps & ComponentProps<"svg">>(
  ({ isMobile, pathStroke, ...props }, ref) =>
    isMobile ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={18}
        fill="none"
        {...props}
      >
        <path
          stroke="#FAFAFA"
          strokeWidth={2}
          d="M11.001 13.315v3.364l7.909-7.68L11 1.319V5.666l-.953.044c-3.316.156-5.65 1.386-7.12 3.566-1.161 1.721-1.836 4.139-1.92 7.271.99-1.228 2.027-2.273 3.427-3.01h.001c1.483-.78 3.284-1.152 5.534-1.222l1.031-.031v1.031Z"
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={23}
        height={20}
        className={styles.secondaryShareIcon}
        fill="rgba(0, 0, 0, 0)"
        {...props}
        ref={ref}
      >
        <path
          stroke="#FAFAFA"
          strokeWidth={2}
          d="m12.38 19.115-.019.013m.019-.013.003-.007m-.022.02.019-.013M12.383 19.108l-.003.007m.003-.007-.073-.078.003-.003.075.082-.002.002m-.003-.003.003.003m-.006.004.006-.004m0 0-.006.004m0 0-.019.013m.019-.013-.019.013M12.38.882 12.374.87l.006.012Zm0 0L12.36.87l.019.012ZM.885 19.109l-.002.001m.002-.001-.002.001m.002-.001-.002.001m.002-.001-.002.001m11.618-4.316v4.056L21.935 10l-9.434-8.852V6.19l-.954.043c-3.864.175-6.596 1.564-8.313 4.024-1.402 2.008-2.187 4.847-2.232 8.532 1.223-1.512 2.463-2.784 4.18-3.657h.002c1.675-.85 3.716-1.262 6.288-1.338l1.03-.03v1.03Zm-.128 4.336.001-.002v.002Z"
        />
      </svg>
    )
)

ShareDetails.displayName = "IconSecondaryShare"

export default ShareDetails
