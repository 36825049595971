import styles from '../../../pages/foryou/_styles/InfoBlock.module.scss';
import { ComponentProps, forwardRef } from "react"

interface IProps {
  pathStroke?: string
  isMobile?: boolean
}

export const Share = forwardRef<SVGSVGElement, IProps & ComponentProps<"svg">>(
  ({ isMobile, pathStroke, ...props }, ref) =>
    isMobile ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={30}
        fill="#000000"
        {...props}
        ref={ref}
        className={styles.shareIcon}
      >
        <path
          fill={pathStroke ? pathStroke : "#fafafa"}
          fillRule="evenodd"
          d="M.708 1a1 1 0 0 1 1-1h21a1 1 0 0 1 1 1v28a1 1 0 0 1-1.442.897L12.208 24.95 2.149 29.896A1 1 0 0 1 .708 29V1Zm2 1v25.393l9.058-4.456a1 1 0 0 1 .883 0l9.059 4.456V2h-19Z"
          clipRule="evenodd"
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={ref}>
        <path
          stroke={pathStroke ? pathStroke : "#fafafa"}
          strokeWidth={2}
          d="M11.668 13.315v3.364l7.908-7.68-7.908-7.68V5.666l-.953.044c-3.317.156-5.65 1.386-7.12 3.566-1.162 1.721-1.836 4.139-1.92 7.271.99-1.228 2.027-2.273 3.427-3.01 1.484-.78 3.285-1.152 5.535-1.222l1.03-.031v1.031Z"
        />
      </svg>
    )
)

Share.displayName = "IconShare"

export default Share
