import styles from '../../components/sideBar/SideBar.module.scss';

interface IProps {
  selected?: boolean;
  isMobile?: boolean;
}

const Account = ({ selected, isMobile }: IProps) => !isMobile ? (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
  >
    <circle
      className={!selected ? styles.profileIcon : ''}
      cx={14} cy={14} r={13} stroke={selected ? '#FAFAFA' : "#888"}
      strokeWidth={2}
    />
    <circle
      className={!selected ? styles.profileIcon : ''}
      cx={14} cy={14} r={13} stroke={selected ? '#FAFAFA' : "#888"}
      strokeWidth={2}
    />
    <path
      className={!selected ? styles.profileIcon : ''}
      stroke={selected ? '#FAFAFA' : "#888"} strokeWidth={2}
      d="M20 14a6 6 0 0 1-12 0"
    />
  </svg>
) : (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <circle cx={12} cy={12} r={11} stroke={selected ? '#FAFAFA' : "#888"} strokeWidth={2}/>
    <circle cx={12} cy={12} r={11} stroke={selected ? '#FAFAFA' : "#888"} strokeWidth={2}/>
    <path
      stroke={selected ? '#FAFAFA' : "#888"}
      strokeWidth={2}
      d="M17.143 12a5.143 5.143 0 1 1-10.286 0"
    />
  </svg>
)
export default Account;
