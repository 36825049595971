import styles from "./SearchPage.module.scss";
import SideBar from "../../components/sideBar/SideBar";
import Input from "../../components/shared/Input";
import React, {useCallback, useEffect, useState} from "react";
import PeriodModule from "../filters/components/PeriodModule";
import useInterfaceContext from "../../components/providers/interface";
import {useGetSearchFilmsListQuery} from "../../store/api/filmAPI";
import {EN, UA} from "../../constants/local";
import GenresModule from "../filters/components/GenresModule";
import MoviesList from "./MoviesList/MoviesList";
import Footer from "../../components/footer/Footer";
import useWindowSize from "../../hooks/useWindowSize";
import Loader from "../../components/loader/Loader";
import Header from "../../components/header/Header";
import {useTranslation} from "react-i18next";
import SearchFieldIcon from "../../components/icons/searchIcons/searchField";
import {debounce} from "../../utils/helpers";
import {useDispatch} from "react-redux";
import {setSearch} from "../../store/slices/filters.slice";
import {useAppSelector} from "../../store/store";
import useLikeClick from "../../hooks/useLikeClick";
import useFavouriteClick from "../../hooks/useFavouriteClick";

const buttonsList = [
  { title: "Popularity" },
  { title: "Rating" },
  { title: "date" },
];

interface IQuery {
  language: string;
  sort_by: string;
  include_video: boolean;
  "release_date_gte"?: string;
  "release_date_lte"?: string;
  search?: string;
  with_genres?: string;
}

const SearchPage = () => {
  const search = useAppSelector((state: any) => state.filters.search)

  const [filmsList, setFilmsList] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [inputValue, setInputValue] = useState(search || '');
  const [sortBy, setSortBy] = useState('');
  const [activeChips, setActiveChips] = useState('Popularity');
  const [isFocused, setIsFocused] = useState(false);

  const { isMobile } = useInterfaceContext();
  const { windowSize } = useWindowSize();
  const { t, i18n } = useTranslation();
  const { likesList, likeHandler } = useLikeClick();
  const { favoritesList, favouritesHandler } = useFavouriteClick();
  const dispatch = useDispatch();

  const [periodValue, setPeriodValue] = useState<number[]>([1980, 2024]);
  const [periodValueUI, setPeriodValueUI] = useState<number[]>([1980, 2024]);
  const [genresValue, setGenresValue] = useState<number[]>([])

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const clearGenresHandler = () => {
    setGenresValue([]);
  }

  const clearPeriodHandler = () => {
    setPeriodValue([1980, 2024]);
    setPeriodValueUI([1980, 2024]);
  }

  const setGenresValueHandler = (itemId: any) => {
    setGenresValue((currentIds) => {
      if (currentIds.includes(itemId)) {
        return currentIds.filter((currentId: any) => currentId !== itemId);
      } else {
        return [...currentIds, itemId];
      }
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setPeriodForRequest = useCallback(
    debounce((value: number[]) => {
      setPeriodValue(value);
    }, 400),
    []
  );

  const setPeriodHandler = (periodValue: number[]) => {
    setPeriodValueUI(periodValue);
    setPeriodForRequest(periodValue);
  };

  const queryHandler = () => {
    const query: IQuery = {
      language: i18n.language === 'en' ? EN : UA,
      sort_by: "popularity.desc",
      include_video: true,
    };

    if (sortBy) {
      query.sort_by = sortBy;
    }

    if (periodValue[0] !== 1980 || periodValue[1] !== 2024) {
      query["release_date_gte"] = `${periodValue[0]}-01-01`;
      query["release_date_lte"] = `${periodValue[1]}-12-31`;
    }

    if (searchValue) {
      query.search = searchValue;
    }

    if (genresValue.length) {
      query.with_genres = genresValue.join('|');
    }

    return query;
  };

  const { data, isLoading, isFetching, error } = useGetSearchFilmsListQuery({
    mainFilter: "/movies/discover",
    queries: {
      ...queryHandler(),
      page: page
    },
  },{
    refetchOnMountOrArgChange: true, // запрос будет выполнен при каждом изменении аргументов
  });

  useEffect(() => {
    if (!data || isFetching) return;
    if ((data?.results && data?.results.length) && (page > 1)) {
      setFilmsList((prevState) => [...prevState, ...data.results]);
    } else {
      setFilmsList(data.results);
    }
  }, [data, isFetching, page]);

  const handleClickChips = (title: string) => {
    setActiveChips(title);
    if (title === 'Rating') {
      setSortBy('vote_average.desc');
    } else if (title === 'date') {
      setSortBy('release_date.desc');
    } else if (title === 'Popularity') {
      setSortBy('popularity.desc');
    }
  };

  const setPageHandler = () => {
    setPage((prevState) => prevState + 1);
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    dispatch(setSearch(event.target.value));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchValue(inputValue);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  useEffect(() => {
    setPage(1);
  }, [genresValue, periodValue, searchValue, sortBy]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <section>
        <Header className={styles.headerWrapper} />
        {windowSize !== "mobile" ? <SideBar /> : null}
        <div className={styles.container}>
          <div className={styles.leftColumnWrapper}>
            <div className={styles.leftColumnTopContent}>
              <div className={styles.topControlsWrapper}>
                <div className={`${styles.searchFieldWrapper} ${isFocused ? styles.focused : ''}`}>
                  <SearchFieldIcon />
                  <Input
                    placeHolder={t('search')}
                    className={styles.input}
                    labelClassName={styles.label}
                    value={inputValue}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={styles.chipsListWrapper}>
                  {buttonsList.map(({ title }, index) => (
                    <button
                      key={index}
                      className={`${styles.chipsButton} ${activeChips === title ? styles.chipsButtonActive : ''}`}
                      onClick={() => handleClickChips(title)}
                    >
                      <p className={styles.btnText}>{t(title)}</p>
                    </button>
                  ))}
                </div>
              </div>
              <MoviesList
                data={filmsList}
                error={error}
                setPageHandler={setPageHandler}
                isFetching={isFetching}
                isLastData={data.page ? data.page === data.total_pages : false}
                likeHandler={likeHandler}
                favouritesHandler={favouritesHandler}
                title={t('search')}
                likesList={likesList?.data?.results}
                favoritesList={favoritesList?.data?.results}
                isFavoritePage={false}
              />
            </div>
          </div>
          {windowSize !== "mobile" && (
            <div className={styles.contentWrapper}>
              <PeriodModule
                setPeriodValue={setPeriodHandler}
                isMobile={!isMobile}
                className={styles.periodModule}
                period={periodValueUI}
                clearFiltersHandler={clearPeriodHandler}
              />
              <GenresModule
                setGenresValue={setGenresValueHandler}
                isMobile={!isMobile}
                className={styles.genresModule}
                genres={genresValue}
                clearFiltersHandler={clearGenresHandler}
              />
              <div className={styles.advertisement} />
            </div>
          )}
        </div>
        <Footer source={''} />
      </section>
    </>
  );
};

export default SearchPage;
