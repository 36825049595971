import styles from "../../foryou/_components/DesctopComponents/Media.module.scss";
import React from "react";
import LikeRevItem from "../../../components/icons/details/LikeRevItem";
import LikedRevItemWite from "../../../components/icons/details/LikedRevItemWite";

interface IProps {
  review: any
  likeReviewHandler: (id: number, liked: boolean) => void
  reviewsList: any
}
const ReviewModalContent = ({review, likeReviewHandler, reviewsList}: IProps) => {
  const currentItem = reviewsList.find((item: any) => item.id === review.id)
  return (
    <div className={styles.reviewModalContentWrapper}>
      <div className={styles.reviewModalContentDecor} />
      <div className={styles.reviewAuthor}>
        <div className={styles.av}>{currentItem?.author[0]}</div>
        <div className={styles.authorName}>{currentItem?.author}</div>
      </div>
      <div className={styles.reviewText}>{currentItem?.content}</div>
      <div
        onClick={(e) => {
          e.stopPropagation()
          likeReviewHandler(currentItem.id, currentItem.liked)
        }}
        className={styles.reviewVotes}
      >
        <LikedRevItemWite extraClass={`${styles.hoveredRevModal} ${currentItem.liked && styles.likedRevModal}`} />
        <LikeRevItem/>
        {currentItem?.like_count !== 0 && <span>{currentItem?.like_count}</span>}
      </div>
    </div>
  )
};

export default ReviewModalContent;
