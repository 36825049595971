import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type DefaultState = {
  period: number[]
  genres: number[]
  search: string
  periodCHanged: boolean
  openModal: boolean
  mutedState: boolean
  filterChanged: boolean
  temporaryCounter: number
  tempGenres: number[]
  tempPeriod: number[]
}

const initialState: DefaultState = {
  period: [1980, 2024],
  genres: [],
  search: "",
  periodCHanged: false,
  openModal: false,
  mutedState: true,
  filterChanged: false,
  temporaryCounter: 0,
  tempGenres: [],
  tempPeriod: [1980, 2024]
}

export const FiltersSlice = createSlice({
  name: "filters",
  initialState: initialState,
  reducers: {
    setOpenModal: (state, action: PayloadAction<boolean>) => {
      state.openModal = action.payload
    },
    setPeriod: (state, action: PayloadAction<number[]>) => {
      state.periodCHanged = action.payload[0] !== 1980 || action.payload[1] !== 2024

      state.period = [
        action.payload[0] < 1980 ? 1980 : action.payload[0],
        action.payload[1] > 2024 ? 2024 : action.payload[1],
      ]
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setGenres: (state, action: PayloadAction<number[]>) => {
      state.genres = action.payload
    },
    setMutedState: (state, action: PayloadAction<boolean>) => {
      state.mutedState = action.payload;
    },
    setFilterChanged: (state, action: PayloadAction<boolean>) => {
      state.filterChanged = action.payload
    },// @ts-ignore
    setTemporaryCounter: (state, action: PayloadAction<any>) => {
      let counter = 0;
      if (action.payload.period[0] !== 1980 ||  action.payload.period[1] !== 2024) {
        counter += 1;
      };

      counter += action.payload.genres.length;

      state.temporaryCounter =  counter;
    },// @ts-ignore
    setTemporaryGenres: (state, action: PayloadAction<any>) => {
      if (state.tempGenres.includes(action.payload)) {
        state.tempGenres =  state.tempGenres.filter((currentId: any) => currentId !== action.payload);
      } else {
        state.tempGenres =  [...state.tempGenres, action.payload];
      }
    },
    setTemporaryPeriod: (state, action: PayloadAction<number[]>) => {
      state.tempPeriod = [
        action.payload[0] < 1980 ? 1980 : action.payload[0],
        action.payload[1] > 2024 ? 2024 : action.payload[1],
      ]
    },
    resetFilters: (state) => {
      state.tempGenres = []
      state.genres = []
      state.tempPeriod = [1980, 2024]
      state.period = [1980, 2024]
    },
    resetPeriod: (state) => {
      state.tempPeriod = [1980, 2024]
      state.period = [1980, 2024]
    },
    resetGenres: (state) => {
      state.tempGenres = []
      state.genres = []
    }
  },
})

export default FiltersSlice.reducer
export const {
  resetGenres,
  resetPeriod,
  setPeriod,
  setGenres,
  setSearch,
  setOpenModal ,
  setMutedState,
  setFilterChanged,
  setTemporaryCounter,
  setTemporaryGenres,
  setTemporaryPeriod,
  resetFilters
} = FiltersSlice.actions
