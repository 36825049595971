import React, { HTMLAttributes, ReactNode } from "react";
import styles from "./Input.module.scss";
import classnames from 'classnames';

interface IInputProps extends HTMLAttributes<HTMLInputElement> {
  iconRight?: ReactNode;
  label?: string;
  className?: string;
  labelClassName?: string;
  value?: string;
  placeHolder?: string;
}

const Input = ({
   iconRight,
   label,
   className,
   labelClassName,
   placeHolder,
   ...props
}: IInputProps) => (
  <div className={styles.form__group}>
    <input
      type="input"
      className={classnames(styles.form__field, className)}
      placeholder={placeHolder || "Відповісти"}
      name="name"
      id="name"
      required
      {...props}
    />
    {label && (
      <label htmlFor="name" className={classnames(styles.form__label, labelClassName)}>
        {label}
      </label>
    )}
    {iconRight && <span className={styles.iconRight}>{iconRight}</span>}
  </div>
);

export default Input;
