import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type DefaultState = {
  id: number,
  currentVideo: number,
  lastVisited: number,
  currentItem: any
}

const initialState: DefaultState = {
  id: 0,
  currentVideo: 0,
  lastVisited: 0,
  currentItem: {}
};

export const CurrentItemSlice = createSlice({
  name: 'currentItem',
  initialState: initialState,
  reducers: {
    setItemId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    setCurrentVideoId: (state, action: PayloadAction<number>) => {
      state.currentVideo = action.payload;
    },
    setLastVisitedId: (state, action: PayloadAction<number>) => {
      state.lastVisited = action.payload;
    },
    setCurrentItem: (state, action: PayloadAction<any>) => {
      state.currentItem = action.payload;
    },
  },
});

export default CurrentItemSlice.reducer;
export const {
  setItemId,
  setCurrentVideoId,
  setLastVisitedId,
  setCurrentItem
} = CurrentItemSlice.actions;
