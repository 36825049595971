import styles from '../../../pages/foryou/_styles/InfoBlock.module.scss';
import { ComponentProps } from "react";

interface IProps extends ComponentProps<"svg"> {
  isMobile?: boolean;
  liked?: boolean;
}

export const MainLike = ({ isMobile, liked, ...props }: IProps) =>
  !isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={22}
      fill={liked ? '#FAFAFA' : 'rgba(0, 0, 0, 0)'}
      className={styles.mainLikeIcon}
      {...props}
    >
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M7.6 1C3.955 1 1 3.87 1 7.41 1 13.817 8.8 19.644 13 21c4.2-1.355 12-7.182 12-13.59C25 3.87 22.045 1 18.4 1A6.651 6.651 0 0 0 13 3.723a6.537 6.537 0 0 0-2.357-2.003A6.75 6.75 0 0 0 7.6 1Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={22}
      fill={liked ? '#FAFAFA' : 'none'}
      {...props}
    >
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M7.6 1C3.955 1 1 3.87 1 7.41 1 13.817 8.8 19.644 13 21c4.2-1.355 12-7.182 12-13.59C25 3.87 22.045 1 18.4 1A6.651 6.651 0 0 0 13 3.723a6.537 6.537 0 0 0-2.357-2.003A6.75 6.75 0 0 0 7.6 1Z"
      />
    </svg>
  );
