const LikeRevItem = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={18}
      fill="none"
    >
      <path
        fill="#FAFAFA"
        d="M12.364 6.507h5.818C19.186 6.507 20 7.3 20 8.275v1.86c0 .232-.047.46-.137.674l-2.813 6.644a.91.91 0 0 1-.84.547H.91a.897.897 0 0 1-.91-.884v-8.84c0-.489.407-.885.91-.885h3.164c.296 0 .573-.14.743-.374L9.775.187a.463.463 0 0 1 .574-.14L12 .849c.955.464 1.45 1.514 1.186 2.522l-.822 3.136Zm-6.91 2.288v7.437H15.6l2.582-6.096v-1.86h-5.818c-1.187 0-2.055-1.087-1.763-2.205l.822-3.137a.438.438 0 0 0-.238-.504l-.6-.292-4.283 5.899c-.227.313-.517.57-.847.758Zm-1.818.364H1.818v7.073h1.818V9.159Z"
      />
    </svg>
  )
};

export default LikeRevItem;
