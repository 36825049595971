import React from "react";
import styles from "./SliderModal.module.scss";
import useWindowSize from "../../../hooks/useWindowSize";

interface IModalBody {
  children: React.ReactNode;
  duration: number;
  state: string;
}

const ModalBody = ({ children, duration, state }: IModalBody) => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";

  const defaultStyle = {
    transition: `transform ${duration}ms ease-in-out`,
    transform: isMobile ? "translateY(200%)" : "translateX(101%)", // Начальная позиция
  };

  const transitionStyles = {
    entering: { transform: isMobile ? "translateY(100%)" : "translateX(101%)" },
    entered: { transform: isMobile ? "translateY(100%)" : "translateX(0)" },
  };
  return (
    <div
      className={styles.sidebar}
      style={{
        ...defaultStyle, //@ts-ignore
        ...transitionStyles[state],
      }}
    >
      {children}
    </div>
  );
};

export default ModalBody;
