import React, {useEffect} from "react";
import styles from "./CooperationPage.module.scss";
import SideBar from "../../components/sideBar/SideBar";
import useWindowSize from "../../hooks/useWindowSize";
import Heading from "../../components/shared/Heading";
import Cooperation1 from "../../components/icons/cooperations/Cooperation-1";
import Cooperation2 from "../../components/icons/cooperations/Cooperation-2";
import Cooperation3 from "../../components/icons/cooperations/Cooperation-3";
import Cooperation4 from "../../components/icons/cooperations/Cooperation-4";
import {Link} from "react-router-dom";
import TV from '../../static/imgs/tv.png'
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

const CooperationPage = () => {
  const { windowSize } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={styles.wrapper}>
      <Header className={styles.headerWrapper} />
      {windowSize !== "mobile" && <SideBar />}
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <Heading title="Співпраця"/>
          <p className={styles.subheadingText}>
            Якщо у вас виникли проблеми з роботою сервісу, будь ласка, {<span className={styles.colorfulText}>повідомте нас про це</span>}.
          </p>
        </div>
        <div className={styles.content}>
          <section className={styles.advertisementWrapper}>
            <div className={styles.iconWrapper}>
              <Cooperation1 className={styles.advertisementIcon} />
              <Cooperation2 className={styles.advertisementIcon} />
              <Cooperation3 className={styles.advertisementIcon} />
              <Cooperation4 className={styles.advertisementIcon} />
            </div>
            <div className={styles.advertisementTitleWrapper}>
              <h3 className={styles.title}>Реклама</h3>
              <p className={styles.subtitle}>З питань розміщення реклами на <span className={styles.boldDecor}>HIVIEWER</span> звертайтеся за поштою внизу сторінки.</p>
              {/*<Link to="mailto:ads@hiviewer.ai" className={styles.link}>ads@hiviewer.ai</Link>*/}
            </div>
          </section>
          <section className={styles.partnershipWrapper}>
            <div className={styles.advertisementTitleWrapper}>
              <h3 className={styles.title}>Партнерство</h3>
              <p className={styles.subtitle}>Ми відкриті до цікавих пропозицій — та знайдемо взаємовигідні варіанти співпраці з усіма.</p>
              {/*<Link to="mailto:partners@hiviewer.ai" className={styles.link}>partners@hiviewer.ai</Link>*/}
            </div>
            <img
              width={460}
              height={424}
              src={TV}
              alt="#"
            />
          </section>
          <section className={styles.questionsWrapper}>
            <p className={styles.questionText}>З пропозиціями звертайтесь за поштою</p>
            <Link to="mailto:info@hiviewer.ai" className={styles.questionLink}>info@hiviewer.ai</Link>
          </section>
        </div>
      </div>
      <Footer source={''} />
    </section>
  );
};

export default CooperationPage;
