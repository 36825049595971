import styles from './ContentSwitcher.module.scss';
import {useTranslation} from "react-i18next";

interface IProps {
  setActiveTab: (tab: string) => void;
  activeTab?: string;
  videosCounter?: number;
  imgsCounter?: number;
}
const ContentSwitcher = ({
   setActiveTab,
   activeTab,
   videosCounter,
   imgsCounter
}: IProps) => {
  const {t} = useTranslation();

  return (
    <div className={styles.switcherWrapper}>
      <div
        onClick={() => setActiveTab('0')}
        className={`${styles.switchBtnOne} ${activeTab === '0' ? styles.selectedSwitch : ''}`}
      >
        {t('contentSwitcherOne')} <span className={styles.count}>{videosCounter}</span>
      </div>
      <div
        onClick={() => setActiveTab('1')}
        className={`${styles.switchBtnSecond} ${activeTab === '1' ? styles.selectedSwitch : ''}`}
      >
        {t('contentSwitcherSecond')} <span className={styles.count}>{imgsCounter}</span>
      </div>
    </div>
  )
}

export default ContentSwitcher;
