import styles from '../header/Header.module.scss';

interface IProps {
  isMobile?: boolean
}

const Filters = ({ isMobile }: IProps) => !isMobile ? (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={14}
    fill="none"
  >
    <path
      className={styles.filterIconPath}
      stroke="#888"
      strokeLinecap="round"
      strokeWidth={2}
      d="M1 3.182h4.444m15.556 0H9.889M1 10.818h13.333m6.667 0h-2.222M7.667 5.364c1.227 0 2.222-.977 2.222-2.182S8.894 1 7.667 1c-1.228 0-2.223.977-2.223 2.182s.995 2.182 2.223 2.182ZM16.556 13c1.227 0 2.222-.977 2.222-2.182s-.995-2.182-2.222-2.182c-1.228 0-2.223.977-2.223 2.182S15.328 13 16.556 13Z"
    />
  </svg>
) : (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={20}
    fill="none"
  >
    <path
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M19.058 7.723c.948-.534 1.423-.801 1.682-1.232.26-.43.26-.949.26-1.987v-.69c0-1.326 0-1.99-.44-2.402C20.122 1 19.415 1 18 1H4c-1.414 0-2.121 0-2.56.412C1 1.824 1 2.488 1 3.815v.69c0 1.037 0 1.556.26 1.986.26.43.733.698 1.682 1.232l2.913 1.64c.636.358.955.537 1.183.735.474.411.766.895.898 1.49.064.284.064.618.064 1.285v2.67c0 .909 0 1.364.252 1.718.252.355.7.53 1.594.88 1.879.734 2.818 1.101 3.486.683.668-.417.668-1.372.668-3.282v-2.67c0-.666 0-1 .064-1.285a2.68 2.68 0 0 1 .899-1.49"
    />
  </svg>
)
export default Filters
