import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {FiltersSlice} from "./slices/filters.slice";
import {CurrentItemSlice} from "./slices/currentItem.slice";
import {filmsAPI} from "./api/filmAPI";
import {ModalSlice} from "./slices/modals.slice";
import {NavigationSlice} from "./slices/navigation.slice";
import {AuthSlice} from "./api/authSlice";

export const store = configureStore({
  reducer: {
    navigation: NavigationSlice.reducer,
    modals: ModalSlice.reducer,
    filters: FiltersSlice.reducer,
    item: CurrentItemSlice.reducer,
    auth: AuthSlice.reducer,
    [filmsAPI.reducerPath]: filmsAPI.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([filmsAPI.middleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
