import {ComponentProps} from "react";
import styles from "../sideBar/SideBar.module.scss";

interface IProps extends ComponentProps<"svg"> {
  stroke?: string;
  selected?: boolean;
  isMobile?: boolean;
}

const Search = ({ selected, stroke, isMobile, ...props }: IProps) => !isMobile ? (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <path
      className={!selected ? styles.searchIcon : ''}
      stroke={stroke || (selected ? '#FAFAFA' : "#98989E")}
      strokeLinecap="round"
      strokeWidth={2}
      d="m29 29-6.61-6.623L29 29Zm-2.947-15.474a12.526 12.526 0 1 1-25.053 0 12.526 12.526 0 0 1 25.053 0v0Z"
    />
  </svg>
) : (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke={selected ? '#FAFAFA' : "#98989E"}
      strokeLinecap="round"
      strokeWidth={2}
      d="m23 23-5.194-5.204L23 23Zm-2.316-12.158a9.842 9.842 0 1 1-19.683 0 9.842 9.842 0 0 1 19.683 0v0Z"
    />
  </svg>
)
export default Search
