import styles from '../../../pages/foryou/_styles/InfoBlock.module.scss';
import { ComponentProps } from "react";

interface IProps extends ComponentProps<"svg"> {
  isMobile?: boolean;
  liked?: boolean;
}

export const LikeDetails = ({ isMobile, liked, ...props }: IProps) =>
  !isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={22}
      fill={liked ? '#FAFAFA' : 'rgba(0, 0, 0, 0)'}
      className={styles.mainLikeIcon}
      {...props}
    >
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M7.6 1C3.955 1 1 3.87 1 7.41 1 13.817 8.8 19.644 13 21c4.2-1.355 12-7.182 12-13.59C25 3.87 22.045 1 18.4 1A6.651 6.651 0 0 0 13 3.723a6.537 6.537 0 0 0-2.357-2.003A6.75 6.75 0 0 0 7.6 1Z"
      />
    </svg>
  ) : (
    <svg
      width="22"
      height="20"
      viewBox="0 0 30 26"
      fill={liked ? '#FAFAFA' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke="#fafafa"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.7 1.69969e-06C10.0959 -0.000870793 11.4716 0.334174 12.7105 0.976846C13.5665 1.42086 14.3398 2.0027 15.0008 2.69639C16.5845 1.03617 18.8216 1.69969e-06 21.3 1.69969e-06C26.1037 1.69969e-06 30 3.89006 30 8.69096C30 12.921 27.5136 16.8321 24.5477 19.8238C21.5719 22.8254 17.9416 25.0773 15.315 25.9491C15.1105 26.017 14.8895 26.017 14.685 25.9491C12.0584 25.0773 8.4281 22.8254 5.45234 19.8238C2.48643 16.8321 0 12.921 0 8.69096C0 3.8903 3.89594 0.000383169 8.69929 1.9381e-06M11.7896 2.75221C10.8355 2.25728 9.77595 1.99924 8.70071 2L8.7 2C4.99868 2 2 4.99685 2 8.69096C2 12.1519 4.06357 15.5822 6.87266 18.4157C9.54531 21.1116 12.738 23.1088 15 23.9406C17.262 23.1088 20.4547 21.1116 23.1273 18.4157C25.9364 15.5822 28 12.1519 28 8.69096C28 4.99685 25.0013 2 21.3 2C19.0344 2 17.0308 3.12225 15.8174 4.84403C15.6299 5.11002 15.3248 5.26817 14.9994 5.26796C14.674 5.26776 14.369 5.10923 14.1819 4.843C13.5643 3.96433 12.7437 3.24713 11.7896 2.75221Z"
        fill="#FAFAFA"
      />
    </svg>
  );
