import styles from "./AdminSideBar.module.scss";
import ActivityIcon from "../../icons/adminSideBar/ActivityIcon";
import UsersIcon from "../../icons/adminSideBar/UsersIcon";
import PrePaymentIcon from "../../icons/adminSideBar/PrePaymentIcon";
import { DEFAULT_WHITE, SECONDARY_GRAY } from "../../../constants/colors";

interface IProps {
  iconName: string;
  selectedItem: boolean;
}

const AdminSideBarIcon = ({ iconName, selectedItem }: IProps) => (
  <div className={styles.icon}>
    {iconName === "activity" && <ActivityIcon stroke={selectedItem ? DEFAULT_WHITE : SECONDARY_GRAY} />}
    {iconName === "users" && <UsersIcon stroke={selectedItem ? DEFAULT_WHITE : SECONDARY_GRAY} />}
    {iconName === "pre-payment" && <PrePaymentIcon stroke={selectedItem ? DEFAULT_WHITE : SECONDARY_GRAY} />}
  </div>
);

export default AdminSideBarIcon;
