import styles from "../header/Header.module.scss";
import {ComponentProps} from "react";

interface IProps extends ComponentProps<"svg"> {
  isMobile: boolean;
}

const ArrowBack = ({ isMobile }: IProps) => !isMobile ? (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={styles.chevronLeft}
      d="M14 7L9 12L14 17"
      stroke="#888888"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
) : (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={12}
    fill="none"
  >
    <path
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m6.5 1-5 5 5 5"
    />
  </svg>
);

export default ArrowBack;
