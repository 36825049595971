import styles from "./Greetings.module.scss";
import LogoIcon from "../../components/icons/Logo";
import Footer from "../../components/footer/Footer";
import Button from "../../components/shared/Button/Button";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../constants/routes";
const Greetings = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.greetingsPageWrapper}>
      <div className={styles.header}><LogoIcon/></div>
      <div className={styles.contentWrapper}>
        <div className={styles.title}>Вітаємо!</div>
        <div className={styles.description}>
          Ви успішно підключили передплату<br/>
          <span>“1 рік”</span>
        </div>
        <Button
          title={"Повернутися до кабінету"}
          submit={() => navigate(ROUTES.PROFILE)}
          className={styles.btn}
        />
      </div>
      <div style={{height:'50px'}} />
      <Footer source={''}/>
    </div>
  )
}

export default Greetings;
