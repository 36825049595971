import styles from "../styles/FilterComponents.module.scss"
import {genresData} from "../../../constants/structure";
import {useTranslation} from "react-i18next";
import ClearButton from "./ClearButton";

interface IProps {
  isMobile: boolean;
  className?: string;
  setGenresValue?: any;
  genres?: any;
  clearFiltersHandler: () => void;
}
const GenresModule = ({
  isMobile,
  className,
  setGenresValue,
  genres,
  clearFiltersHandler
}: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.genresWrapper}>
      <div className={`${isMobile ? styles.titleMobile : styles.genresTitle}`}>
        <span>
          {t("Genres")}
          <ClearButton
            clearFiltersHandler={clearFiltersHandler}
            hideBtn={genres.length === 0}
          />
        </span>
      </div>
      <div className={`${isMobile ? styles.genresListWrapperMobile : styles.genresListWrapper}`}>
        {genresData.map((item: any) => {
          const activeItem = genres.findIndex((subItem: any) => subItem === item.id)

          return (
            <div
              key={item.id}
              onClick={() => setGenresValue(item.id)}
              className={`${styles.genresItem} ${activeItem !== -1 ? styles.activeGenre : ""}`}>
              {t(item.name)}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default GenresModule
