const Info = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
    >
      <path
        stroke="#A7A7A7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9 15.75a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Z"
      />
      <path
        stroke="#A7A7A7"
        strokeLinejoin="round"
        strokeWidth={2.25}
        d="M9 6h.008v.008H9V6Z"
      />
      <path
        stroke="#A7A7A7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9 9v3"
      />
    </svg>
  )
}

export default Info;
