const FilmRating = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill="#FAFAFA"
          d="m6 7.984 2.124 1.189-.475-2.387 1.787-1.652-2.417-.287L6 2.637v5.347ZM6 9.13l-3.526 1.974.787-3.964L.294 4.396l4.013-.476L6 .25l1.693 3.67 4.014.476L8.739 7.14l.788 3.964L6 9.13Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default FilmRating;
