import { useEffect } from "react";

function useOutsideClick(modalRef, callback) {
  useEffect(() => {
    // Обработчик кликов
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        callback(); // Выполнить функцию, если клик произошел вне modalRef
      }
    }

    // Добавляем обработчик события
    document.addEventListener("mousedown", handleClickOutside);

    // Удаляем обработчик события при размонтировании
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, callback]);
}

export default useOutsideClick;
