import {ROUTES_WITHOUT_BACK_BUTTON} from "../../constants/structure";
import { useLocation } from "react-router-dom";
import { PropsWithChildren } from "react";

interface IProps extends PropsWithChildren{
  hasBackButton?: boolean;
  source?: string;
}

export const BackButtonLayout = ({ children, source }: IProps) => {
  const { pathname } = useLocation();

  if (source === 'modal') {
    return <>{children}</>
  }

  return !ROUTES_WITHOUT_BACK_BUTTON.includes(pathname) ? <>{children}</> : null;
};
