import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import {logOut, setCredentials} from './authSlice';

interface IGetVideoQueries {
  queries: Object,
  id: number
}

interface IGetMoviesList {
  queries: Object,
  mainFilter: string,
}

interface IGetVideoInfo {
  queries: Object,
  id: number
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_MAIN_URL,
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    //@ts-ignore
    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
      const refreshResult = await baseQuery(
        {
          url: '/refresh/',
          method: 'POST',
          body: { refresh: refreshToken },
        },
        api,
        extraOptions
      );
      if (refreshResult.data) {// @ts-ignore
        localStorage.setItem('accessToken', refreshResult.data?.access);
        api.dispatch(
          setCredentials({
            token: (refreshResult.data as any).access,
          })
        );
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logOut());
      }
    } else {
      api.dispatch(logOut());
    }
  }

  return result;
};


const generateQueryStr = (baseString: string, query: Object): string => {
  return baseString +
    Object.entries(query)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
};

export const filmsAPI = createApi({
  reducerPath: "films",
  baseQuery: baseQueryWithReauth,
  refetchOnFocus: false,
  endpoints: (builder) => ({
    getFilmsList: builder.query<any, Object>({
      query: (queryParams: IGetMoviesList) => {
        const queryStr = generateQueryStr(`${queryParams.mainFilter}?`, queryParams.queries);
        return { url: queryStr };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (newItems.page === 1) {
          currentCache.results = newItems.results;
          return;
        }

        currentCache.results.push(...newItems.results);
        currentCache.results.filter((value: any, index: any, self: any) =>
            index === self.findIndex((t: any) => (
              t.id === value.id
            ))
        )
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      }
    }),
    getSearchFilmsList: builder.query<any, Object>({
      query: (queryParams: IGetMoviesList) => {
        const queryStr = generateQueryStr(`${queryParams.mainFilter}?`, queryParams.queries);
        return { url: queryStr };
      },
    }),
    getFavouritesFilmsList: builder.query<any, Object>({
      query: (queryParams: IGetMoviesList) => {
        const queryStr = generateQueryStr(`${queryParams.mainFilter}?`, queryParams.queries);
        const accessToken = localStorage.getItem("accessToken");
        return {
          url: queryStr,
          headers: {
            "accept": "application/json",
            "Authorization": `Bearer ${accessToken}`
          }
        };
      },
      keepUnusedDataFor: 0,
    }),
    getCurrentVideo: builder.query<any, Object>({
      query: (queryParams: IGetVideoQueries) => {
        const queryStr = generateQueryStr(`/movies/${queryParams.id}/videos?`, queryParams.queries);
        return { url: queryStr };
      },
    }),
    getVideoDetails: builder.query<any, Object>({
      query: (queryParams: IGetVideoInfo) => {
        const queryStr = generateQueryStr(`/movies/${queryParams.id}?`, queryParams.queries);
        return { url: queryStr };
      },
    }),
    getCastsList: builder.query<any, Object>({
      query: (queryParams: IGetVideoInfo) => {
        const queryStr = generateQueryStr(`/movies/${queryParams.id}/credits?`, queryParams.queries);
        return { url: queryStr };
      },
    }),
    getReviewsList: builder.query<any, Object>({
      query: (queryParams: IGetVideoInfo) => {
        const queryStr = generateQueryStr(`/movies/${queryParams.id}/reviews?`, queryParams.queries);
        const accessToken = localStorage.getItem("accessToken");
        return {
          url: queryStr,
          headers: {
            "accept": "application/json",
            "Authorization": `Bearer ${accessToken}`
          }
        };
      },
    }),
    getMovieImages: builder.query<any, Object>({
      query: (queryParams: IGetVideoInfo) => {
        const queryStr = generateQueryStr(`/movies/${queryParams.id}/images?`, queryParams.queries);
        return { url: queryStr };
      },
    }),
    getIdsList: builder.query<any, Object>({
      query: (queryParams: IGetMoviesList) => {
        const queryStr = generateQueryStr(`${queryParams.mainFilter}`, {});
        const accessToken = localStorage.getItem("accessToken");
        return {
          url: queryStr,
          headers: {
            "accept": "application/json",
            "Authorization": `Bearer ${accessToken}`
          }
        };
      },
    }),
  }),
});

export const {
  useGetFilmsListQuery,
  useGetSearchFilmsListQuery,
  useGetCurrentVideoQuery,
  useGetCastsListQuery,
  useGetReviewsListQuery,
  useGetVideoDetailsQuery,
  useGetFavouritesFilmsListQuery,
  useGetMovieImagesQuery,
  useGetIdsListQuery
} = filmsAPI;
