import styles from "./MovieCard.module.scss";
import LikeButton from "../../../components/shared/LikeButton";
import React from "react";
import SavedIcon from "../../../components/icons/searchIcons/savedIcon";
import {Movie} from "./MoviesList";
import {genresData} from "../../../constants/structure";
import {setCurrentItem} from "../../../store/slices/currentItem.slice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../constants/routes";
import {useTranslation} from "react-i18next";
import HoverPlay from "../../../components/icons/HoverPlay";
import FilmRating from "../../../components/icons/searchIcons/fimRating";
import {truncateString} from "../../../utils/helpers";

interface ISimilarContentCardProps {
  isMobile: boolean;
  img: string;
  item: Movie;
  liked: boolean;
  isFav: boolean;
  likeHandler: (e: any, item: any, liked: boolean) => void;
  favouritesHandler: (e: any, item: any, isFav: boolean) => void;
}

const MovieCard = ({
   isMobile,
   img,
   item,
   liked,
   likeHandler,
   isFav,
   favouritesHandler
}: ISimilarContentCardProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const title = i18n.language === 'en' ? item.title : item.title_ua;
  return (
    <div
      className={styles.cardWrapper}
      onClick={() => {
        dispatch(setCurrentItem(item));
        navigate(ROUTES.DETAILS);
      }}
    >
      <div className={styles.imgWrapper}>
        <div className={styles.hoverPlayIcon}><HoverPlay className={styles.wrapperPath}/></div>
        <img width={isMobile ? 157 : 119} height={isMobile ? 240 : 166} className={styles.image} src={img} alt="#"/>
      </div>
      <div className={styles.textWrapper}>
        <div className={styles.savedIconWrapper} onClick={(e: any) => favouritesHandler(e, item, isFav)}>
          <SavedIcon className={styles.savedIcon} inFavourites={isFav} />
        </div>
        <h4 className={styles.movieTitle}>{truncateString(title, 17)}</h4>
        <div className={styles.infoWrapper}>
          <div className={styles.rowInfoWrapper}>
            <div className={styles.badge}>{item.release_date?.substring(0, 4) || '2022'}</div>
            <p className={styles.country}>USA</p>
          </div>
          <p className={styles.description}>
            {item.genre_ids[0] && (
              <span>{t(`${genresData.find(
                (genre) => genre.id === item.genre_ids[0])?.name}`) || 'Action'}
              </span>
            )}
            {item.genre_ids[1] && (
              <span>{t(`${genresData.find(
                (genre) => genre.id === item.genre_ids[1])?.name}`) || 'Action'}
              </span>
            )}
          </p>
          <div className={styles.ratingWrapper}>
            <FilmRating />
            <p className={styles.rating}>{item.vote_average?.toFixed(1)}</p>
          </div>
        </div>
        <LikeButton
          clickHandler={likeHandler}
          direction="row"
          isMobile={isMobile}
          className={styles.likeButton}
          count={item.vote_count}
          liked={liked}
          item={item}
        />
      </div>
    </div>
  )
};

export default MovieCard;
