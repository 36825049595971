import styles from "./CountriesFilter.module.scss";
import { COUNTRIES_FILTER_BUTTONS } from "../../../constants/global";
import React, { useState } from "react";
import classnames from "classnames";
import useWindowSize from "../../../hooks/useWindowSize";

export const CountriesFilter = () => {
  const [selectedFilter, setSelectedFilter] = useState("Всі");
  const {windowSize} = useWindowSize();

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setSelectedFilter(event.currentTarget.textContent || '');
  };


  return (
    <div className={styles.filterButtonWrapper}>
      {COUNTRIES_FILTER_BUTTONS.map((title) => {
        if (windowSize === 'desktop' && title === '') {
          return null
        }

        return (
          <button key={title} className={classnames(styles.button, {
            [styles.selectedFilter]: title === selectedFilter
          })} onClick={handleClickFilter}>
            {title}
          </button>
        )
      })}
    </div>
  );
};
