import styles from "./Header.module.scss";
import {ROUTES} from "../../constants/routes";
import {useTranslation} from "react-i18next";
import {useLogin} from "../../hooks/useLogin";
import {useAppSelector} from "../../store/store";
import {useRef} from "react";
import useOutsideClick from "../../utils/hooks/useOutsideClick";
import headerAvatar from "../../static/imgs/headerAvatar.png";
import Rubin from "../icons/Rubin";
import {setAccountTab} from "../../store/slices/navigation.slice";
import {useDispatch} from "react-redux";

interface IProps {
  goToPage: (page: string) => void;
  openDropHandler: () => void
  opened: boolean;
}

const AccountDropDown = ({ goToPage, openDropHandler, opened }: IProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const {t} = useTranslation();
  const firstName = useAppSelector((state: any) => state.auth.userData.username);
  const dispatch = useDispatch();
  const { logout } = useLogin();

  useOutsideClick(modalRef, openDropHandler);
  return (
    <div
      ref={modalRef}
      className={`${styles.accountDropDown} ${opened ? styles.openedAcc : ''}`}
    >
      <div className={styles.accDropSectionWrapper}>
        <div className={styles.userName}>
          <div className={`${styles.headerProfileDrop}`}>
            <img src={headerAvatar} alt="avatar"/>
            <span>{(firstName || 'U')[0]}</span>
          </div>
          <div className={styles.accDropName}>{firstName || 'User'}</div>
        </div>
        <div className={styles.accId}>Hiviewer ID: 91477713</div>
        <div
          onClick={() => {
            dispatch(setAccountTab(1))
            goToPage(ROUTES.PROFILE)
          }}
          className={styles.accDropMenu}>{t('subAndPay')}</div>
        <div
          onClick={() => {
            dispatch(setAccountTab(2))
            goToPage(ROUTES.PROFILE)
          }}
          className={styles.accDropMenu}>{t('settings')}</div>
      </div>
      <div
        onClick={() => {
          goToPage(ROUTES.DISCOUNT)
        }}
        className={styles.plans}
      >
        <Rubin />{t('tar')}
      </div>
      <div
        onClick={() => {
          logout();
          openDropHandler();
          goToPage(ROUTES.FOR_YOU);
        }}
        className={styles.signOut}
      >
        {t('logOut')}
      </div>
    </div>
  )
}

export default AccountDropDown;
