import React from "react";
import styles from "./ActivityPage.module.scss";
import useWindowSize from "../../../hooks/useWindowSize";
import { ROUTES } from "../../../constants/routes";
import Header from "../../../components/header/Header";
import AdminSideBar from "../../../components/admin/AdminSideBar/AdminSideBar";
import CounterLayout from "../../../components/shared/CounterLayout";
import { TimelineFilter } from "../../../components/admin/TimelineFilter/TimelineFilter";
import { GridSection } from "../../../components/admin/GridSection/GridSection";
import AdminFooter from "../../../components/admin/AdminFooter/AdminFooter";
import Footer from "../../../components/footer/Footer";

const STATISTICS = [
  { title: "Нові реєстрації", counter: "1 072" },
  { title: "Зараз на сайті", counter: "1 050" },
  { title: "Нові передплати", counter: "1 050" },
];

const GUESTS = [
  { title: "Нові", counter: "1 072" },
  { title: "Всі", counter: "1 050" },
  { title: "Старі", counter: "1 050" },
];

const SOURCES = [
  { title: "Safari", counter: "123" },
  { title: "IOS", counter: "366" },
  { title: "Android", counter: "9 827" },
];

const COUNTRIES = [
  { title: "Україна", counter: "22" },
  { title: "США", counter: "222" },
  { title: "Швейцарія", counter: "22" },
  { title: "Нідерланди", counter: "22" },
];

const ActivityPage = () => {
  const { windowSize } = useWindowSize();
  const isNotMobile = windowSize !== "mobile";

  return (
    <section className={styles.wrapper}>
      <Header className={styles.headerWrapper} backButtonClassName={styles.backButton} />
      {isNotMobile && <AdminSideBar />}
      <CounterLayout title="10 724" subtitle="Користувачів">
        <div className={styles.contentWrapper}>
          <TimelineFilter />
          <GridSection sectionTitle="Статистика" data={STATISTICS} variant="md" />
          <GridSection sectionTitle="Гості" data={GUESTS} variant="md" />
          <GridSection sectionTitle="Джерела" data={SOURCES} variant="sm" />
          <GridSection sectionTitle="Країни" data={COUNTRIES} variant="sm" withBorderBottom={false} />
        </div>
      </CounterLayout>
      {isNotMobile ? <Footer source={ROUTES.ACTIVITY} /> : <AdminFooter />}
    </section>
  );
};

export default ActivityPage;
