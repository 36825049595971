const AddReview = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={51}
      height={50}
      fill="none"
    >
      <path
        fill="#333"
        d="M23 22.5v-10h5v10h10v5H28v10h-5v-10H13v-5h10ZM25.5 50C11.693 50 .5 38.807.5 25s11.193-25 25-25 25 11.193 25 25-11.193 25-25 25Zm0-5c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20Z"
      />
    </svg>
  )
}

export default AddReview;
