import styles from "./ActorCard.module.scss"

type Props = {
  fullName: string
  description: string
  image: string
}

const ActorCard = ({ fullName, description, image }: Props) => (
  <div className={styles.wrapper}>
    <img
      width={130}
      height={130}
      className={styles.image}
      src={image ? image : ""}
      alt="#"
    />
    <div className={styles.textWrapper}>
      <h3 className={styles.fullName}>{fullName}</h3>
      <p className={styles.description}>{description}</p>
    </div>
  </div>
)

export default ActorCard
