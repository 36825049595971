import { ComponentProps } from "react";

interface IProps extends ComponentProps<"svg"> {}

const Cooperation1 = ({ ...props }: IProps) => (
  <svg width="100%" height="auto" viewBox="0 0 220 140" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.5 22.5H219.5V132C219.5 136.142 216.142 139.5 212 139.5H8.00001C3.85787 139.5 0.5 136.142 0.5 132V22.5Z"
      fill="#222222"
      stroke="#333333"
    />
    <path
      d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H212C216.142 0.5 219.5 3.85786 219.5 8V22.5H0.5V8Z"
      fill="#111111"
      stroke="#333333"
    />
    <circle cx="10.5" cy="11.5" r="1.5" fill="#555555" />
    <circle cx="16.5" cy="11.5" r="1.5" fill="#555555" />
    <circle cx="22.5" cy="11.5" r="1.5" fill="#555555" />
    <rect x="50" y="49" width="120" height="65" rx="8" fill="#E50914" />
    <path
      d="M116 81.5C116 81.6959 115.953 81.8885 115.862 82.0593C115.771 82.23 115.64 82.3729 115.482 82.4742L105.658 88.8302C105.493 88.9374 105.303 88.996 105.109 88.9998C104.915 89.0036 104.723 88.9525 104.554 88.8518C104.386 88.7527 104.246 88.6081 104.149 88.4329C104.052 88.2578 104 88.0584 104 87.8552V75.1448C104 74.9416 104.052 74.7422 104.149 74.5671C104.246 74.3919 104.386 74.2473 104.554 74.1482C104.723 74.0475 104.915 73.9964 105.109 74.0002C105.303 74.004 105.493 74.0626 105.658 74.1698L115.482 80.5258C115.64 80.6271 115.771 80.77 115.862 80.9407C115.953 81.1115 116 81.3041 116 81.5Z"
      fill="#FAFAFA"
    />
    <rect x="10" y="61" width="10" height="10" rx="2" fill="#444444" />
    <rect x="10" y="76" width="10" height="10" rx="2" fill="#444444" />
    <rect x="10" y="91" width="10" height="10" rx="2" fill="#444444" />
    <circle cx="195" cy="74" r="5" fill="#444444" />
    <circle cx="195" cy="89" r="5" fill="#444444" />
  </svg>
);
export default Cooperation1;
