import styles from "./Footer.module.scss";
import { useEffect, useState } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { footerMenuItems, ROUTES_WITHOUT_FOOTER } from "../../constants/structure";
import SideBarIcon from "../sideBar/SideBaricon";
import FooterLogo from "../icons/FooterLogo";
import useWindowSize from "../../hooks/useWindowSize";
import {ROUTES} from "../../constants/routes";
import {useTranslation} from "react-i18next";
import SupportIcon from "../icons/footer/SupprotIcon";

interface IProps {
  source: string;
}
const Footer = ({ source }: IProps) => {
  const [horizontal, setHorizontal] = useState(true);
  const { windowSize } = useWindowSize();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const focusHandler = () => {
    const chooseWrapper = document.getElementById("choose_section");

    if (chooseWrapper) {
      chooseWrapper.focus();
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener(
        "orientationchange",
        () => {
          if (window.innerHeight > window.innerWidth) setHorizontal(false);
          else setHorizontal(true);
        },
        false,
      );
    }

    return () => {
      window.removeEventListener("orientationchange", () => {});
    };
  }, []);

  const footerContentHandler = () => {
    if (windowSize !== "mobile" && !ROUTES_WITHOUT_FOOTER.includes(pathname)) {
      return (
        <div
          className={`${styles.footerWrapper} ${source === "modal" || source === ROUTES.SEARCH
            ? styles.footerModalWrapper : ""}`}
          onClick={focusHandler}
        >
          <div className={styles.footerLogoWrapper}>
            <FooterLogo />
          </div>
          <div className={styles.links}>
            <Link to={ROUTES.DISCOUNT}>{t('Subscriptions')}</Link>
            <Link to={ROUTES.AGREEMENT}>{t('agreement')}</Link>
            <Link to={ROUTES.ABOUT_US}>{t('Aboutus')}</Link>
            <Link to={ROUTES.COOPERATION}>{t('Cooperation')}</Link>
          </div>
          <div className={styles.copy}>
            <div className={styles.supportBtn} onClick={() => navigate(ROUTES.SUPPORT_CHAT)}>
              <SupportIcon/>
              {t('Usersupport')}
            </div>
            <a href="/">© 2024<span>HIVIEWER.</span> {t('copyright')}.</a>
          </div>
        </div>
      );
    }

    if (windowSize === "mobile") {
      return (
        <div className={styles.footerWrapperMobile} style={!horizontal ? {display: "none" } : {}}>
          {footerMenuItems.map((item: any) => {
            const pathHandler = (path: string) => {
              if (path === 'profile') {
                return localStorage.getItem('accessToken') ? '/profile' : '/login'
              }

              if (path === 'invite') {
                return ROUTES.DISCOUNT
              }

              if (path === 'likes') {
                return ROUTES.FAVOURITES
              }

              return `/${path}`;
            }

            return (
              <Link key={item.path} to={pathHandler(item.path)} className={styles.itemWrapper}>
                <SideBarIcon
                  iconName={item.path}
                  selectedItem={pathname === `/${item.path}`}
                  isMobile={windowSize === "mobile"}
                />
              </Link>
            );
          })}
        </div>
      );
    }

    return <div />;
  };

  return footerContentHandler();
};

export default Footer;
