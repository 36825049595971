const CheckMark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={14}
    fill="none"
  >
    <path
      fill="#111"
      d="m5.795 10.875-3.47-3.47a.997.997 0 0 0-1.41 1.41l4.18 4.18c.39.39 1.02.39 1.41 0l10.58-10.58a.997.997 0 1 0-1.41-1.41l-9.88 9.87Z"
    />
  </svg>
)

export default CheckMark;
