import {useEffect, useRef, useState} from "react";
import styles from "./CustomSelect.module.scss";
import ArrowUp from "../../icons/forYou/ArrowUp";
import VisaLogo from "../../icons/profile/visaLogo";
import MasterLogo from "../../icons/profile/masterLogo";
import Card from "../../icons/profile/card";
import {useTranslation} from "react-i18next";

interface Option {
  value: string;
  label: string;
  icon?: string;
}

interface CustomSelectProps {
  options: Option[];
  cardsFlag?: boolean;
  outsideHandler?: (value: string) => void;
  maxHeight?: number;
  label?: string;
  width?: string;
  inputValuesHandler: (key: string, value: any) => void;
  savingKey: string;
}

const CustomSelect = ({
  options,
  cardsFlag,
  outsideHandler,
  maxHeight,
  label,
  width,
  inputValuesHandler,
  savingKey
}:CustomSelectProps) => {
  const {t} = useTranslation();
  const elementRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    label ? { label, value: label } : options[0]
  );

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    setIsOpen(false);
    inputValuesHandler(savingKey, option.value);

    if (outsideHandler) {
      outsideHandler(option.value);
    }
  };

  const formatCardNumber = (input: number | string): number | string => {
    if (input === t('creditCard')) return t('creditCard');

    const cardNumber = input.toString();
    const lastFourDigits = cardNumber.slice(-4);
    const maskedNumber = cardNumber.slice(0, -4).replace(/\d/g, '*');

    return maskedNumber.replace(/(.{4})/g, '$1 ').trim() + ' ' + lastFourDigits;
  }

  const cardsAdditionalComponentRendering = () => {
    return (
      <>
        <div className={`${styles.cardsSeparator}`}>{t('payMethods')}</div>
        <div
          className={`${styles.payMethod} ${styles.newCardOption}`}
          onClick={() => handleOptionClick({ value: t('creditCard'), label: t('creditCard'), icon: t('creditCard') })}
        >
          <Card/>{t('creditCard')}
        </div>
      </>
    )
  }

  const cardsLabelRendering = (selectedOption: Option) => {
    return (
      <div className={styles.selectItemCard}>
        {selectedOption?.icon === 'visa' ? <VisaLogo/> : null}
        {selectedOption?.icon === 'master' ? <MasterLogo/> : null}
        {selectedOption?.icon === t('creditCard') ? <Card/> : null}
        {formatCardNumber(selectedOption.label)}
      </div>
    )
  }

  const cardsOptionRendering = (option: Option) => {
    return (
      <div className={styles.selectItemCard}>
        {formatCardNumber(option.label)}
      </div>
    )
  }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickOutside = (event: MouseEvent) => { // @ts-ignore
    if (elementRef.current && !elementRef.current.contains(event?.target) && isOpen) {
      setIsOpen(false)
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside, elementRef]);

  return (
    <div ref={elementRef} className={styles.customSelect} style={{width: width ? width : ''}}>
      <div className={`${styles.selectBox} ${isOpen ? styles.focused : ''}`} onClick={toggleDropdown}>
        {selectedOption ? (
          cardsFlag ? cardsLabelRendering(selectedOption) : selectedOption?.label
        ) : 'Select an option'}
        {isOpen ?
          <span className={styles.arrow}><ArrowUp /></span> :
          <span className={`${styles.arrow} ${styles.arrowSwip}`}><ArrowUp /></span>
        }
      </div>
      {isOpen && (
        <div className={`${styles.optionsContainer} ${styles.customScrollbar}`} style={{maxHeight}}>
          {options.map((option) => (
            <div key={option.value} className={styles.option} onClick={() => handleOptionClick(option)}>
              {cardsFlag ? cardsOptionRendering(option) : option.label}
            </div>
          ))}
          {cardsFlag && cardsAdditionalComponentRendering()}
        </div>
      )}
    </div>
  )
}

export default CustomSelect;
