import styles from "./TimelineFilter.module.scss";
import { TIME_FILTER_BUTTONS } from "../../../constants/global";
import React, { useState } from "react";
import classnames from "classnames";
import useWindowSize from "../../../hooks/useWindowSize";
import Filter from "../../icons/Filter";

export const TimelineFilter = () => {
  const [selectedFilter, setSelectedFilter] = useState("День");
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setSelectedFilter(event.currentTarget.textContent || "");
  };

  if (isMobile) {
    return (
      <button className={styles.filterButtonMobile}>
        <Filter />
        <span>День</span>
      </button>
    );
  }

  return (
    <div className={styles.filterButtonWrapper}>
      {TIME_FILTER_BUTTONS.map((title) => {
        return (
          <button
            key={title}
            className={classnames(styles.button, {
              [styles.selectedFilter]: title === selectedFilter,
            })}
            onClick={handleClickFilter}
          >
            {title}
          </button>
        );
      })}
    </div>
  );
};
