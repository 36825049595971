const XIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10}
      height={10}
      fill="none"
    >
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeWidth={2}
        d="m8.5 8.5-7-7m7 0-7 7"
      />
    </svg>
  )
}

export default XIcon;
