import React from "react"

import ActorCard from "./ActorCard"
import styles from "./MainActorsList.module.scss"
import Heading from "../../../../components/shared/Heading";
import {useGetCastsListQuery} from "../../../../store/api/filmAPI";
import {EN} from "../../../../constants/local";
import {useAppSelector} from "../../../../store/store";
import DotsLoader from "../../../../components/icons/DotsLoader";

type Props = {
  sectionTitle: string
}

type Person = {
  adult: boolean;
  gender: number;
  id: number;
  known_for_department: string;
  name: string;
  original_name: string;
  popularity: number;
  profile_path: string;
  cast_id: number;
  character: string;
  credit_id: string;
  order: number;
};

const MainActorsList = ({ sectionTitle }: Props) => {
  const selectedMovie = useAppSelector((state: any) => state.item.currentItem);
  const { data, isLoading } = useGetCastsListQuery({
    queries: { language: EN },
    id: selectedMovie.id,
  })

  if (isLoading) {
    return <DotsLoader />
  }
  return (
    <div className={styles.wrapper}>
      <Heading title={sectionTitle} />
      <div className={styles.cardWrapper}>
        {data.cast.map((item: Person, index: number) => item.profile_path ? (
          <ActorCard
            key={index}
            fullName={item.name}
            description={item.character}
            image={`${process.env.REACT_APP_IMG_BASE_URL}${item.profile_path}`}
          />
        ) : (<></>))}
      </div>
    </div>
  )
}

export default MainActorsList
