import Button from "../Button/Button";
import {useTranslation} from "react-i18next";
import {useRef, useState} from "react";
import styles from './TestimonialInput.module.scss';
import testAvatar from "../../../static/imgs/testAvatar.png";

interface IProps {
  placeholder: string
  avatarSize: number
  valueHandler: (value: string) => void
  sendHandler: () => void
}

const TestimonialInput = ({ placeholder, avatarSize, valueHandler, sendHandler }: IProps) => {
  const {t} = useTranslation();
  const textareaRef = useRef(null);
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [brekPoint, setBrekPoint] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleInput = (e: any) => {
    if (textareaRef.current) {
      // @ts-ignore
      textareaRef.current.style.height = '33px'; // @ts-ignore
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 55)}px`;

      const lineHeight = parseInt(window.getComputedStyle(textareaRef.current).lineHeight, 10);

      // @ts-ignore
      const scrollHeight = textareaRef.current.scrollHeight;
      setBrekPoint(scrollHeight > lineHeight * 1.5);

      if (e.target.value.length === 0) {
        setBrekPoint(false)
      }
    }
  };

  return (
    <div className={`${styles.wrapper} ${brekPoint ? styles.wrapperBrekpoint : ''}`}>
      <div className={styles.avatarWrapper}>
        <img src={testAvatar} alt="avatar" width={avatarSize} height={avatarSize} />
      </div>
      <div className={`
          ${styles.inputWrapper}
          ${isFocused ? styles.focused : ''}
          ${brekPoint ? styles.breckpoint : ''}
        `}>
        <textarea
          ref={textareaRef}
          className={styles.input}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onInput={handleInput}
          onChange={(e) => {
            setValue(e.target.value)
            valueHandler(e.target.value)
          }}
          value={value}
        />
        <Button
          title={t('sendComment')}
          submit={() => {
            sendHandler();

            setValue('');
          }}
          className={styles.btn}
        />
      </div>
    </div>
  )
}

export default TestimonialInput;
