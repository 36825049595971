const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
    >
      <path
        stroke="#A7A7A7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m6 12 4.125 4M6 12l4.125-4M6 12h7m5 0h-2"
      />
    </svg>
  )
}

export default Arrow
