interface IProps {
  className: string
}

const LikeIcon = ({className}:IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={17}
      fill="none"
    >
      <path
        fill="rgba(0, 0, 0, 0)"
        className={className}
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M6.175 1C3.593 1 1.5 3.152 1.5 5.807 1.5 10.614 7.025 14.984 10 16c2.975-1.016 8.5-5.386 8.5-10.193C18.5 3.152 16.407 1 13.825 1c-1.581 0-2.98.807-3.825 2.042A4.712 4.712 0 0 0 8.33 1.54 4.567 4.567 0 0 0 6.175 1Z"
      />
    </svg>
  )
}

export default LikeIcon;
