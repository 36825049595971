import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import styles from "./Discount.module.scss";
import DiscountIcon from "../../components/icons/discount/discountIcon";
import CopyIcon from "../../components/icons/discount/copy";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const data = [
  {
    mounts: '1',
    costs: '0',
    continue: 'далі 82 грн/міс.'
  },
  {
    mounts: '3',
    costs: '99',
    continue: 'далі 165 грн/ 3 міс.'
  },
  {
    mounts: '1',
    costs: '0',
    continue: 'далі 827 грн/міс.'
  }
]
const Discount = () => {
  const {t} = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.discountPageWrapper}>
      <Header className={styles.headerDiscount}/>
      <div className={styles.discountContent}>
        <div className={styles.iconWrapper}><DiscountIcon/></div>
        <div className={styles.discountTitle}>Отримай знижку на передплату</div>
        <div className={styles.discountDescription}>Пропозиція діє до 22.02.2025</div>
        <div className={styles.cards}>
          {data.map((item, index) => {
            return (
              <div key={index} className={styles.cardWrapper}>
                <div className={styles.cardCosts}>
                  <span>{item.mounts} місяць</span>
                  <span>{item.costs} <span className={styles.costCurrency}>грн</span></span>
                </div>
                <div className={styles.cardContinue}>{item.continue}</div>
                <div className={styles.cardButton}>Підключити</div>
              </div>
            )
          })}
        </div>
      </div>
      <div style={{height: '50px'}}/>
      <Footer source={''}/>
      {openModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <div className={styles.textWrapper}>
              <div className={styles.modalTitle}>{t('linkModal')}</div>
              <div className={styles.modalDescription}>https://hiviewer.ai/Y6xZa9Hn6pV</div>
            </div>
            <div onClick={() => setOpenModal(false)}><CopyIcon/></div>
          </div>
        </div>
      )}
    </div>
  )
};

export default Discount;
