const SavedSmall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={37}
      height={50}
      fill="none"
    >
      <path
        fill="#282828"
        fillRule="evenodd"
        d="M0 1.667C0 .747.72 0 1.609 0H35.39C36.28 0 37 .746 37 1.667v46.666c0 .576-.287 1.111-.759 1.415a1.56 1.56 0 0 1-1.56.08L18.5 41.582 2.319 49.829a1.56 1.56 0 0 1-1.56-.08A1.681 1.681 0 0 1 0 48.332V1.667Zm3.217 1.666v42.323l14.573-7.428a1.558 1.558 0 0 1 1.42 0l14.573 7.428V3.333H3.217Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default SavedSmall;
