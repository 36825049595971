import React, {PropsWithChildren, useState} from "react";
import styles from "./CounterLayout.module.scss";
import ArrowDown from "../../../components/icons/ArrowDown";
import classnames from "classnames";

interface IProps extends PropsWithChildren{
  title: string;
  subtitle: string;
}

const CounterLayout = ({title, subtitle, children}: IProps) => {
  const [showAnalytics, setShowAnalytics] =  useState(false);
  const handleShowAnalytics = () => setShowAnalytics(!showAnalytics);

  return (
    <div className={classnames(styles.container, {
      [styles.showContent]: !showAnalytics
    })}>
      <div className={styles.contentContainer}>
        <div className={styles.textWrapper}>
          <p className={styles.counterTitle}>{title}</p>
          <p className={styles.counterSubtitle}>{subtitle}</p>
        </div>
        <ArrowDown className={classnames(styles.arrow, {
          [styles.rotateArrow]: showAnalytics
        })} onClick={handleShowAnalytics}/>
        {showAnalytics && <>{children}</>}
      </div>
    </div>
  );
};

export default CounterLayout;
