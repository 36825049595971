import {useContext} from "react";
import {InterfaceContext} from "./InterfaceContext";

const useInterfaceContext = () => {
    const context = useContext(InterfaceContext);

    if (!context) {
        throw new Error('useInterfaceContext must be used within an InterfaceProvider');
    }

    return context;
};

export default useInterfaceContext