const DiscountIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
    >
      <circle cx={19.809} cy={19.809} r={13.714} fill="#FAFAFA"/>
      <path
        fill="#EF3500"
        d="M14.286 15.714a1.429 1.429 0 1 1 0-2.857 1.429 1.429 0 0 1 0 2.857Zm11.428 8.572a1.428 1.428 0 1 0 0 2.856 1.428 1.428 0 0 0 0-2.856ZM40 20c0 1.864-1.341 3.262-2.525 4.496-.673.704-1.37 1.429-1.632 2.067-.243.583-.257 1.551-.272 2.489-.026 1.743-.055 3.718-1.428 5.09-1.373 1.374-3.348 1.403-5.091 1.43-.938.014-1.906.028-2.49.27-.637.263-1.362.96-2.066 1.633C23.263 38.659 21.864 40 20 40c-1.864 0-3.262-1.341-4.496-2.525-.704-.673-1.429-1.37-2.066-1.632-.584-.243-1.552-.257-2.49-.272-1.743-.026-3.718-.055-5.09-1.428-1.374-1.373-1.403-3.348-1.43-5.091-.014-.938-.028-1.906-.27-2.49-.263-.637-.96-1.362-1.633-2.066C1.341 23.263 0 21.864 0 20c0-1.864 1.341-3.262 2.525-4.496.673-.704 1.37-1.429 1.632-2.066.243-.584.257-1.552.272-2.49.026-1.743.055-3.718 1.428-5.09 1.373-1.374 3.348-1.403 5.091-1.43.938-.014 1.906-.028 2.49-.27.637-.263 1.362-.96 2.066-1.633C16.738 1.341 18.136 0 20 0c1.864 0 3.262 1.341 4.496 2.525.704.673 1.429 1.37 2.067 1.632.583.243 1.551.257 2.489.272 1.743.026 3.718.055 5.09 1.428 1.374 1.373 1.403 3.348 1.43 5.091.014.938.028 1.906.27 2.49.263.637.96 1.362 1.633 2.066C38.659 16.738 40 18.136 40 20Zm-25.714-1.429a4.286 4.286 0 1 0 0-8.571 4.286 4.286 0 0 0 0 8.571Zm13.868-4.703a1.43 1.43 0 0 0-2.022-2.022L11.846 26.132a1.43 1.43 0 0 0 2.022 2.022l14.286-14.286ZM30 25.714a4.286 4.286 0 1 0-8.572 0 4.286 4.286 0 0 0 8.572 0Z"
      />
    </svg>
  )
}

export default DiscountIcon;
