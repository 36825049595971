import {Suspense, useEffect, useRef, useState} from "react"

import styles from "./_styles/Choose.module.scss"
import {useAppSelector} from "../../store/store";
import {EN, UA} from "../../constants/local";
import {useGetSearchFilmsListQuery} from "../../store/api/filmAPI";
import {ISectionItem} from "./_types/interfaces";
import MobilePlayer from "./_components/section/MobilePlayer";
import DesctopPlayer from "./_components/section/DesctopPlayer";
import Footer from "../../components/footer/Footer";
import SideBar from "../../components/sideBar/SideBar";
import Header from "../../components/header/Header";
import {useDispatch} from "react-redux";
import {setMutedState} from "../../store/slices/filters.slice";
import ArrowUp from "../../components/icons/forYou/ArrowUp";
import ArrowDown from "../../components/icons/forYou/ArrowDown";
import {ROUTES} from "../../constants/routes";
import {setLastVisitedId} from "../../store/slices/currentItem.slice";
import {useNavigate} from "react-router-dom";
import FiltersButton from "./_components/FiltersHeader/FiltersButton";
import Loader from "../../components/loader/Loader";
import useWindowSize from "../../hooks/useWindowSize";
import {useTranslation} from "react-i18next";
import TipsComponent from "../../components/icons/tips/TipsComponent";
import ShareModal from "../../components/shared/ShareModal/ShareModal";

// TODO NEED MOVE STATES FROM COMPONENT TO INTERFACE PROVIDER AND REMOVE HERE

const Choose = () => {
  const [page, setPage] = useState(1)
  const [moviesList, setMoviesList] = useState<any[]>([]);
  const [height, setHeight] = useState(0)
  const [horizontal, setHorizontal] = useState(true)
  const [guide, setGuide] = useState(true);
  const {genres, period, periodCHanged} = useAppSelector((state: any) => state.filters)
  const ref = useRef(null)
  const muted = useAppSelector((state: any) => state.filters.mutedState);
  const currentId = useAppSelector((state: any) => state.item.currentVideo);
  const lastVisited = useAppSelector((state: any) => state.item.lastVisited);
  const { shareModal } = useAppSelector((state: any) => state.modals);

  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";

  const dispatch = useDispatch();

  const setMuted = (value: any) => {
    dispatch(setMutedState(value))
  }

  const filterErrorItem = (id: number) => {
    setMoviesList((prevState) => prevState.filter((item: any) => item.id !== id))
  }

  const queryHandler = () => {
    if (genres.length || periodCHanged) {
      return {
        page: page,
        language: i18n.language === 'en' ? EN : UA,
        with_genres: genres.length ? genres.toString() : "",
        release_date_gte: `${period[0]}-01-01`,
        release_date_lte: `${period[1]}-01-01`,
        include_video: 'true',
        site: 'YouTube',
        sort_by: 'popularity.desc',
      }
    }

    if (!genres.length && !periodCHanged) {
      return {
        page: page,
        language: i18n.language === 'en' ? EN : UA,
        include_video: 'true',
        site: 'YouTube',
        sort_by: 'popularity.desc',
      }
    }
  }

  const { data, isLoading, isFetching } = useGetSearchFilmsListQuery({
    mainFilter: "/movies/sandbox",
    queries: {
      ...queryHandler(),
      page: page
    },
  });

  useEffect(() => {
    if (localStorage.getItem("guide")) {
      setGuide(false);
    }

    const handleStorageChange = () => {
      const savedData = localStorage.getItem("guide");
      console.log(savedData, 'savedData')
      if (savedData === "1") {
        setGuide(false);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (!data || isFetching) return;
    if ((data?.results && data?.results.length) && (page > 1)) {
      setMoviesList((prevState) => [...prevState, ...data.results]);
    } else {
      setMoviesList(data.results);
    }
  }, [data, isFetching, page]);

  const setPageHandler = () => {
    setPage((prevState: number) => prevState + 1)
  }

  const arrowNavHandler = (direction: string, id: number) => {
    const currentIndex = moviesList.findIndex((item: any) => item.id === id)
    const item = document.getElementById(moviesList[direction === "up" ? currentIndex - 1 : currentIndex + 1]?.id)

    if (item) {
      item.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
    }
  }

  const focusHandler = () => {
    // @ts-ignore
    ref.current?.focus()
  }

  const goToFilters = () => {
    navigate(ROUTES.FILTERS);
    dispatch(setLastVisitedId(currentId))
  }

  useEffect(() => {
    if (lastVisited !== 0 && moviesList) {
      const item = document.getElementById(lastVisited)
      if (item) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            item.scrollIntoView({ block: "center", inline: "center" });
          });
        });
      }
    }
  }, [lastVisited, moviesList]);

  useEffect(() => {
    setPage(1)
  }, [genres])

  useEffect(() => {
    if (!isMobile) {
      const handleWheel = (event: any) => {
        event.preventDefault()
      }

      const handleKeyDown = (event: any) => {
        if (event.key === "ArrowUp" || event.key === "ArrowDown" || event.code === "Space") {
          return
        }
        event.preventDefault()
      }

      document.addEventListener("wheel", handleWheel, { passive: false })
      document.addEventListener("keydown", handleKeyDown)

      return () => {
        document.removeEventListener("wheel", handleWheel)
        document.removeEventListener("keydown", handleKeyDown)
      }
    }
  }, [isMobile])

  useEffect(() => {
    if (currentId && moviesList.length) {
      const currentIndex = moviesList.findIndex((item: any) => item.id === currentId)
      if (currentIndex === moviesList.length - 35) {
        setPageHandler()
      }

      // @ts-ignore
      ref.current?.focus()
    }
  }, [currentId, moviesList])

  const handleResize = () => {
    setHeight(window?.innerHeight)
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setHeight(window?.innerHeight)

      window.addEventListener(
        "orientationchange",
        () => {
          if (window.innerHeight > window.innerWidth) setHorizontal(false)
          else setHorizontal(true)
        },
        false
      )
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener("orientationchange", () => {})
      setHorizontal(true)
    }
  }, [])

  return moviesList ? (
    <Suspense fallback={null}>
      <section
        className={styles.mobileContainer}
        style={{height: `${height}px`}}
        ref={ref}
        tabIndex={0}
        id={"choose_section"}>
        <Header className={styles.headerWrapper}/>
        <SideBar/>
        <Footer source={''}/>
        {!isLoading && !guide &&
          moviesList?.map((item: ISectionItem, index: number) => (
            <div
              key={`${item.id}_${index}`}
              id={`${item.id}`}
              className={isMobile ? styles.item : styles.itemDesctop}
              style={{height: `${height}px`, overflow: "hidden"}}>
              {isMobile ? (
                <MobilePlayer
                  item={item}
                  horizontal={horizontal}
                  currentId={currentId}
                  filterErrorItem={filterErrorItem}
                />
              ) : (
                <DesctopPlayer
                  setMuted={setMuted}
                  muted={muted}
                  item={item}
                  currentId={currentId}
                  horizontal={horizontal}
                  focusHandler={focusHandler}
                  filterErrorItem={filterErrorItem}
                />
              )}
            </div>
          ))}
        {!isMobile && (
          <div className={styles.navFiltersWrapper}>
            <FiltersButton goToFilters={goToFilters}/>
            <div className={styles.playerRemoteController}>
              <div className={styles.navArrowWrapper} onClick={() => arrowNavHandler("up", currentId)}>
                <ArrowUp className={styles.arrowIcon}/>
              </div>
              <div
                className={`${styles.navArrowWrapper} ${styles.arrowDown}`}
                onClick={() => arrowNavHandler("down", currentId)}
              >
                <ArrowDown className={styles.arrowIcon}/>
              </div>
            </div>
          </div>
        )}
      </section>
      {shareModal && <ShareModal />}
      {guide && <TipsComponent setGuide={setGuide}/>}
    </Suspense>
  ) : <div className={styles.preloaderWrapper}><Loader /></div>
}

export default Choose
