import {useEffect, useState} from "react";

import styles from "./Header.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "../icons/ArrowBack";
import LogoIcon from "../icons/Logo";
import FilterIcon from "../icons/Filters";
import useWindowSize from "../../hooks/useWindowSize";
import { ROUTES } from "../../constants/routes";
import { FilterLayout } from "./FilterLayout";
import { BackButtonLayout } from "./ArrowBackLayout";
import { LoginButtonLayout } from "./LoginButtonLayout";
import classnames from "classnames";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../store/store";
import {setLastVisitedId} from "../../store/slices/currentItem.slice";
import {LocalizationLayout} from "./LocalizationLayout";
import headerAvatar from '../../static/imgs/headerAvatar.png'
import {useTranslation} from "react-i18next";
import LanguageDropDown from "./LanguageDropDown";
import AccountDropDown from "./AccountDropDown";
import MobileCloseModule from "./MobileCloseModule";
import CommonCloseModule from "./CommonCloseModule";

interface IProps {
  className?: string;
  backButtonClassName?: string;
  source?: string;
  clickHandler?: any;
}

const Header = ({ className, backButtonClassName, source, clickHandler }: IProps) => {
  const { pathname } = useLocation();
  const { windowSize } = useWindowSize();
  const { t,i18n } = useTranslation();
  const token = localStorage.getItem('accessToken');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentId = useAppSelector((state: any) => state.item.currentVideo);
  const genres = useAppSelector((state: any) => state.filters.genres)
  const period = useAppSelector((state: any) => state.filters.period)
  const firstName = useAppSelector((state: any) => state.auth.userData.username);

  const [languageDrop, setLanguageDrop] = useState(false);
  const [accDrop, setAccDrop] = useState(false);
  const [loggedInFlag, setLoggedInFlag] = useState(token);

  const languageClickHandler = () => {
    if (accDrop) {
      setAccDrop(false)
    }
    setLanguageDrop(!languageDrop)
  }

  const languageModalLeaveHandler = () => {
    if (accDrop) {
      setAccDrop(false)
    }
    setLanguageDrop(false)
  }

  const accClickHandler = () => {
    if (languageDrop) {
      setLanguageDrop(false)
    }
    setAccDrop(!accDrop)
  }

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  const filterCounterHandler = (gen: number[], per: number[]) => {
    let counter = 0;
    if (per[0] !== 1980 ||  per[1] !== 2024) {
      counter += 1;
    };

    counter += gen.length;

    return counter;
  }

  const refreshHandler = () => {
    window.location.href = ROUTES.FOR_YOU;
  };

  const goToPage = (path: string) => {
    navigate(path);
  };

  const focusHandler = () => {
    const chooseWrapper = document.getElementById("choose_section");

    if (chooseWrapper) {
      chooseWrapper.focus();
    }
  };

  useEffect(() => {
    setLoggedInFlag(token);
  }, [token]);

  useEffect(() => {
    if (pathname === ROUTES.INDEX) {
      window.location.href = ROUTES.FOR_YOU;
    }
  }, [pathname]);

  return <div className={classnames(styles.wrapperMobile, className)} onClick={focusHandler}>
    <BackButtonLayout source={source}>
      <div
        className={classnames(styles.backButton, backButtonClassName, windowSize === "mobile" ? styles.mobileBack : '')}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIcon isMobile={windowSize === "mobile"}/>
      </div>
    </BackButtonLayout>
    <div className={styles.logoWrapperMobile} onClick={refreshHandler}>
      <LogoIcon/>
    </div>
    <div className={styles.headerButtonsWrapper}>
      {windowSize !== "mobile" && (
        <LocalizationLayout>
          <div
            onClick={languageClickHandler}
            className={`${styles.localizationButton} ${languageDrop ? styles.localizationButtonActive : ''}`}
          >
            {i18n.language === 'uk' ? 'UA' : 'EN'}
          </div>
          <LanguageDropDown
            changeLanguage={changeLanguage}
            languageClickHandler={languageClickHandler}
            leaveModalHandler={languageModalLeaveHandler}
            openedFlag={languageDrop}
          />
        </LocalizationLayout>
      )}
      <LoginButtonLayout isMobile={windowSize === "mobile"}>
        {loggedInFlag ? (
          <>
            <div
              onClick={accClickHandler}
              className={`${styles.headerProfile}`}
            >
              {accDrop && <div className={styles.activeHeaderProfile}/>}
              <img src={headerAvatar} alt="avatar"/>
              <span>{(firstName || 'U')[0]}</span>
            </div>
            <AccountDropDown openDropHandler={() => setAccDrop(false)} goToPage={goToPage} opened={accDrop} />
          </>
        ) : (
          <div onClick={() => goToPage(ROUTES.LOGIN)} className={styles.logInButton}>
            <span>{t('logInButton')}</span>
          </div>
          )
        }
      </LoginButtonLayout>
      <FilterLayout source={source} isMobile={windowSize === "mobile"}>
        {source === ROUTES.FILTERS ? (
          windowSize === 'mobile' ? <MobileCloseModule /> : <CommonCloseModule />
        ) : (
          <div onClick={() => {
            goToPage(ROUTES.FILTERS);
            dispatch(setLastVisitedId(currentId))
          }} className={`${windowSize === "mobile" ? styles.mobileFilterButton : styles.desctopFilterButton}`}>
            {windowSize === "mobile" && filterCounterHandler(genres, period) !== 0 ?
              <div className={styles.mobileHeaderButttonCounter}>{filterCounterHandler(genres, period)}</div> :
              <div/>
            }
            <FilterIcon isMobile={windowSize === "mobile"}/>
          </div>
        )}
      </FilterLayout>
    </div>
  </div>;
};

export default Header;
