import styles from "./SideBar.module.scss";
import {ROUTES_WITHOUT_SIDEBAR, sideBarMenuItems, userMenuItems} from "../../constants/structure";
import MenuWrapper from "../semantic/MenuWrapper";
import SideBarIcon from "./SideBaricon";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ROUTES} from "../../constants/routes";
import useWindowSize from "../../hooks/useWindowSize";

const SideBar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";

  const { t } = useTranslation();

  const focusHandler = () => {
    const chooseWrapper = document.getElementById('choose_section');

    if (chooseWrapper) {
      chooseWrapper.focus();
    }
  }


  const goToPage = (path: string) => {
    navigate(path);
  };

  return !ROUTES_WITHOUT_SIDEBAR.includes(pathname) && !isMobile ? (
    <div className={styles.wrapper} onClick={focusHandler}>
      <div className={styles.topSidebarWrapper}>
        <MenuWrapper>
          {sideBarMenuItems.map((item: any) => {
            return (
             <li key={item.title} className={styles.itemSemanticWrapper}>
               <div className={styles.itemWrapper} onClick={() => goToPage(`/${item.href}`)}>
                 <SideBarIcon iconName={item.path} selectedItem={pathname === `/${item.href}`}/>
                 <div className={`${styles.itemTitle} ${pathname === `/${item.path}` ? styles.itemTitleActive : ''}`}>
                   {t(item.title)}
                 </div>
               </div>
             </li>
            )
          })}
        </MenuWrapper>
      </div>
      <div>
        <MenuWrapper>
          {userMenuItems.map((item: any) => {
            const pathHandler = (path: string) => {
              if (path === '/profile') {
                return localStorage.getItem('accessToken') ? '/profile' : '/login'
              }

              if (path === '/invite') {
                return ROUTES.DISCOUNT
              }

              return path;
            }

            return (
              <li key={item.title} className={styles.itemSemanticWrapper}>
                <div className={styles.itemWrapper} onClick={() => goToPage(pathHandler(`/${item.path}`))}>
                  <SideBarIcon iconName={item.path} selectedItem={pathname === `/${item.path}`}/>
                  <div className={`${styles.itemTitle} ${pathname === `/${item.path}` ? styles.itemTitleActive : ''}`}>
                    {t(item.title)}
                  </div>
                </div>
              </li>
            )
          })}
        </MenuWrapper>
      </div>
    </div>
  ) : <div/>
};

export default SideBar;
