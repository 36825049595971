import {SVGProps} from "react";

const CopyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="#888"
      d="M10 10.213V26.25a6.25 6.25 0 0 0 5.84 6.237l.41.013h11.035A3.75 3.75 0 0 1 23.75 35H15a7.5 7.5 0 0 1-7.5-7.5V13.75a3.75 3.75 0 0 1 2.5-3.537ZM28.75 5a3.75 3.75 0 0 1 3.75 3.75v17.5A3.75 3.75 0 0 1 28.75 30h-12.5a3.75 3.75 0 0 1-3.75-3.75V8.75A3.75 3.75 0 0 1 16.25 5h12.5Zm0 2.5h-12.5A1.25 1.25 0 0 0 15 8.75v17.5a1.25 1.25 0 0 0 1.25 1.25h12.5A1.25 1.25 0 0 0 30 26.25V8.75a1.25 1.25 0 0 0-1.25-1.25Z"
    />
  </svg>
)

export default CopyIcon;
