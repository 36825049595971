import styles from '../styles/profile.module.scss';
import Button from "../../../components/shared/Button/Button";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setOpenModal} from "../../../store/slices/modals.slice";

const CardRemoveConfirmModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={styles.removeCardModalWrapper}>
      <div className={styles.removeCardModalTextContent}>
        <div>{t('removeCardTitle')}</div>
      </div>
      <div className={styles.removeCardModalDescr}>{t('removeCardDescription')}</div>
      <Button
        title={'OK'}
        submit={() => dispatch(setOpenModal(''))}
        className={styles.removeConfirm}
      />
    </div>
  )
}

export default CardRemoveConfirmModal;
