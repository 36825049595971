import {SVGProps} from "react";

const VisaLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={14}
      fill="none"
      {...props}
    >
      <path
        fill="#1434CB"
        d="M26.053.5c-2.84 0-5.378 1.477-5.378 4.205 0 3.128 4.502 3.345 4.502 4.916 0 .662-.756 1.255-2.048 1.255-1.833 0-3.203-.828-3.203-.828L19.34 12.8s1.578.699 3.674.699c3.105 0 5.548-1.55 5.548-4.324 0-3.306-4.52-3.516-4.52-4.974 0-.519.62-1.087 1.908-1.087a6.47 6.47 0 0 1 2.639.602l.573-2.659S27.872.5 26.054.5ZM.07.7 0 1.103s1.195.22 2.271.657c1.386.502 1.484.794 1.718 1.701l2.543 9.833H9.94L15.192.701h-3.4L8.416 9.262 7.04 2.005C6.913 1.175 6.274.701 5.49.701H.07Zm16.49 0-2.667 12.593h3.243L19.794.701H16.56Zm18.09 0c-.783 0-1.197.42-1.501 1.155l-4.752 11.438h3.401l.659-1.906h4.143l.4 1.906H40L37.382.701h-2.734Zm.442 3.403 1.008 4.725h-2.701l1.693-4.725Z"
      />
    </svg>
  )
}


export default VisaLogo;
