import { ROUTES } from "./routes";

export const sideBarMenuItems = [
  {
    title: "choose",
    path: "feed",
    href: "feed"
  },
  {
    title: "search",
    path: "search",
    href: "search"
  },
  {
    title: "selected",
    path: "likes",
    href: "favourites"
  },
];

export const adminSideBarMenuItems = [
  {
    title: "Активність",
    path: "activity",
  },
  {
    title: "Користувачі",
    path: "users",
  },
  {
    title: "Передплати",
    path: "pre-payment",
  },
];

export const footerMenuItems = [
  {
    title: "Пошук",
    path: "search",
  },
  {
    title: "Обрані",
    path: "likes",
  },
  {
    title: "Підібрати",
    path: "feed",
  },
  {
    title: "Запросити",
    path: "invite",
  },
  {
    title: "Профіль",
    path: "profile",
  },
];

export const footerAdminMenuItems = [
  {
    title: "Активність",
    path: "activity",
  },
  {
    title: "Користувачі",
    path: "users",
  },
  {
    title: "Передплати",
    path: "pre-payment",
  },
];

export const firstSectionInfo = [
  {
    path: "savedDetails",
    number: 34,
    additionalText:"До обраних",
    handler: "saved"
  },
  {
    path: "likesDetails",
    number: 345,
    handler: "like"
  },
  {
    path: "shareDetails",
    number: 23,
    handler: "share"
  },
];

export const firstSectionInfoMobile = [
  {
    path: "likes",
    number: 345,
    handler: "like"
  },
  {
    path: "comments",
    number: 23,
    handler: "comment"
  },
  {
    path: "savedDetails",
    number: 34,
    handler: "saved"
  },
  {
    path: "shareDetails",
    number: 564,
    handler: "share"
  },
];

export const infoBlockItems = [
  {
    path: "likes",
    number: 345,
    handler: "like"
  },
  // {
  //   path: "comments",
  //   number: 23,
  //   handler: "comment"
  // },
  {
    path: "saved",
    number: 34,
    handler: "saved"
  },
  {
    path: "secondaryShare",
    number: 564,
    handler: "share"
  },
];

export const userMenuItems = [
  {
    title: "invite",
    path: "invite",
  },
];

export const ROUTES_WITHOUT_SIDEBAR = [ROUTES.FILTERS, ROUTES.MORE];

export const routesWithoutFiltersHandler = (isMobile: boolean) => {
  let routes = [
    ROUTES.MORE,
    ROUTES.COOPERATION,
    ROUTES.SUPPORT,
    ROUTES.SEARCH,
    ROUTES.ABOUT_US,
    ROUTES.AGREEMENT,
    ROUTES.ACTIVITY,
    ROUTES.USERS,
    ROUTES.PRE_PAYMENT,
    ROUTES.LOGIN,
    ROUTES.CONFIRMATION,
    ROUTES.PROFILE,
    ROUTES.PAY,
    ROUTES.DISCOUNT,
    ROUTES.MOBILE_ACCOUNT_SETTINGS,
    ROUTES.MOBILE_PAYMENT_DETAILS,
    ROUTES.SUPPORT_CHAT,
    ROUTES.FAVOURITES,
    ROUTES.NOT_FOUND
  ]

  if (!isMobile) {
    routes.push(ROUTES.FOR_YOU)
  }

  if (isMobile) {
    routes = routes.filter(item => item !== ROUTES.SEARCH);
    routes = routes.filter(item => item !== ROUTES.FAVOURITES);
  }
  return routes;
}

export const ROUTES_WITHOUT_BACK_BUTTON = [ROUTES.FOR_YOU, ROUTES.FILTERS];
export const ROUTES_WITHOUT_LOCALIZATION = [ROUTES.FILTERS, ROUTES.LOGIN, ROUTES.CONFIRMATION];

export const ROUTES_WITHOUT_LOGIN_BUTTON = [
  ROUTES.FILTERS,
  ROUTES.LOGIN,
  ROUTES.CONFIRMATION,
  ROUTES.ACTIVITY,
  ROUTES.USERS,
  ROUTES.PRE_PAYMENT,
];

export const ROUTES_WITHOUT_FOOTER = [ROUTES.MORE];

export const mainData = [
  {
    url: "https://www.youtube.com/watch?v=basLDO2bj2k&pp=ygUHdHJhaWxlcg%3D%3D",
    id: 1,
  },
  {
    url: "https://www.youtube.com/watch?v=DtR76pz517E",
    id: 2,
  },
  {
    url: "https://www.youtube.com/watch?v=QdBZY2fkU-0&pp=ygUHdHJhaWxlcg%3D%3D",
    id: 3,
  },
  {
    url: "https://www.youtube.com/watch?v=lV1OOlGwExM&pp=ygUHdHJhaWxlcg%3D%3D",
    id: 4,
  },
];

export const genresData = [
  {
    id: 28,
    name: "Actionfilm",
  },
  {
    id: 12,
    name: "Adventure",
  },
  {
    id: 16,
    name: "Cartoon",
  },
  {
    id: 35,
    name: "Comedy",
  },
  {
    id: 80,
    name: "Crime",
  },
  {
    id: 99,
    name: "Documentary",
  },
  {
    id: 18,
    name: "Drama",
  },
  {
    id: 10751,
    name: "Family",
  },
  {
    id: 14,
    name: "Fantasy",
  },
  {
    id: 36,
    name: "Historical",
  },
  {
    id: 27,
    name: "Horrors",
  },
  {
    id: 10402,
    name: "Music",
  },
  {
    id: 9648,
    name: "Detective",
  },
  {
    id: 10749,
    name: "Romance",
  },
  {
    id: 878,
    name: "Fiction",
  },
  {
    id: 10770,
    name: "TelevisionFilm",
  },
  {
    id: 53,
    name: "Thriller",
  },
  {
    id: 10752,
    name: "war",
  },
  {
    id: 37,
    name: "western",
  },
];
