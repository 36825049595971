import styles from "./SimilarContentCard.module.scss"
import LikeButton from "../../../../components/shared/LikeButton";
import {genresData} from "../../../../constants/structure";
import {useTranslation} from "react-i18next";
import {setCurrentItem} from "../../../../store/slices/currentItem.slice";
import {ROUTES} from "../../../../constants/routes";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import HoverPlay from "../../../../components/icons/HoverPlay";
import React from "react";
import {truncateString} from "../../../../utils/helpers";
import FilmRating from "../../../../components/icons/searchIcons/fimRating";

type Movie = {
  id: number;
  genre_ids: number[];
  reviews_count: number | null;
  adult: boolean;
  backdrop_path: string;
  original_language: string;
  original_title: string;
  overview: string;
  overview_ru: string;
  overview_ua: string;
  popularity: number;
  poster_path: string;
  release_date: string;
  title: string;
  title_ru: string;
  title_ua: string;
  video: boolean;
  vote_average: number;
  vote_count: number;
  not_found: boolean;
  liked: boolean;
};

interface ISimilarContentCardProps {
  isMobile: boolean
  movie: Movie
  likeHandler: (e: any, item: any, liked: boolean) => void;
  liked: boolean
}

const SimilarContentCard = ({
  isMobile,
  movie,
  likeHandler,
  liked
}: ISimilarContentCardProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const title = i18n.language === 'en' ? movie.title : movie.title_ua;
  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        dispatch(setCurrentItem(movie));
        navigate(ROUTES.DETAILS);
      }}
      className={styles.cardWrapper}
    >
      <div className={styles.imgSimilarWrapper}>
        <div className={styles.hoverPlayIcon}><HoverPlay className={styles.wrapperPath}/></div>
        <img
          width={119}
          height={166}
          className={styles.image}
          src={`${process.env.REACT_APP_IMG_BASE_URL}${movie.poster_path}`}
          alt="#"
        />
      </div>
      <div className={styles.textWrapper}>
        <h4 className={styles.movieTitle}>{truncateString(title, 17)}</h4>
        <div className={styles.infoWrapper}>
          <div className={styles.rowInfoWrapper}>
            <div className={styles.badge}>{movie.release_date?.substring(0, 4) || '2022'}</div>
            <p className={styles.country}>USA</p>
          </div>
          <p className={styles.description}>
            {movie.genre_ids[0] && t(`${genresData.find((genre) => genre.id === movie.genre_ids[0])?.name}`)}{' '}
            {movie.genre_ids[1] && t(`${genresData.find((genre) => genre.id === movie.genre_ids[1])?.name}`)}
          </p>
          <div className={styles.ratingWrapper}>
            <FilmRating/>
            <p className={styles.rating}>{movie.vote_average?.toFixed(1)}</p>
          </div>
        </div>
        <LikeButton
          count={movie.vote_count}
          direction="row"
          isMobile={isMobile}
          liked={liked}
          clickHandler={likeHandler}
          item={movie}
        />
      </div>
    </div>
  )
}

export default SimilarContentCard
