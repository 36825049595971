import styles from "../../foryou/_components/DesctopComponents/Desctop.module.scss";
import StarIcon from "../../../components/icons/details/StarIcon";
import {useTranslation} from "react-i18next";

interface IProps {
  title: string,
  releaseDate: string,
  genres: any,
  runtime: number,
  voteAverage: number
}

const InfoData = ({
  title,
  releaseDate,
  genres,
  runtime,
  voteAverage,
}: IProps) => {
  const {t} = useTranslation();
  return (
    <div>
      <div className={styles.title}>{title}</div>
      <div className={styles.ganres}>
        <span>{releaseDate ? releaseDate.split("-")[0] : "2023"}</span>
        {genres && (
          <>
            {genres[0] && <span>{t(`${genres[0]?.name}`)}</span>}
            {genres[1] && <span>{t(`${genres[1]?.name}`)}</span>}
          </>
        )}
        <span>{runtime} {t('minutes')}</span>
      </div>
      <div className={styles.reit}>
        <StarIcon/>
        <span>{voteAverage ? voteAverage.toFixed(1) : 9.1}</span>
      </div>
    </div>
  )
}

export default InfoData;
