import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserProfile {
  email: string;
  username: string;
  birth_day: string | null;
  birth_month: string | null;
  birth_year: string | null;
  address: string | null;
  sex: 'male' | 'female' | 'other';
  active: boolean;
  fname: string | null;
  lname: string | null;
  accept_notifications: boolean;
  picture_url: string | null;
}

interface AuthState {
  token: string | null;
  refreshToken: string | null;
  userData: UserProfile
}

const initialState: AuthState = {
  token: null,
  refreshToken: null,
  userData: {
    email: '',
    username: '',
    birth_day: '',
    birth_month: '',
    birth_year: '',
    address: '',
    sex: 'other',
    active: true,
    fname: '',
    lname: '',
    accept_notifications: true,
    picture_url: '',
  }
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials(state, action: PayloadAction<{ token: string }>) {
      state.token = action.payload.token;
    },
    logOut(state) {
      state.token = null;
      state.refreshToken = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
    setUserData(state, action: PayloadAction<UserProfile>) {
      state.userData = action.payload;
    }
  },
});

export const { setCredentials, logOut, setUserData } = AuthSlice.actions;
export default AuthSlice.reducer;
