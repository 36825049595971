import styles from "../styles/profile.module.scss";
import {useState} from "react";
// import MyCards from "./MyCards";
import Subscriptions from "./Subscriptions";
import {useTranslation} from "react-i18next";
import MySubscriptions from "./MySubscriptions";

const PrepaymentPage = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const {t} = useTranslation();

  const currentTabHandler = (index: number) => {
    setCurrentTab(index)
  }

  return (
    <div>
      <div className={styles.accountTitle}>{t('subAndPay')}</div>
      <div className={styles.tabsBlock}>
        <div
          onClick={() => currentTabHandler(0)}
          className={`
            ${styles.tab}
            ${currentTab === 0 && styles.active}
            ${currentTab === 2 && styles.additionalClass}
          `}>{t('Subscriptions')}</div>
        {/*<div*/}
        {/*  onClick={() => currentTabHandler(1)}*/}
        {/*  className={`*/}
        {/*    ${styles.tab}*/}
        {/*    ${currentTab === 1 && styles.active}*/}
        {/*    ${currentTab === 0 && styles.additionalClass}*/}
        {/*  `}>{t('myCards')}</div>*/}
        <div
          onClick={() => currentTabHandler(2)}
          className={`${styles.tab} ${currentTab === 2 && styles.active}`}>{t('mySubs')}</div>
      </div>
      {currentTab === 0 && (<Subscriptions />)}
      {/*{currentTab === 1 && (<MyCards />)}*/}
      {currentTab === 2 && (<MySubscriptions />)}
    </div>
  )
};

export default PrepaymentPage;
