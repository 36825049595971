import {createContext} from "react";

export interface InterfaceContextType {
    enabledScroll: boolean;
    height: number;
    horizontal: boolean;
    isMobile: boolean;
    setEnabledScroll: (enabled: boolean) => void;
    setHeight: (newHeight: number) => void;
    setHorizontal: (isHorizontal: boolean) => void;
    setIsMobile: (isMobile: boolean) => void;
};

export const InterfaceContext = createContext<InterfaceContextType | null>(null)