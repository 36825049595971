import React, { HTMLAttributes} from "react";
import styles from "./FormInput.module.scss";
import classnames from 'classnames';

interface IInputProps extends HTMLAttributes<HTMLInputElement> {
  placeHolder: string;
  label?: string;
  className?: string;
  labelClassName?: string;
  name: string;
  id?: string;
  value: string;
  setValue: (value: any) => void;
  type?: string;
  maxLength?: number;
  ref?: any;
  inputmode?: string;
  pattern?: string;
}

const FormInput = ({
   value,
   setValue,
   label,
   placeHolder,
   className,
   labelClassName,
   name,
   id,
   type,
   maxLength,
   ref,
   ...props
}: IInputProps) => (
  <input
    ref={ref}
    value={value}
    onChange={setValue}
    type={type || "input"}
    className={classnames(styles.form__field, className)}
    placeholder={placeHolder}
    name={name}
    id={id || 'name'}
    maxLength={maxLength ? maxLength : 100}
    required
    {...props}
  />
);

export default FormInput;
