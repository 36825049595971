import axiosInstance from "../../api/axios";

const useFavourites = () => {
  const saveFavourites = async (id: number) => {
    try {
      await axiosInstance.post(`/movies/favorites`, {movie: id});
    } catch (e) {
      console.log(e, 'e');
    }
  }
  const unSaveFavourites = async (id: number) => {
    try {
      await axiosInstance.delete(`/movies/favorites/${id}`);
    } catch (e) {
      console.log(e, 'e');
    }
  }

  return {
    saveFavourites,
    unSaveFavourites
  }
};

export default useFavourites;
