import styles from '../../../pages/foryou/_styles/InfoBlock.module.scss';

interface IProps {
  isMobile?: boolean;
}

const Comments = ({ isMobile }: IProps) => !isMobile ? (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="rgba(0, 0, 0, 0)"
    className={styles.commentIcon}
  >
    <path
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.002 21a10 10 0 1 0-8.889-5.414L1.003 21l5.414-1.111A9.959 9.959 0 0 0 11.002 21Z"
    />
  </svg>
) : (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={22}
    fill="none"
  >
    <path
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.669 21a10 10 0 1 0-8.889-5.414L1.669 21l5.414-1.111A9.958 9.958 0 0 0 11.67 21Z"
    />
  </svg>
)

export default Comments;
