import React from "react";
import styles from "./SliderModal.module.scss";

interface IModalBackdrop {
  children: React.ReactNode;
  duration: number;
  state: string;
  setOpenModal: any;
}
const ModalBackdrop = ({ children, duration, state, setOpenModal }: IModalBackdrop) => {
  const defaultStyle = {
    transition: `background-color ${duration}ms ease-in-out, z-index ${duration}ms ease-in-out`,
    backgroundColor: "rgba(0, 0, 0, 0)",
    zIndex: -1000
  };

  const transitionStyles = {
    entering: { backgroundColor: "rgba(17,17,17,0.8)", zIndex: 2211111111111111 },
    entered: { backgroundColor: "rgba(17,17,17,0.8)", zIndex: 2211111111111111 }
  };
  return (
    <div
      onClick={(event) => {
        if (event.target !== event.currentTarget) return;
        event.preventDefault()
        event.stopPropagation()
        setOpenModal(false)
      }}
      className={styles.modalBackdrop}
      style={{
        ...defaultStyle, //@ts-ignore
        ...transitionStyles[state]
      }}
    >
      {children}
    </div>
  );
};

export default ModalBackdrop;
