import { useEffect, useState } from "react"
import styles from "../../_styles/Choose.module.scss"
import {ISectionItemProps} from "../../_types/interfaces";
import useInViewport from "../../../../hooks/useInViewPort";
import ReactPlayer from "react-player";
import MobileCover from "../MobileComponents/MobileCover";
import MobileBottomInfoBlock from "../MobileComponents/MobileBottomInfoBlock";
import MobileProgressController from "../MobileComponents/MobileProgressController";
import useInterfaceContext from "../../../../components/providers/interface";
import {useDispatch} from "react-redux";
import {setMutedState} from "../../../../store/slices/filters.slice";
import {setCurrentItem, setCurrentVideoId, setLastVisitedId} from "../../../../store/slices/currentItem.slice";
import {ROUTES} from "../../../../constants/routes";
import {useNavigate} from "react-router-dom";
import FiltersHeader from "../FiltersHeader/FiltersHeader";
import {useGetCurrentVideoQuery} from "../../../../store/api/filmAPI";
import {useTranslation} from "react-i18next";
import {EN, UA} from "../../../../constants/local";

const errors = [150];

const MobilePlayer = ({
  item,
  horizontal,
  currentId,
  filterErrorItem
}: ISectionItemProps) => {
  const [url, setUrl] = useState("")
  const [player, setPlayer] = useState(null)
  const [time, setTime] = useState<number | number[]>(0)
  const [maxValue, setMaxValue] = useState<number | number[]>(0)
  const [ready, setReady] = useState(false)
  const [muted, setMuted] = useState(true);
  const [forcePlay, setForcePlaying] = useState(true)
  const [paused, setPaused] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { i18n } = useTranslation();
  const {isMobile} = useInterfaceContext();
  const { inViewport, targetRef } = useInViewport()

  // const { enData } = useFilmsEN(item.id, inViewport)
  const { data } = useGetCurrentVideoQuery({
      queries: { language: i18n.language === 'en' ? EN : UA },
      id: item.id
    },
    {
      skip: !inViewport
    }
  );

  const forcePlayHAndler = () => {
    if (paused) {
      setMuted(true)

      setForcePlaying(false)
      setTimeout(() => {
        setForcePlaying(true);
      }, 100)

      setPaused(false)
    }
  }

  const mutedIconHandler = () => {
    dispatch(setMutedState(!muted))
  }

  const clickHandler = () => {
    if (ready) {
      navigate(ROUTES.DETAILS);
      dispatch(setLastVisitedId(item.id as number));
    }
  }

  useEffect(() => {
    const trailer = data?.results.find((item: any) => item.type === 'Trailer') || data?.results[0]
    setUrl(trailer ? trailer?.key : '')
  }, [data])

  useEffect(() => {
    if (inViewport) {
      dispatch(setCurrentVideoId(item.id))
      dispatch(setCurrentItem(item))
    }
  }, [inViewport, item, dispatch])

  return (
    <>
      {!horizontal && item.id === currentId && (
        <div className={styles.horizontalWrapper}>
          <ReactPlayer
            controls={true}
            width={"100%"}
            height={"100%"}
            playsinline={true}
            playing={true}
            loop={true}
            url={`https://www.youtube.com/watch?v=${url}`}
            muted={muted}
          />
        </div>
      )}
      <div
        ref={targetRef}
        className={styles.itemWrapper}>
        {horizontal && <FiltersHeader/>}
        <div className={styles.player}>
          <MobileCover
            mutedIconHandler={mutedIconHandler}
            muted={muted}
            setMuted={setMuted}
            ready={ready}
            forcePlayHAndler={forcePlayHAndler}
          />
          {inViewport && forcePlay && horizontal ? (
            <ReactPlayer
              controls={false}
              width={"100%"}
              height={"100%"}
              playsinline={true}
              progressInterval={time as number}
              onProgress={(value: any) => setTime(Math.round(value.playedSeconds))}
              playing={true}
              loop={true}
              style={{ background: '#000000' }}
              url={`https://www.youtube.com/watch?v=${url}`}
              muted={muted}
              onPause={() => setPaused(true)}
              onError={(e: any) => {
                if (errors.includes(e)) {
                  console.log(e, 'error')
                  filterErrorItem(item.id)
                }
              }}
              onReady={(e: any) => {
                setPlayer(e["player"])
                const duration = e["player"].getDuration()
                setMaxValue(duration)
                setTimeout(() => {
                  setReady(true)
                }, 200)
              }}
            />
          ) : (
            <div style={{ height: "350px", width: "100%", background: '#000000' }} />
          )}
        </div>
        <div className={styles.bottomControlWrapper}>
          {isMobile && (
            <MobileBottomInfoBlock
              item={item}
              currentItemId={item.id}
              voteCount={item.vote_count}
              isMobile={isMobile}
              clickHandler={clickHandler}
            />
          )}
          {isMobile && (
            <div className={styles.mobileProgressController}>
              <MobileProgressController
                maxValue={maxValue as number}
                time={time as number}
                player={player as any}
                setTime={setTime}
                isMobile={isMobile}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MobilePlayer
