import styles from "../styles/profile.module.scss";
import SubscriptionCard from "./SubscriptionCard";
import {useTranslation} from "react-i18next";

const SubscriptionsData = [
  {
    id: 0,
    amount: 'oneMonth',
    total: 97,
    price: 97,
    discount: ''
  },
  {
    id: 1,
    amount: 'threeMonths',
    total: 255,
    price: 85,
    discount: '12%'
  },
  {
    id: 2,
    amount: "oneYear",
    total: 864,
    price: 72,
    discount: '25%'
  },
]
const Subscriptions = () => {
  const {t} = useTranslation();

  return (
    <div>
      <div className={styles.subscriptionSubTitle}>
        {t('subBlockTitle')}
      </div>
      <div className={styles.subscriptionDescr}>{t('choosePlan')}</div>
      {SubscriptionsData.map((item) => (
        <SubscriptionCard
          key={item.id}
          amount={t(item.amount)}
          total={item.total}
          price={item.price}
          discount={item.discount}
          id={item.id}
        />
      ))}
    </div>
  )
}

export default Subscriptions;
