import { useEffect } from "react"

import styles from "./playerControllerWrapper.module.css"
import Slider from "@mui/material/Slider";
import {timeFormat} from "../../../../utils/helpers";

interface IProps {
  time: any
  maxValue: any
  player: any
  setTime: any
  focusHandler: any
  displayVolume: any
}

const PlayerControllerWrapper = ({
  time,
  maxValue,
  player,
  setTime,
  displayVolume,
}: IProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeyUp = (event: any, t: number) => {
    if (!player) return
    if (event.keyCode === 39 && player) {
      player?.seekTo(t + 10)
    }
    if (event.keyCode === 37 && player) {
      player?.seekTo(t - 10)
    }
  }

  useEffect(() => {
    window.addEventListener("keyup", (e) => handleKeyUp(e, time))
    return () => {
      window.removeEventListener("keyup", (e) => handleKeyUp(e, time))
    }
  }, [handleKeyUp, player, time])

  return (
    <div
      style={{opacity: displayVolume ? 1 : 0}}
      className={styles.desctopPlayerControllWrapper}
    >
      <div className={styles.sliderWrapper}>
        <Slider
          className={`progress-controller-desktop ${time <= 3 && "progress-controller-desktop-opacity"}`}
          min={0}
          max={maxValue as number}
          defaultValue={0}
          value={time}
          onChange={(_, value) => {
            if (player) {
              // @ts-ignore
              player.seekTo(value, "seconds")
            }
            setTime(value as number)
          }}
        />
        <div className={styles.progresTimeWrapper}>{timeFormat(time)}</div>
      </div>
    </div>
  )
}

export default PlayerControllerWrapper
