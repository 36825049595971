const HoverPlay = ({className}: {className: string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={55}
      height={55}
      fill="none"
    >
      <path
        className={className}
        fill="#DEDEDE"
        fillOpacity={0.3}
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55Z"
      />
      <path
        fill="#FAFAFA"
        d="M34 27.5c0 .209-.051.414-.15.596a1.19 1.19 0 0 1-.411.443l-10.643 6.78a1.149 1.149 0 0 1-1.196.023 1.195 1.195 0 0 1-.439-.447A1.259 1.259 0 0 1 21 34.28V20.72c0-.217.056-.43.161-.616.106-.187.257-.341.439-.447a1.144 1.144 0 0 1 1.196.023l10.643 6.78c.171.108.313.26.412.443.098.182.15.387.149.596Z"
      />
    </svg>
  )
}

export default HoverPlay;
