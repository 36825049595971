import styles from '../header/Header.module.scss';

interface IProps {
  isMobile?: boolean
}

const HeaderClose = ({ isMobile }: IProps) =>
  !isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={12}
      fill="none"
    >
      <path
        className={styles.closeIcon}
        stroke="#888"
        strokeLinecap="round"
        strokeWidth={2}
        d="M11.5 11 1.5 1m10 0-10 10"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      fill="none"
    >
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeWidth={2}
        d="M11 11 1 1m10 0L1 11"
      />
    </svg>
  )
export default HeaderClose
