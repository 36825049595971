import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styles from './SuccessToast.module.scss';
import {useAppSelector} from "../../../store/store";
import { setSuccessToast } from '../../../store/slices/modals.slice';
import {useTranslation} from "react-i18next";
import LikeRevItem from "../../icons/details/LikeRevItem";

const SuccessToast = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { successToast } = useAppSelector((state: any) => state.modals);

  useEffect(() => {
    if (successToast) {

      const timer = setTimeout(() => {
        setTimeout(() => {
          dispatch(setSuccessToast(false));
        }, 500);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [successToast, dispatch]);

  return (
    <div className={`${styles.successToast} ${successToast ? styles.show : ''}`}>
      <LikeRevItem />
      <span>{t('successToast')}</span>
    </div>
  );
};

export default SuccessToast;
