import styles from '../../components/sideBar/SideBar.module.scss';

interface IProps {
  selected?: boolean;
  isMobile?: boolean;
}

const Choose = ({ selected, isMobile }: IProps) => !isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      fill="none"
    >
      <path
        className={!selected ? styles.forYouIcon : ''}

        stroke={selected ? "#FAFAFA" : "#888"}
        strokeWidth={2}
        d="M20.115 14.134a1 1 0 0 1 0 1.732l-6.923 3.997a1 1 0 0 1-1.5-.866v-7.994a1 1 0 0 1 1.5-.866l6.923 3.997Z"
      />
      <path
        className={!selected ? styles.forYouIcon : ''}
        stroke={selected ? "#FAFAFA" : "#888"}
        strokeLinecap="round"
        strokeWidth={2}
        d="M1 15C1 7.268 7.268 1 15 1s14 6.268 14 14-6.268 14-14 14a13.99 13.99 0 0 1-11.8-6.462"
      />
    </svg>
) : (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
  >
    <path
      stroke={selected ? "#FAFAFA" : "#888"}
      strokeWidth={2}
      d="M15.77 11.134a1 1 0 0 1 0 1.732l-4.155 2.398a1 1 0 0 1-1.5-.866V9.602a1 1 0 0 1 1.5-.866l4.154 2.398Z"
    />
    <path
      stroke={selected ? "#FAFAFA" : "#888"}
      strokeLinecap="round"
      strokeWidth={2}
      d="M1.5 12c0-6.075 4.925-11 11-11s11 4.925 11 11-4.925 11-11 11a10.991 10.991 0 0 1-9.27-5.077"
    />
  </svg>
)
export default Choose
