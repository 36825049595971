const Card = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
    >
      <path
        stroke="#A7A7A7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2.5 7.5v6.666a1.667 1.667 0 0 0 1.667 1.667h11.666a1.666 1.666 0 0 0 1.667-1.667V7.5m-15 0V5.833a1.667 1.667 0 0 1 1.667-1.667h11.666A1.666 1.666 0 0 1 17.5 5.833V7.5m-15 0h15M5.833 10.833H10"
      />
    </svg>
  )
}

export default Card;
