import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import styles from './MobilePaymentDetails.module.scss';
import PrepaymentPage from "../profile/components/Prepayment";
import ModalWrapper from "../../components/shared/ModalWrapper/ModalWrapper";
import CardAddedModal from "../profile/components/CardAddedModal";
import CardRemoveModal from "../profile/components/CardRemoveModal";
import CardRemoveConfirmModal from "../profile/components/CardRemoveConfirmModal";
import RemoveSubscriptionModal from "../profile/components/RemoveSubscriptionModal";
import SuccessRemoveSubscriptionModal from "../profile/components/SuccessRemoveSubscriptionModal";
import {useAppSelector} from "../../store/store";

const MobilePaymentDetails = () => {
  const { modal } = useAppSelector((state: any) => state.modals)

  return (
    <>
      <Header />
      <div className={styles.contentWrapper}>
        <PrepaymentPage />
      </div>
      <Footer source={''}/>
      {modal && (
        <ModalWrapper>
          {modal === 'cardAdded' && (
            <CardAddedModal />
          )}
          {modal === 'cardRemove' && (
            <CardRemoveModal />
          )}
          {modal === 'cardRemoveConfirm' && (
            <CardRemoveConfirmModal />
          )}
          {modal === 'removeSubscription' && (
            <RemoveSubscriptionModal />
          )}
          {modal === 'removeSubscriptionSuccess' && (
            <SuccessRemoveSubscriptionModal />
          )}
        </ModalWrapper>
      )}
    </>
  )
}

export default MobilePaymentDetails;
