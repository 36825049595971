import React from "react";
import styles from "../sideBar/SideBar.module.scss";

interface IProps  {
  children: React.ReactNode
}

const MenuWrapper = ({ children }: IProps) => {
  return (
    <nav>
      <ul className={styles.menu}>
        {children}
      </ul>
    </nav>
  )
};

export default MenuWrapper;
