import {useLayoutEffect, useState} from 'react';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState('');

  useLayoutEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth >= 1024) {
        setWindowSize('desktop');
      } else if (screenWidth >= 768 && screenWidth <= 1023) {
        setWindowSize('mobile');
      } else {
        setWindowSize('mobile');
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {windowSize};
};

export default useWindowSize;
