const Rubin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={17}
      fill="none"
    >
      <path
        stroke="#EF3500"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 1.375h12l3 4.844-8.5 9.203a.704.704 0 0 1-.5.203.72.72 0 0 1-.5-.204L1 6.22l3-4.844Z"
      />
      <path
        stroke="#EF3500"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8 8.156 6 6.025l.6-.969"
      />
    </svg>
  )
}

export default Rubin;
