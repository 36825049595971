const Next = ({currentStep}: {currentStep: any}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={34}
      height={35}
      fill="none"
    >
      <path
        fill={currentStep === 3 ? "#444" : "#DDDDDD"}
        fillRule="evenodd"
        d="M11 17.5c0 .19.072.371.201.505.13.134.304.21.486.21h7.965L16.7 21.278a.718.718 0 0 0-.201.506.74.74 0 0 0 .201.506.688.688 0 0 0 .487.209.667.667 0 0 0 .486-.21l4.125-4.284A.714.714 0 0 0 22 17.5a.738.738 0 0 0-.202-.506l-4.124-4.285a.675.675 0 0 0-.487-.209.676.676 0 0 0-.487.21.73.73 0 0 0-.201.505c0 .19.072.372.201.506l2.952 3.065h-7.965a.675.675 0 0 0-.486.209.729.729 0 0 0-.201.505Z"
        clipRule="evenodd"
      />
      <rect
        width={32}
        height={32}
        x={1}
        y={1.5}
        stroke={currentStep === 3 ? "#444" : "#DDDDDD"}
        strokeWidth={2}
        rx={16}
      />
    </svg>
  )
}

export default Next;
