import styles from "./Header.module.scss";
import {useRef} from "react";
import useOutsideClick from "../../utils/hooks/useOutsideClick";

interface ILanguageDropDownProps {
  languageClickHandler: () => void;
  changeLanguage: (language: string) => void;
  openedFlag: boolean,
  leaveModalHandler: () => void;
}
const LanguageDropDown = ({
  languageClickHandler,
  changeLanguage,
  openedFlag,
  leaveModalHandler
}: ILanguageDropDownProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(modalRef, leaveModalHandler);
  return (
    <div
      ref={modalRef}
      className={`${styles.localisationDropDown} ${openedFlag ? styles.opened : ''}`}
    >
      {openedFlag && (
        <>
          <div onClick={() => {
            changeLanguage('en');
            languageClickHandler();
          }}>
            English
          </div>
          <div onClick={() => {
            changeLanguage('uk');
            languageClickHandler();
          }}>
            Українська
          </div>
        </>
      )}
    </div>
  )
}

export default LanguageDropDown;
