import styles from './Login.module.scss';
import {useState} from "react";
import Toggle from "../../components/shared/Toggle/Toggle";
import PhoneEmailInput from "../../components/shared/PhoneEmailInput/PhoneEmailInput";
import Button from "../../components/shared/Button/Button";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import {useLogin} from "../../hooks/useLogin";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Login = () => {
  const [error, setError] = useState('');
  const [checked, setChecked] = useState(JSON.parse(localStorage.getItem('rememberMe') as string) || false)
  const [inputValue, setInputValue] = useState(localStorage.getItem('email') || '');

  const { loginStepOne } = useLogin();
  const {t} = useTranslation();

  const checkedHandler = (value: any) => {
    setChecked(value.target.checked)

    if (value.target.checked) {
      localStorage.setItem('rememberMe', 'true')
      localStorage.setItem('email', inputValue)
    }

    if (!value.target.checked) {
      localStorage.removeItem('rememberMe')
      localStorage.removeItem('email')
    }
  }

  const inputValueHandler = (e: any) => {
    const value = e.target.value;

    setInputValue(value);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+380\d{9}$/;

    if (emailRegex.test(value) || phoneRegex.test(value) || !value) {
      setError('');
    } else {
      setError('Please enter a valid email');
    }
  };


  return (
    <div className={styles.loginPageWrapper}>
      <Header className={styles.headerWrapper}/>
      <div className={styles.loginPageContentWrapper}>
        <div className={styles.loginTitle}>{t('login')}</div>
        <div className={styles.loginDesc}>{t('loginTitle')}</div>
        <PhoneEmailInput value={inputValue} setValue={inputValueHandler} error={error}/>
        <div className={styles.rememberWrapper}>
          <Toggle checked={checked} setChecked={checkedHandler}/>
          <p>{t('rememberMe')}</p>
        </div>
        <Link to={'/confirmation'} className={styles.loginButtonWrapper}>
          <Button
            className={styles.loginButton}
            title={t('getCode')}
            submit={() => loginStepOne({emailOrPhone: inputValue})}
          />
        </Link>
        <div className={styles.privacy}>
          <div>
            {t('loginTerms1')}
            {t('loginTerms2')}
            {t('loginTerms3')}
          </div>
          <div>
            {t('loginTerms4')} <a
            href="https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/HEAD/docs/rules/anchor-is-valid.md">
            {t('loginTerms5')}
          </a>
          </div>
        </div>
      </div>
      <div style={{height: '50px'}}/>
      <Footer source={''}/>
    </div>
  )
};

export default Login;
