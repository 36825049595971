import { ComponentProps } from "react";

interface IProps extends ComponentProps<"path"> {}

const ActivityIcon = ({ ...props }: IProps) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1V25.8889C1 26.714 1.32778 27.5053 1.91122 28.0888C2.49467 28.6722 3.28599 29 4.11111 29H29"
      stroke="#888888"
      strokeWidth="2"
      strokeMiterlimit="5.759"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    />
    <path
      d="M7.22217 18.1112L13.4444 11.8889L19.6666 18.1112L28.9999 8.77783"
      stroke="#888888"
      strokeWidth="2"
      strokeMiterlimit="5.759"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    />
    <path
      d="M24.3335 8.77783H29.0002V13.4445"
      stroke="#888888"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    />
  </svg>
);

export default ActivityIcon;
