import { ComponentProps } from "react";

interface IProps extends ComponentProps<"path"> {}

const PrePaymentIcon = ({ ...props }: IProps) => (
  <svg width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.66667 1H24.3333L29 8.81776L15.7778 23.6715C15.6764 23.7755 15.5554 23.8581 15.4218 23.9145C15.2883 23.9709 15.1449 24 15 24C14.8551 24 14.7117 23.9709 14.5782 23.9145C14.4446 23.8581 14.3236 23.7755 14.2222 23.6715L1 8.81776L5.66667 1Z"
      stroke="#888888"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    />
    <path
      d="M11.8885 11.9448L8.77734 8.50496L9.71068 6.94141"
      stroke="#888888"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    />
  </svg>
);

export default PrePaymentIcon;
