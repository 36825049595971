import styles from "../../_styles/InfoBlock.module.scss"
import {infoBlockItems} from "../../../../constants/structure";
import InfoBlockIcons from "./InfoBlockIcons";
import React from "react";

interface IProps {
  voteCount: number
  isMobile?: boolean
  likeHandler: (e: any, item: any, flag: boolean) => void,
  favouritesHandler: (e: any, item: any, flag: boolean) => void,
  reviewHandler: () => void,
  liked: boolean,
  inFavourites: boolean,
  item: any,
  shareHandler: () => void
}

const ChooseInfoBlock = ({
   voteCount,
   isMobile,
   likeHandler,
   reviewHandler,
   favouritesHandler,
   liked,
   inFavourites,
   item,
   shareHandler
}: IProps) => {
  const valueHandler = (path: string, def: any) => {
    if (path === "likes") {
      return voteCount
    }
    if (path === "share") {
      return Math.round(voteCount / 3)
    }

    return def
  }

  return (
    <div className={`${isMobile ? styles.itemInfoBarMobile : styles.itemInfoBar}`}>
      {infoBlockItems.map((infoBlockItem, index) => {
        return (
          <div
            id={infoBlockItem.path}
            key={infoBlockItem.path}
            onClick={(e) => {
              if (infoBlockItem.handler === 'like') {
                return likeHandler(e, item, liked)
              }

              if (infoBlockItem.handler === 'saved') {
                return favouritesHandler(e, item, inFavourites)
              }

              if (infoBlockItem.handler === 'share') {
                return shareHandler()
              }
            }}
            className={`${styles.infoBarItemWrapper} ${isMobile ? styles.infoBarItemWrapperMobile : ""}`}>
            <div className={styles.infoBarContentWrapper}>
              <InfoBlockIcons
                isMobile={isMobile}
                iconName={infoBlockItem.path}
                liked={liked}
                inFavourites={inFavourites}
              />
              <div>{valueHandler(infoBlockItem.path, infoBlockItem.number)}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ChooseInfoBlock
