import styles from '../../../pages/foryou/_styles/InfoBlock.module.scss';
import { ComponentProps } from "react";

interface IProps extends ComponentProps<"svg"> {
  isMobile?: boolean;
  inFavourites?: boolean;
}

const Saved = ({ isMobile, inFavourites, ...props }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={22}
      fill={inFavourites ? '#FAFAFA' : 'rgba(0, 0, 0, 0)'}
      className={!isMobile ? styles.savedIcon : ''}
      {...props}
    >
      <path
        stroke="#FAFAFA"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 21V1h14v20l-7-3.69L1 21Z"
      />
    </svg>
  )
}
export default Saved;
