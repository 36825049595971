import styles from '../../components/sideBar/SideBar.module.scss';

interface IProps {
  selected?: boolean;
  isMobile?: boolean;
  showPercent?: boolean;
}

const Invite = ({ selected, isMobile, showPercent }: IProps) => !isMobile ? (
  <div className={styles.inviteIconWrapper}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={28}
      fill="none"
    >
      <path
        className={!selected ? styles.inviteIcon : ''}
        fill={selected ? "#FAFAFA" : "#888"}
        d="M23.92 7h-3.68c.48-.72.76-1.58.76-2.5C21 2.02 18.98 0 16.5 0c-1.42 0-2.67.67-3.5 1.71C12.17.68 10.92 0 9.5 0 7.02 0 5 2.02 5 4.5c0 .92.28 1.78.76 2.5H2.08C.93 7 0 7.9 0 9v5c0 1.08.89 1.95 2 1.99V24c0 2.21 1.88 4 4.19 4h13.62c2.31 0 4.19-1.79 4.19-4v-8.01c1.11-.04 2-.92 2-1.99V9c0-1.1-.93-2-2.08-2ZM16.5 2a2.5 2.5 0 0 1 0 5H14V4.5A2.5 2.5 0 0 1 16.5 2ZM7 4.5a2.5 2.5 0 0 1 5 0V7H9.5A2.5 2.5 0 0 1 7 4.5ZM1.99 9.03S2.01 9 2.08 9H12v5H2V9.03h-.01ZM6.19 26C4.98 26 4 25.1 4 24v-8h8v10H6.19ZM22 24c0 1.1-.98 2-2.19 2H14V16h8v8Zm1.92-10H14V9h10l-.08 5Z"
      />
    </svg>
    {showPercent && (
      <svg
        className={styles.inviteIconPercent}
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        fill="none"
      >
        <circle cx={7.02} cy={6.935} r={4.702} fill="#FAFAFA"/>
        <path
          fill="#EF3500"
          d="M5.127 5.53a.49.49 0 1 1 0-.979.49.49 0 0 1 0 .98Zm3.918 2.94a.49.49 0 1 0 0 .979.49.49 0 0 0 0-.98ZM13.943 7c0 .64-.46 1.119-.866 1.542-.23.24-.47.49-.56.708-.083.2-.088.532-.092.854-.01.597-.02 1.274-.49 1.745-.471.47-1.148.48-1.746.49-.321.005-.653.01-.853.093-.219.09-.467.329-.709.56-.423.405-.902.865-1.541.865-.64 0-1.119-.46-1.542-.866-.241-.23-.49-.47-.708-.56-.2-.082-.532-.087-.854-.092-.597-.01-1.274-.02-1.745-.49-.471-.47-.48-1.148-.49-1.745-.005-.322-.01-.654-.093-.854-.09-.219-.329-.467-.56-.708C.688 8.119.23 7.639.23 7c0-.64.46-1.119.865-1.542.231-.24.47-.49.56-.708.083-.2.088-.532.093-.853.01-.598.019-1.275.49-1.746.47-.47 1.148-.48 1.745-.49.322-.005.654-.01.854-.093.218-.09.467-.329.708-.56.423-.405.903-.865 1.542-.865s1.118.46 1.541.866c.242.23.49.47.709.56.2.082.532.087.853.092.598.01 1.275.02 1.746.49.47.47.48 1.148.49 1.746.004.321.01.653.093.853.09.219.328.467.56.708.405.423.865.903.865 1.542Zm-8.816-.49a1.47 1.47 0 1 0 0-2.939 1.47 1.47 0 0 0 0 2.94ZM9.88 4.898a.49.49 0 0 0-.693-.694L4.29 9.102a.49.49 0 1 0 .693.694l4.898-4.898Zm.633 4.061a1.47 1.47 0 1 0-2.94 0 1.47 1.47 0 0 0 2.94 0Z"
        />
      </svg>
    )}
  </div>
) : (
  <div className={styles.inviteIconWrapper}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23}
      height={24}
      fill="none"
    >
      <path
        fill={selected ? "#FAFAFA" : "#888"}
        d="M20.99 6h-3.114a3.888 3.888 0 0 0 .643-2.143C18.52 1.731 16.81 0 14.712 0c-1.202 0-2.26.574-2.962 1.466C11.048.583 9.99 0 8.788 0 6.69 0 4.981 1.731 4.981 3.857c0 .789.237 1.526.643 2.143H2.51C1.537 6 .75 6.771.75 7.714V12c0 .926.753 1.671 1.692 1.706v6.865C2.442 22.466 4.033 24 5.988 24h11.524c1.955 0 3.546-1.534 3.546-3.429v-6.865c.939-.035 1.692-.789 1.692-1.706V7.714c0-.943-.787-1.714-1.76-1.714Zm-6.278-4.286c1.167 0 2.115.96 2.115 2.143C16.827 5.04 15.879 6 14.71 6h-2.115V3.857c0-1.183.948-2.143 2.115-2.143ZM6.672 3.857c0-1.183.948-2.143 2.115-2.143 1.168 0 2.116.96 2.116 2.143V6H8.788c-1.167 0-2.115-.96-2.115-2.143ZM2.433 7.74s.018-.026.077-.026h8.394V12H2.442V7.74h-.008Zm3.555 14.546c-1.024 0-1.853-.772-1.853-1.715v-6.857h6.769v8.572H5.988Zm13.377-1.715c0 .943-.829 1.715-1.853 1.715h-4.916v-8.572h6.77v6.857ZM20.99 12h-8.394V7.714h8.462L20.99 12Z"
      />
    </svg>
    {showPercent && (
      <svg
        className={styles.inviteIconPercent}
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        fill="none"
      >
        <circle cx={7.02} cy={6.935} r={4.702} fill="#FAFAFA"/>
        <path
          fill="#EF3500"
          d="M5.127 5.53a.49.49 0 1 1 0-.979.49.49 0 0 1 0 .98Zm3.918 2.94a.49.49 0 1 0 0 .979.49.49 0 0 0 0-.98ZM13.943 7c0 .64-.46 1.119-.866 1.542-.23.24-.47.49-.56.708-.083.2-.088.532-.092.854-.01.597-.02 1.274-.49 1.745-.471.47-1.148.48-1.746.49-.321.005-.653.01-.853.093-.219.09-.467.329-.709.56-.423.405-.902.865-1.541.865-.64 0-1.119-.46-1.542-.866-.241-.23-.49-.47-.708-.56-.2-.082-.532-.087-.854-.092-.597-.01-1.274-.02-1.745-.49-.471-.47-.48-1.148-.49-1.745-.005-.322-.01-.654-.093-.854-.09-.219-.329-.467-.56-.708C.688 8.119.23 7.639.23 7c0-.64.46-1.119.865-1.542.231-.24.47-.49.56-.708.083-.2.088-.532.093-.853.01-.598.019-1.275.49-1.746.47-.47 1.148-.48 1.745-.49.322-.005.654-.01.854-.093.218-.09.467-.329.708-.56.423-.405.903-.865 1.542-.865s1.118.46 1.541.866c.242.23.49.47.709.56.2.082.532.087.853.092.598.01 1.275.02 1.746.49.47.47.48 1.148.49 1.746.004.321.01.653.093.853.09.219.328.467.56.708.405.423.865.903.865 1.542Zm-8.816-.49a1.47 1.47 0 1 0 0-2.939 1.47 1.47 0 0 0 0 2.94ZM9.88 4.898a.49.49 0 0 0-.693-.694L4.29 9.102a.49.49 0 1 0 .693.694l4.898-4.898Zm.633 4.061a1.47 1.47 0 1 0-2.94 0 1.47 1.47 0 0 0 2.94 0Z"
        />
      </svg>
    )}
  </div>
)
export default Invite
