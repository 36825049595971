const LikedRevItem = ({extraClass}: {extraClass: string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={18}
      fill="none"
      className={extraClass}
    >
      <path
        fill="#111"
        d="M12.364 6.507h5.818C19.186 6.507 20 7.3 20 8.275v1.86c0 .232-.047.46-.137.674l-2.813 6.644a.91.91 0 0 1-.84.547H.91a.897.897 0 0 1-.91-.884v-8.84c0-.489.407-.885.91-.885h3.164c.296 0 .573-.14.743-.374L9.775.187a.463.463 0 0 1 .574-.14L12 .849c.955.464 1.45 1.514 1.186 2.522l-.822 3.136Z"
      />
    </svg>
  )
}

export default LikedRevItem;
