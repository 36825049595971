interface IProps {
  className?: string;
}

const ArrowUp = ({ className }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={8}
      fill="none"
    >
      <path
        className={className}
        stroke="#888"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m11 6.502-5-5-5 5"
      />
    </svg>
  )
};

export default ArrowUp;
