import React, {useEffect} from "react";
import styles from "./AgreementPage.module.scss";
import useWindowSize from "../../hooks/useWindowSize";
import Header from "../../components/header/Header";
import SideBar from "../../components/sideBar/SideBar";
import Footer from "../../components/footer/Footer";
import {useTranslation} from "react-i18next";

const AgreementPage = () => {
  const { windowSize } = useWindowSize();
  const {t} = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className={styles.wrapper}>
      <Header className={styles.headerWrapper} />
      {windowSize !== "mobile" && <SideBar />}
      <div className={styles.container}>
        <div className={styles.sectionWrapper}>
          <div className={styles.subtitle}>{t('agr1title')}</div>
          <div className={styles.text}>{t('agr2')}</div>
          <div className={styles.text}>{t('agr4')}</div>
          <div className={styles.text}>{t('agr5')}</div>
          <div className={styles.text}>{t('agr6')}</div>
          <div className={styles.text}>{t('agr9')}</div>
          <div className={styles.text}>{t('agr10')}</div>
          <div className={styles.text}>{t('agr11')}</div>
          <div className={styles.text}>{t('agr12')}</div>
          <div className={styles.text}>{t('agr13')}</div>
          <div className={styles.text}>{t('agr15')}</div>
          <div className={styles.text}>{t('agr16')}</div>
          <div className={styles.text}>{t('agr17')}</div>
          <div className={styles.text}>{t('agr18')}</div>
          <div className={styles.text}>{t('agr19')}</div>
          <div className={styles.text}>{t('agr20')}</div>
          <div className={styles.text}>{t('agr22')}</div>
          <div className={styles.text}>{t('agr24')}</div>
          <div className={styles.text}>{t('agr25')}</div>
          <div className={styles.text}>{t('agr26')}</div>
          <div className={styles.text}>{t('agr27')}</div>
          <div className={styles.text}>{t('agr28')}</div>
          <div className={styles.text}>{t('agr29')}</div>
          <div className={styles.text}>{t('agr30')}</div>
          <div className={styles.text}>{t('agr31')}</div>
        </div>
        <div className={styles.sectionWrapper}>
          <div className={styles.subtitle}>{t('agr23title')}</div>
          <div className={styles.text}>{t('agr32')}</div>
          <div className={styles.text}>{t('agr33')}</div>
          <div className={styles.text}>{t('agr34')}</div>
          <div className={styles.text}>{t('agr35')}</div>
          <div className={styles.text}>{t('agr36')}</div>
          <div className={styles.text}>{t('agr37')}</div>
        </div>
      </div>
      <Footer source={''}/>
    </section>
  );
};

export default AgreementPage;
