const IconProfileSecurity = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={60}
      height={24}
      fill="none"
    >
      <path
        fill="#225E63"
        d="m40.963 13.557.6.18c-.04.17-.1.31-.19.42-.09.11-.19.2-.32.25-.13.06-.29.09-.49.09-.24 0-.43-.03-.59-.1a.914.914 0 0 1-.39-.37c-.11-.17-.17-.4-.17-.67 0-.36.1-.64.29-.84.19-.2.47-.29.82-.29.28 0 .49.06.65.17.16.11.28.28.35.52l-.6.13a.35.35 0 0 0-.07-.15.407.407 0 0 0-.14-.12.392.392 0 0 0-.18-.04c-.15 0-.27.06-.35.19-.06.09-.09.24-.09.43 0 .24.04.41.11.5.07.09.18.14.31.14s.23-.04.29-.11c.08-.09.13-.19.16-.33ZM41.803 13.36c0-.36.1-.64.3-.84.2-.2.48-.3.84-.3.37 0 .65.1.85.29.2.2.3.47.3.83 0 .26-.04.47-.13.63-.09.16-.21.29-.38.38-.16.09-.37.14-.61.14-.25 0-.45-.04-.62-.12a.967.967 0 0 1-.4-.38c-.11-.18-.15-.37-.15-.63Zm.68 0c0 .22.04.38.12.48.08.1.19.15.34.15.15 0 .26-.05.34-.14.08-.1.12-.27.12-.51 0-.21-.04-.36-.13-.45-.08-.1-.2-.14-.34-.14a.4.4 0 0 0-.33.15c-.08.08-.12.24-.12.46ZM44.443 12.26h.89l.34 1.34.34-1.34h.89v2.2h-.56v-1.68l-.43 1.68h-.5l-.43-1.68v1.68h-.56v-2.2h.02ZM47.342 12.26h1.13c.25 0 .43.06.55.18.12.12.18.28.18.5 0 .22-.07.4-.2.52-.13.13-.34.19-.61.19h-.37v.82h-.68v-2.21Zm.68.94h.17c.13 0 .22-.02.28-.07.05-.05.08-.1.08-.17a.24.24 0 0 0-.07-.18c-.05-.05-.13-.07-.26-.07h-.19v.49h-.01ZM49.562 12.26h.68v1.66h1.06v.54h-1.74v-2.2ZM51.642 12.26h.68v2.2h-.68v-2.2ZM54.161 14.09h-.77l-.11.36h-.69l.83-2.2h.74l.83 2.2h-.71l-.12-.36Zm-.14-.47-.24-.79-.24.79h.48ZM55.201 12.26h.63l.83 1.22v-1.22h.64v2.2h-.64l-.82-1.21v1.21h-.64v-2.2ZM57.6 12.26h2.07v.54h-.69v1.66h-.68V12.8h-.69v-.54h-.01ZM32.785 15.61l1.97-.62-.64-1.42c-.47.59-.94 1.35-1.33 2.04Zm-.67-6.49L28.255.523 0 2.122l7.829 21.356 15.067-4.75c-.96-1.379-1.31-3.019-.2-3.818 1.24-.9 3.11.14 4.3 1.61 1.15-1.92 4.379-6.39 5.119-7.4Z"
      />
      <path
        fill="#FEFEFE"
        d="M27.375 6.736c1.18 0 2.14-.9 2.14-2.02s-.96-2.02-2.14-2.02c-1.18 0-2.14.9-2.14 2.02 0 1.11.96 2.02 2.14 2.02Zm-1.75.87h3.5v9.149h-3.5V7.606ZM23.896 10.275c.03.01.05 0 .05-.03v-2.32c0-.03-.02-.06-.05-.08 0 0-.46-.299-1.86-.379-.07-.04-1.3-.05-1.63 0-5.099.41-5.289 4.1-5.289 4.26v.9c0 .11 0 3.879 5.29 4.208.52.04 1.529 0 1.629 0 1.22 0 2.06-.37 2.06-.37.03-.01.05-.04.05-.07v-2.17c0-.03-.02-.04-.04-.02 0 0-.38.3-2.04.47-.47.05-.7.03-.87 0-2.36-.4-2.47-2.119-2.47-2.119 0-.03-.01-.08-.01-.1v-.66c0-.03 0-.08.01-.1 0 0 .16-1.85 2.47-2.05h.87c1.01.13 1.83.63 1.83.63ZM5.399 16.725c0 .03.02.05.05.05h3.41c.03 0 .05-.02.05-.05v-2.66c0-.03.02-.05.05-.05 0 0 5.448.39 5.448-3.26 0-2.889-3.419-3.199-4.539-3.149h-4.42c-.03 0-.05.02-.05.05v9.069Zm3.45-4.74v-2.39h.84s1.209.05 1.309.88c.02.06.02.47 0 .49-.16.97-1.21 1.02-1.21 1.02h-.94Z"
      />
      <path
        fill="#2BBC5D"
        d="M27.475 21.169c.28 0 .5 0 .86-.16 1.24-.65 5.43-10.879 9.849-14.028.03-.02.06-.05.08-.08.03-.04.03-.08.03-.08s0-.21-.65-.21c-3.93-.11-8.019 8.139-10.169 11.398-.03.04-.17 0-.17 0s-1.44-1.7-2.69-2.35c-.03-.01-.17-.06-.319-.05-.1 0-.68.12-.95.4-.32.34-.31.53-.31.94 0 .03.02.17.06.24.31.54 1.7 2.46 2.85 3.52.17.13.44.46 1.53.46Z"
      />
      <path
        fill="#225E63"
        d="M39.343 3.891h3.27c.65 0 1.17.09 1.56.26.4.18.73.43.98.75.26.33.45.71.56 1.14.12.43.18.89.18 1.38 0 .76-.09 1.35-.26 1.77-.17.42-.41.77-.72 1.059-.31.28-.64.47-.99.57-.48.13-.92.19-1.31.19h-3.27V3.89Zm2.2 1.61v3.89h.54c.46 0 .79-.05.98-.15.19-.1.35-.28.46-.53.11-.25.17-.67.17-1.24 0-.76-.12-1.27-.37-1.55s-.65-.42-1.23-.42h-.55ZM46.502 8.66l2.1-.13c.05.34.14.6.28.78.23.29.55.43.97.43.31 0 .56-.07.73-.22.17-.15.26-.32.26-.51 0-.18-.08-.35-.24-.5-.16-.15-.54-.28-1.13-.41-.97-.22-1.65-.51-2.07-.87-.42-.36-.62-.82-.62-1.38 0-.37.11-.709.32-1.039.21-.33.53-.58.96-.77.43-.19 1.01-.28 1.75-.28.91 0 1.61.17 2.08.51.479.34.759.88.849 1.62l-2.08.12c-.06-.32-.17-.55-.35-.7-.18-.15-.42-.22-.73-.22-.26 0-.45.05-.58.16-.13.11-.19.24-.19.4 0 .11.05.22.16.31.1.09.35.18.74.26.96.21 1.65.42 2.07.63.42.21.72.48.91.79a2 2 0 0 1 .28 1.06c0 .46-.13.88-.38 1.26-.25.39-.61.68-1.06.879-.45.2-1.03.3-1.72.3-1.21 0-2.05-.23-2.52-.7-.46-.47-.72-1.06-.789-1.78ZM53.531 8.66l2.1-.13c.05.34.14.6.28.78.23.29.55.43.97.43.31 0 .56-.07.73-.22.17-.15.26-.32.26-.51 0-.18-.08-.35-.24-.5-.16-.15-.54-.28-1.13-.41-.97-.22-1.65-.51-2.07-.87-.41-.36-.62-.82-.62-1.38 0-.37.11-.709.32-1.039.21-.33.53-.58.96-.77.43-.19 1.01-.28 1.75-.28.91 0 1.61.17 2.08.51.48.34.76.88.85 1.62l-2.08.12c-.06-.32-.17-.55-.35-.7-.18-.15-.42-.22-.73-.22-.26 0-.45.05-.58.16-.13.11-.19.24-.19.4 0 .11.05.22.16.31.1.09.35.18.74.26.96.21 1.65.42 2.07.63.42.21.72.48.91.79A2 2 0 0 1 60 8.7c0 .46-.13.88-.38 1.26-.25.39-.61.68-1.06.879-.45.2-1.03.3-1.72.3-1.21 0-2.05-.23-2.52-.7-.47-.47-.73-1.06-.79-1.78Z"
      />
    </svg>
  )
}

export default IconProfileSecurity;
