import React from 'react';
import LoaderIcon from "../icons/mainLoader/loader.svg";
import useWindowSize from "../../hooks/useWindowSize";

const Loader = () => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";
  const loaderSize = isMobile
    ? {width: '170px', height: '53px'} : {width: '340px', height: '105px'}

  return (
    <div style={{width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <object type="image/svg+xml" data={LoaderIcon} style={loaderSize}>svg-animation</object>
    </div>
  );
};

export default Loader;

