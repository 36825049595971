import React, {useEffect} from "react";
import styles from "./AboutPage.module.scss";
import SideBar from "../../components/sideBar/SideBar";
import useWindowSize from "../../hooks/useWindowSize";
import Heading from "../../components/shared/Heading";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

const CooperationPage = () => {
  const { windowSize } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Header className={styles.headerWrapper} />
      {windowSize !== "mobile" && <SideBar />}
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <Heading title="Про нас" />
          <p className={styles.sectionText}>
            HIVIEWER — це всесвіт контенту, у якому тобі завжди добре. Тут все як ти забажаєш. Хочеш — і на всі дюйми
            телевізора дивишся не просто улюблений серіал, а цілий канал улюбленого серіалу. Хочеш — пірнаєш у
            меговсесвіт із ноутбука, а виринаєш у телешоу незалежно від його розкладу. Хочеш — дивишся футбольний матч
            за 10 хвилин або на телефоні формуєш власну колекцію аудіокнижок. Зовсім поруч світи мультиків та цілі
            всесвіти фільмів, які не перерахувати. А ще тут так багато можливостей і дивовижних історій, що із
            задоволенням промовляєш: «Дооообре».
          </p>
        </div>
        <div className={styles.sectionWrapper}>
          <h3 className={styles.sectionTitle}>Всесвіт контенту</h3>
          <p className={styles.sectionText}>
            Ми даємо не просто доступ до всього контенту, що можна дивитися та слухати. Ми відчиняємо двері у всесвіт
            історій. 390 каналів із шоу, серіалами та програмами — це ж цілі галактики пригод і вражень. А ще 15 000
            фільмів, захопливі світи спортивних подій та драматичних дербі Ліги чемпіонів або Ліги Європи. Понад 900
            історій-аудіокниг, із яких за мить можна пірнути в подкасти, а з них — у мультяшні всесвіти й назад до ТБ.
            Щодня ми знаходимо та додаємо на HIVIEWER нові світи, історії, фічі — і як справжній всесвіт стаємо більшими
            й більшими, щоб можна було увімкнути та просто дивитися.
          </p>
        </div>
        <div className={styles.sectionWrapper}>
          <h3 className={styles.sectionTitle}>Завжди з тобою</h3>
          <p className={styles.sectionText}>
            Потрапити у всесвіт HIVIEWER можна відусюди. Зі своєї автівки чи метро, на дачі чи в поході, з улюбленого
            дивана чи навіть із літака. Розваги завжди поруч — у твоєму телефоні, телевізорі, ноутбуці чи планшеті. Ми
            працюємо на більшості популярних платформ: iOS, Android, Smart та Android TV, плеєрах і приставках з Apple
            TV, Amazon Fire TV, XBOX One, Sony PlayStation.
          </p>
        </div>
        <div className={styles.sectionWrapper}>
          <h3 className={styles.sectionTitle}>Все як ти хочеш</h3>
          <p className={styles.sectionText}>
            Хочемо, щоб кожен мандрував нашим всесвітом зручно. Тож у нас можна подивитися футбольний матч у повторі або
            увімкнути тільки голи. Перемотати телешоу на цікавий момент чи подивитися його пізніше в записі. Потрапити у
            фільм мовою оригіналу або дивитися українською. А якщо попереду важкі часи без інтернету, то заздалегідь
            завантажити собі контенту в мобільному застосунку. А ще нагадування, субтитри, «Батьківський контроль» та
            багато іншого. У всесвіті HIVIEWER все створене для того, щоб вам було добре.
          </p>
        </div>
      </div>
      <Footer source={''} />
    </div>
  );
};

export default CooperationPage;
