const MutedIconBg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={55}
      height={55}
      fill="none"
    >
      <rect
        width={55}
        height={55}
        fill="#222"
        fillOpacity={0.8}
        rx={27.5}
        transform="matrix(0 -1 -1 0 55 55)"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m39.167 24-7 7m0-7 7 7M26.333 19.333 20.5 24h-4.667v7H20.5l5.833 4.667V19.333Z"
      />
    </svg>
  )
};

export default MutedIconBg
