import { useEffect, useRef, useState } from 'react';
import {useLocation} from "react-router-dom";

const useInViewport = (data) => {
	const [inViewport, setInViewport] = useState(false);
  const { pathname } = useLocation();
	const targetRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setInViewport(entry.isIntersecting);
			},
			{ threshold: 0.5 }
		);

		if (targetRef.current) {
			const timer = setTimeout(() => {
        observer.observe(targetRef.current);
        clearTimeout(timer)
      }, 100)
		}

		// Отключаем наблюдение при размонтировании компонента
		return () => {
			if (targetRef.current) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				observer.unobserve(targetRef.current);
			}
		};
	}, [data, pathname]);

	return { inViewport, targetRef };
};

export default useInViewport;
