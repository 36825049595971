import React, { useState } from "react"
import { useDispatch } from "react-redux"

import styles from "./MobileComponents.module.css"
import ChooseInfoBlock from "../rightData/ChooseInfoBlock";
import {setItemId} from "../../../../store/slices/currentItem.slice";
import ButtonIcon from "../../../../components/icons/ButtonIcon";
import {useTranslation} from "react-i18next";
import {useGetVideoDetailsQuery} from "../../../../store/api/filmAPI";
import {EN, UA} from "../../../../constants/local";
import useLikeClick from "../../../../hooks/useLikeClick";
import useFavouriteClick from "../../../../hooks/useFavouriteClick";
import DotsLoader from "../../../../components/icons/DotsLoader";
import {setShareModal} from "../../../../store/slices/modals.slice";

interface IProps {
  voteCount: number
  isMobile: boolean
  currentItemId: number,
  clickHandler: () => void
  item: any
}
const MobileBottomInfoBlock = ({
   voteCount,
   isMobile,
   currentItemId,
   clickHandler,
   item
}: IProps) => {
  const dispatch = useDispatch()
  const [hoveredButton, setHoveredButton] = useState(false)

  const { i18n } = useTranslation();
  const { likesList, likeHandler } = useLikeClick();
  const { favoritesList, favouritesHandler } = useFavouriteClick();

  const { data, isLoading } = useGetVideoDetailsQuery({
    queries: { language: i18n.language === 'en' ? EN : UA },
    id: currentItemId,
  })

  const shareHandler = () => {
    dispatch(setShareModal(true))
  }

  if (isLoading) {
    return <DotsLoader />
  }

  return (
    <div className={styles.mobileBottomMenu}>
      <ChooseInfoBlock
        item={item}
        voteCount={voteCount}
        isMobile={isMobile}
        reviewHandler={clickHandler}
        likeHandler={likeHandler}
        shareHandler={shareHandler}
        favouritesHandler={favouritesHandler}
        liked={likesList.data?.results.includes(data?.id)}
        inFavourites={favoritesList.data?.results.includes(data?.id)}
      />
      <button
        onMouseEnter={() => setHoveredButton(true)}
        onMouseLeave={() => setHoveredButton(false)}
        onClick={() => {
          clickHandler();
          dispatch(setItemId(currentItemId))
        }}
        className={`${styles.footerContentButton} ${isMobile ? styles.footerContentButtonMobile : ""}`}>
        <ButtonIcon stroke={hoveredButton} />
      </button>
    </div>
  )
}

export default MobileBottomInfoBlock
