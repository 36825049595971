import {ROUTES_WITHOUT_LOGIN_BUTTON} from "../../constants/structure";
import { useLocation } from "react-router-dom";
import { PropsWithChildren } from "react";

interface IProps extends PropsWithChildren{
  hasFilter?: boolean;
  isMobile?: boolean;
}

export const LoginButtonLayout = ({ children, isMobile }: IProps) => {
  const { pathname } = useLocation();

  return !ROUTES_WITHOUT_LOGIN_BUTTON.includes(pathname) && !isMobile ? <>{children}</> : null;
};
