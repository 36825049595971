import React from "react";
import styles from "./GridSection.module.scss";
import classnames from "classnames";
import {TimelineFilter} from "../TimelineFilter/TimelineFilter";

interface ISectionData {
  title: string;
  counter: string;
}

interface IProps {
  sectionTitle?: string;
  data: ISectionData[];
  withBorderBottom?: boolean;
  withSectionTitle?: boolean;
  withFilter?: boolean;
  variant?: "sm" | "md";
}

export const GridSection = ({
  sectionTitle,
  data,
  withBorderBottom = true,
  withSectionTitle = true,
  withFilter = false,
  variant = "md",
}: IProps) => {
  const cardWrapperMode = {
    [styles.cardWrapperSmall]: variant === "sm",
  };

  const cardTitleMode = {
    [styles.cardTitleSmall]: variant === "sm",
  };

  const cardCounterMode = {
    [styles.cardCounterSmall]: variant === "sm",
  };

  const statisticsWrapperMode = {
    [styles.wrapperWithoutBorder]: !withBorderBottom,
  };

  return (
    <section className={classnames(styles.statisticsWrapper, statisticsWrapperMode)}>
      {withSectionTitle && <h3 className={styles.sectionTitle}>{sectionTitle}</h3>}
      {withFilter && <TimelineFilter/>}
      <div className={styles.statisticsCardsWrapper}>
        {data.map(({ title, counter }, index) => (
          <div key={index} className={classnames(styles.cardWrapper, cardWrapperMode)}>
            <p className={classnames(styles.cardTitle, cardTitleMode)}>{title}</p>
            <p className={classnames(styles.cardCounter, cardCounterMode)}>{counter}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
