import styles from '../../components/sideBar/SideBar.module.scss';

import { ComponentProps, forwardRef } from "react"

interface IProps {
  selected?: boolean
  isMobile?: boolean
}

export const Likes = forwardRef<SVGSVGElement, IProps & ComponentProps<"svg">>(({ isMobile, selected, ...props }, ref) =>
  !isMobile ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={30}
        fill="none"
        {...props}
        ref={ref}
      >
        <path
          className={!selected ? styles.chooseIcon : ''}
          fill={selected ? "#FAFAFA" : "#888"}
          fillRule="evenodd"
          d="M.708 1a1 1 0 0 1 1-1h21a1 1 0 0 1 1 1v28a1 1 0 0 1-1.442.897L12.208 24.95 2.149 29.896A1 1 0 0 1 .708 29V1Zm2 1v25.393l9.058-4.456a1 1 0 0 1 .883 0l9.059 4.456V2h-19Z"
          clipRule="evenodd"
        />
      </svg>
)
:
(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={24}
      fill="none"
      {...props}
      ref={ref}>
      <path
        stroke={selected ? "#FAFAFA" : "#888"}
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1.75 23V1h16v22l-8-4.059-8 4.059Z"
      />
    </svg>
  )
)

Likes.displayName = "IconLikes"

export default Likes
