const SkipIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={7}
    height={12}
    fill="none"
  >
    <path
      fill="#A7A7A7"
      fillRule="evenodd"
      d="M6.707 5.293a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414-1.414L4.586 6 .293 1.707A1 1 0 0 1 1.707.293l5 5Z"
      clipRule="evenodd"
    />
  </svg>
);

export default SkipIcon;
