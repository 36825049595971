import { ComponentProps } from "react";

interface IProps extends ComponentProps<"svg"> {
  pathFill?: string;
}

const ChevronRight = ({ pathFill }: IProps) => (
  <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.305919 0.28615C0.110039 0.469427 0 0.717971 0 0.977125C0 1.23628 0.110039 1.48482 0.305919 1.6681L5.47795 6.5059L0.305919 11.3437C0.115591 11.528 0.0102748 11.7749 0.0126554 12.0312C0.015036 12.2874 0.124922 12.5325 0.318647 12.7137C0.512371 12.895 0.774433 12.9977 1.04839 13C1.32235 13.0022 1.58628 12.9037 1.78334 12.7257L7.69408 7.19688C7.88996 7.0136 8 6.76505 8 6.5059C8 6.24675 7.88996 5.9982 7.69408 5.81493L1.78334 0.28615C1.5874 0.102928 1.32169 0 1.04463 0C0.767573 0 0.501858 0.102928 0.305919 0.28615Z"
      fill={pathFill ? pathFill : "#888888"}
    />
  </svg>
);

export default ChevronRight;
