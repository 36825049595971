import styles from "../styles/profile.module.scss";
import Button from "../../../components/shared/Button/Button";
import {useTranslation} from "react-i18next";

const SuccessRemoveSubscriptionModal = () => {
  const {t}= useTranslation();
  return (
    <div className={styles.subscriptionModalWrapper}>
      <div className={styles.removeSubModalTitle}>{t('subRemovedTitle')}</div>
      <div className={styles.removeSubModalDescr}>{t('subRemovedDescr')}</div>
      <Button title={t('chooseSub')} submit={() => {}} className={styles.confirm}/>
    </div>
  )
}

export default SuccessRemoveSubscriptionModal;
