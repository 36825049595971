import { ComponentProps } from "react";

interface IProps extends ComponentProps<"svg"> {}

const Cooperation4 = ({ ...props }: IProps) => (
  <svg width="100%" height="auto" viewBox="0 0 220 140" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.5 22.5H219.5V132C219.5 136.142 216.142 139.5 212 139.5H8.00001C3.85787 139.5 0.5 136.142 0.5 132V22.5Z"
      fill="#222222"
      stroke="#333333"
    />
    <rect x="49" y="46" width="13" height="3" fill="#444444" />
    <rect x="49" y="53" width="25" height="3" fill="#444444" />
    <rect x="49" y="60" width="19" height="3" fill="#444444" />
    <rect x="15" y="37" width="24" height="36" rx="4" fill="#444444" />
    <rect x="49" y="97" width="13" height="3" fill="#444444" />
    <rect x="49" y="104" width="25" height="3" fill="#444444" />
    <rect x="49" y="111" width="19" height="3" fill="#444444" />
    <rect x="15" y="88" width="24" height="36" rx="4" fill="#444444" />
    <rect x="123" y="46" width="13" height="3" fill="#444444" />
    <rect x="123" y="53" width="25" height="3" fill="#444444" />
    <rect x="123" y="60" width="19" height="3" fill="#444444" />
    <rect x="89" y="37" width="24" height="36" rx="4" fill="#444444" />
    <rect x="123" y="97" width="13" height="3" fill="#444444" />
    <rect x="123" y="104" width="25" height="3" fill="#444444" />
    <rect x="123" y="111" width="19" height="3" fill="#444444" />
    <rect x="89" y="88" width="24" height="36" rx="4" fill="#444444" />
    <rect x="163" y="84" width="42" height="41" rx="4" fill="#E50914" />
    <rect x="163" y="38" width="20" height="7" rx="2" fill="#444444" />
    <rect x="188" y="38" width="17" height="7" rx="2" fill="#444444" />
    <rect x="163" y="50" width="33" height="7" rx="2" fill="#444444" />
    <rect x="163" y="62" width="12" height="7" rx="2" fill="#444444" />
    <rect x="180" y="62" width="25" height="7" rx="2" fill="#444444" />
    <path
      d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H212C216.142 0.5 219.5 3.85786 219.5 8V22.5H0.5V8Z"
      fill="#111111"
      stroke="#333333"
    />
    <circle cx="10.5" cy="11.5" r="1.5" fill="#555555" />
    <circle cx="16.5" cy="11.5" r="1.5" fill="#555555" />
    <circle cx="22.5" cy="11.5" r="1.5" fill="#555555" />
  </svg>
);
export default Cooperation4;
