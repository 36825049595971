import Header from "../../components/header/Header";
import styles from "../favourites/Favourites.module.scss";
import SideBar from "../../components/sideBar/SideBar";
import Footer from "../../components/footer/Footer";
import React from "react";
import useWindowSize from "../../hooks/useWindowSize";
import {useTranslation} from "react-i18next";
import NotFound from "../../components/icons/services/404";

const NotFoundPage = () => {
  const { windowSize } = useWindowSize();
  const { t } = useTranslation();

  return (
    <>
      <section>
        <Header className={styles.headerWrapper}/>
        {windowSize !== "mobile" ? <SideBar/> : null}
        <div className={styles.notFoundWrapper}>
          <NotFound/>
          <div>{t('notFound')}</div>
        </div>
        <Footer source={''}/>
      </section>
    </>
  )
}

export default NotFoundPage;
