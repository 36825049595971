import React from "react"

import styles from "./SimilarContent.module.scss"
import SimilarContentCard from "./SimilarContentCard"
import Heading from "../../../../components/shared/Heading";
import useInterfaceContext from "../../../../components/providers/interface";
import {useTranslation} from "react-i18next";
import {useGetFilmsListQuery} from "../../../../store/api/filmAPI";
import useLikeClick from "../../../../hooks/useLikeClick";
import DotsLoader from "../../../../components/icons/DotsLoader";

interface ISimilarContent {
  item: any
}

const SimilarContent = ({ item }: ISimilarContent) => {
  const {isMobile} = useInterfaceContext();
  const { likesList, likeHandler } = useLikeClick();
  const { t } = useTranslation();

  const { data, isLoading } = useGetFilmsListQuery({
    mainFilter: "/movies/discover",
    queries: {
      with_genres: item.genres ? item.genres[0].pk : '',
      sort_by: "popularity.desc"
    },
  })


  if (isLoading) {
    return <DotsLoader />
  }

  const mainList = data.results;

  return (
    <div className={styles.sectionWrapper}>
      <Heading title={t('similar')} />
      <div className={styles.contentWrapper}>
        <div className={styles.cardListWrapper}>
          {mainList.map((item: any, index: number) => (
            <SimilarContentCard
              key={index}
              isMobile={isMobile}
              movie={item}
              liked={likesList.data?.results.includes(item.id)}
              likeHandler={likeHandler}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SimilarContent
