export const ROUTES = {
  NOT_FOUND: "*",
  INDEX: "/",
  FOR_YOU: "/feed",
  SEARCH: "/search",
  FILTERS: "/filters",
  DETAILS: "/details",
  LOGIN: "/login",
  CONFIRMATION: '/confirmation',
  MORE: "/more",
  COOPERATION: "/cooperation",
  ABOUT_US: "/about-us",
  SUPPORT: "/support",
  AGREEMENT: "/agreement",
  PROFILE: '/profile',
  ACTIVITY: "/activity",
  USERS: "/users",
  PRE_PAYMENT: "/pre-payment",
  PAY: "/pay",
  GREETINGS: "/greetings",
  DISCOUNT: "/discount",
  MOBILE_ACCOUNT_SETTINGS: "/mobile_account_settings",
  MOBILE_PAYMENT_DETAILS: "/mobile_payment_details",
  SUPPORT_CHAT: "/support-chat",
  FAVOURITES: "/favourites",
};
