const Gift = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={24}
      fill="none"
    >
      <path
        fill="#A7A7A7"
        d="M20.24 6h-3.114a3.888 3.888 0 0 0 .643-2.143C17.77 1.731 16.06 0 13.962 0 12.76 0 11.701.574 11 1.466 10.298.583 9.24 0 8.038 0 5.94 0 4.231 1.731 4.231 3.857c0 .789.237 1.526.643 2.143H1.76C.787 6 0 6.771 0 7.714V12c0 .926.753 1.671 1.692 1.706v6.865C1.692 22.466 3.283 24 5.238 24h11.524c1.955 0 3.546-1.534 3.546-3.429v-6.865C21.247 13.67 22 12.917 22 12V7.714C22 6.771 21.213 6 20.24 6Zm-6.278-4.286c1.167 0 2.115.96 2.115 2.143C16.077 5.04 15.129 6 13.96 6h-2.115V3.857c0-1.183.948-2.143 2.115-2.143ZM5.922 3.857c0-1.183.948-2.143 2.115-2.143 1.168 0 2.116.96 2.116 2.143V6H8.038c-1.167 0-2.115-.96-2.115-2.143ZM1.683 7.74s.018-.026.077-.026h8.394V12H1.692V7.74h-.008Zm3.555 14.546c-1.024 0-1.853-.772-1.853-1.715v-6.857h6.769v8.572H5.238Zm13.377-1.715c0 .943-.829 1.715-1.853 1.715h-4.916v-8.572h6.77v6.857ZM20.24 12h-8.394V7.714h8.462L20.24 12Z"
      />
    </svg>
  )
};

export default Gift;
