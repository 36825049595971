import useFavourites from "../utils/hooks/useFavourites";
import {useGetIdsListQuery} from "../store/api/filmAPI";

const useFavouriteClick = () => {
  const token = localStorage.getItem("accessToken");

  const { saveFavourites, unSaveFavourites } = useFavourites();

  const favoritesList = useGetIdsListQuery({
    mainFilter: "/movies/favorites/ids",
    queries: {}
  },
  {
    skip: !token,
  });

  const favouritesHandler = async (e: any, item: any, inFav: boolean) => {
    e.stopPropagation();
    if (!token) return;

    if (inFav) {
      await unSaveFavourites(item.id);
    } else {
      await saveFavourites(item.id);
    }
    favoritesList.refetch();
  }

  return {
    favoritesList,
    favouritesHandler
  }
};

export default useFavouriteClick;
