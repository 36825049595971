import styles from "./AdminFooter.module.scss";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { footerAdminMenuItems } from "../../../constants/structure";
import AdminSideBarIcon from "../AdminSideBar/AdminSideBarIcon";

const AdminFooter = () => {
  const [horizontal, setHorizontal] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener(
        "orientationchange",
        () => {
          if (window.innerHeight > window.innerWidth) setHorizontal(false);
          else setHorizontal(true);
        },
        false,
      );
    }

    return () => {
      window.removeEventListener("orientationchange", () => {});
    };
  }, []);

  return (
    <div className={styles.footerWrapperMobile} style={!horizontal ? { display: "none" } : {}}>
      {footerAdminMenuItems.map((item: any) => (
        <Link key={item.path} to={`/${item.path}`} className={styles.itemWrapper}>
          <AdminSideBarIcon iconName={item.path} selectedItem={pathname === `/${item.path}`} />
        </Link>
      ))}
    </div>
  );
};

export default AdminFooter;
