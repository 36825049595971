const CommentsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={51}
      height={50}
      fill="none"
    >
      <path
        fill="#555"
        d="M2.425 50c-.5 0-1-.192-1.366-.558a1.905 1.905 0 0 1-.52-1.75l2.424-11.848A25.315 25.315 0 0 1 .866 20.708a24.979 24.979 0 0 1 7.6-14.002C12.43 3.014 17.586.667 22.973.13 28.38-.43 33.883.84 38.52 3.649a25.135 25.135 0 0 1 10.255 12.193 25.166 25.166 0 0 1 .808 15.906 25.205 25.205 0 0 1-8.966 13.174C36.287 48.193 30.92 50 25.513 50c-3.752 0-7.484-.846-10.851-2.481L2.81 49.942c-.135.02-.25.039-.385.039V50Zm12.506-6.405c.308 0 .616.077.885.212a20.979 20.979 0 0 0 9.697 2.346c4.58 0 9.12-1.519 12.776-4.308a21.456 21.456 0 0 0 7.6-11.136c1.231-4.423 1-9.193-.693-13.463-1.674-4.27-4.772-7.924-8.677-10.328a21.329 21.329 0 0 0-13.16-2.981 21.29 21.29 0 0 0-12.276 5.558 21.293 21.293 0 0 0-6.426 11.848 21.304 21.304 0 0 0 2.04 13.328c.191.385.268.846.172 1.27l-1.981 9.635 9.639-1.98c.135-.02.25-.04.385-.04l.019.04Z"
      />
    </svg>
  )
}

export default CommentsIcon;
