const Prev = ({currentStep}: {currentStep: any}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={34}
      height={35}
      fill="none"
    >
      <path
        fill={currentStep === 1 ? "#444" : "#DDDDDD"}
        fillRule="evenodd"
        d="M23 17.5c0 .19-.072.371-.201.505a.675.675 0 0 1-.486.21h-7.965l2.952 3.064a.718.718 0 0 1 .201.506.74.74 0 0 1-.201.506.688.688 0 0 1-.487.209.667.667 0 0 1-.486-.21l-4.125-4.284A.714.714 0 0 1 12 17.5a.738.738 0 0 1 .202-.506l4.124-4.285a.675.675 0 0 1 .487-.209c.183 0 .358.075.487.21a.73.73 0 0 1 .201.505.73.73 0 0 1-.201.506l-2.952 3.065h7.965c.182 0 .357.075.486.209A.729.729 0 0 1 23 17.5Z"
        clipRule="evenodd"
      />
      <rect
        width={32}
        height={32}
        x={-1}
        y={1}
        stroke={currentStep === 1 ? "#444" : "#DDDDDD"}
        strokeWidth={2}
        rx={16}
        transform="matrix(-1 0 0 1 32 .5)"
      />
    </svg>
  )
}

export default Prev;
