const SearchFieldIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
    >
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        d="m17 17-3.778-3.784L17 17Zm-1.684-8.842A7.158 7.158 0 1 1 1 8.158a7.158 7.158 0 0 1 14.316 0v0Z"
      />
    </svg>
  )
}

export default SearchFieldIcon;
