import { ComponentProps } from "react";

interface IProps extends ComponentProps<"circle"> {
  stroke: string;
}

const UsersIcon = ({ stroke = '#888888' }: IProps) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="13" stroke={stroke} strokeWidth="2" />
    <circle cx="14" cy="14" r="13" stroke={stroke} strokeWidth="2" />
    <path d="M20 14C20 17.3137 17.3137 20 14 20C10.6863 20 8 17.3137 8 14" stroke={stroke} strokeWidth="2"/>
  </svg>
);

export default UsersIcon;
