interface IProps {
  className: string,
  inFavourites: boolean
}
const SavedIcon = ({ className, inFavourites }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={18}
      fill="none"
    >
      <path
        className={className}
        stroke="#FAFAFA"
        fill={inFavourites ? '#FAFAFA' : "rgba(0, 0, 0, 0)"}
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 17V1h12v16l-6-2.952L1 17Z"
      />
    </svg>
  )
}

export default SavedIcon;
