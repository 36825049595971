import styles from "../styles/FilterComponents.module.scss";
import XIcon from "../../../components/icons/filters/xIcon";
import {CSSTransition} from "react-transition-group";

interface IProps {
  clearFiltersHandler: () => void;
  hideBtn?: boolean;
}
const ClearButton = ({clearFiltersHandler, hideBtn}: IProps) => {
  return (
   <div className={styles.clearButtonWrap}>
     <CSSTransition
       in={!hideBtn}
       timeout={300}
       classNames={{
         enter: styles.fadeEnter,
         enterActive: styles.fadeEnterActive,
         exit: styles.fadeExit,
         exitActive: styles.fadeExitActive,
       }}
       unmountOnExit
     >
       <div
         onClick={clearFiltersHandler}
         className={styles.clearFiltersButton}
       ><XIcon />Clear</div>
     </CSSTransition>
   </div>
  )
}

export default ClearButton;
