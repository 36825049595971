import React, {useEffect} from "react";
import styles from "./SupportPage.module.scss";
import SideBar from "../../components/sideBar/SideBar";
import useWindowSize from "../../hooks/useWindowSize";
import Heading from "../../components/shared/Heading";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

const CooperationPage = () => {
  const { windowSize } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={styles.wrapper}>
      <Header className={styles.headerWrapper} />
      {windowSize !== "mobile" && <SideBar />}
      <div className={styles.container}>
        <Heading classNameTitle={styles.title} title="Угода користувача та полiтика конфiденцiйностi" />
        <section className={styles.sectionWrapper}>
          <h3 className={styles.sectionTitle}>1. ПРЕДМЕТ І ЗАГАЛЬНI ПОЛОЖЕННЯ УГОДИу</h3>
          <ul className={styles.list}>
            <li className={styles.sectionText}>
              1.1. Предметом цієї Угоди користувача (далі - «Угода») є відносини між Товариством з обмеженою
              відповідальністю «HIVIEWER», юридичною особою, яка зареєстрована і діє за законодавством України, код
              ЄДРПОУ
              — 38347009, що є адміністратором (далі - «Адміністратор») Медіасервісу HIVIEWER , і Вами (далі
              - «Користувач»), щодо використання Медіасервісу HIVIEWER (далі - «Медіасервіс»), в тому числі Контенту,
              Доступ до якого надається через Медіасервіс, а також інших послуг, в тому числі сервісів, що надаються
              через
              Медіасервіс.
            </li>
            <li className={styles.sectionText}>
              Датою укладення цієї Угоди визнається дата початку використання Користувачем Медіасервісу, його послуг
              та/або сервісів.
            </li>
            <li className={styles.sectionText}>
              1.2. Користувачем визнається будь-яка фізична особа, яка використовує Медіасервіс на платній та/або
              безоплатній основі незалежно від факту реєстрації в Медіасервісі.
            </li>
            <li className={styles.sectionText}>
              1.3. Медіасервіс HIVIEWER або Медіасервіс складається із веб-сайтів HIVIEWER.net та HIVIEWER.ua (далі
              – «Сайти», а кожен окремо – «Сайт»), програмних додатків HIVIEWER (які є невід’ємною частиною
              Медіасервісу)
              для різних видів пристроїв: мобільних, Смарт ТБ (Smart TV) та інших. Використовуючи Медіасервіс,
              Користувач
              може отримати Доступ до Контенту, а також скористатися іншими послугами Медіасервісу в режимі реального
              часу
              як через Сайт, так і через окремі програмні додатки HIVIEWER, що доступні на пристроях, підключених до
              мережі Інтернет (далі - «Пристрої»).
            </li>
            <li className={styles.sectionText}>
              1.4. Контентом є аудіальні (аудіокниги, лекції, вистави та інші твори), аудіовізуальні, музичні твори,
              фонограми, відеограми, ТБ-канали, графічні, текстові та інші матеріали, електронні (відео-) ігри, Доступ
              до
              яких надається Користувачам за допомогою Медіасервісу.
            </li>
          </ul>
        </section>
      </div>
      <Footer source={''}/>
    </section>
  );
};

export default CooperationPage;
