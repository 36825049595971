import { useState } from 'react';
import axiosInstance from "../api/axios";
import axios from "axios";
import {useDispatch} from "react-redux";
import {setCredentials} from "../store/api/authSlice";

interface LoginCredentials {
  emailOrPhone: string;
}

interface LoginCode {
  code: string;
}

interface LoginResponse {
  data: {
    token: {
      access: string;
      refresh: string;
    }
  }
}

export const useLogin = () => {
  const [error, setError] = useState<string>('');
  const dispatch = useDispatch();

  const loginStepOne = async ({ emailOrPhone }: LoginCredentials) => {
    try {
      localStorage.setItem('contact', emailOrPhone);
      await axiosInstance.post<LoginResponse>('login/', { email: emailOrPhone });

      setError('');
    } catch (err: any) {
      if (axios.isAxiosError(err) && err.response?.data) {
        setError(err?.response.data);
      } else {
        setError(err.message as any);
      }
    }
  };

  const loginStepTwo = async ({ code }: LoginCode) => {
    try {
      const response = await axiosInstance.post<LoginResponse>('validate-login/',
        { otp: code, email: localStorage.getItem('contact')}
      );

      // @ts-ignore
      localStorage.setItem('accessToken', response.data.token.access);// @ts-ignore
      localStorage.setItem('refreshToken', response.data.token.refresh);// @ts-ignore
      dispatch(setCredentials({ token: response.data.token.access }));
      setError('');
    } catch (err: any) {
      if (axios.isAxiosError(err) && err.response?.data) {
        setError(err?.response.data);
      } else {
        setError(err.message as any);
      }
    }
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    dispatch(setCredentials({ token: '' }));
  }
  return { loginStepOne, loginStepTwo, error, logout };
};
