import styles from "./Header.module.scss";
import CloseIcon from "../icons/HeaderClose";
import useWindowSize from "../../hooks/useWindowSize";
import {useNavigate} from "react-router-dom";

const CommonCloseModule = () => {
  const { windowSize } = useWindowSize();
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(-1)}
      className={`${windowSize === "mobile" ? styles.mobileFilterButton : styles.desctopFilterButton}`}
    >
      <CloseIcon isMobile={windowSize === "mobile"}/>
    </button>
  )
}

export default CommonCloseModule;
