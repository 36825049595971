import styles from '../../../pages/foryou/_styles/InfoBlock.module.scss';
import { ComponentProps, forwardRef } from "react"

interface IProps {
  pathStroke?: string
  isMobile?: boolean
}

export const SecondaryShare = forwardRef<SVGSVGElement, IProps & ComponentProps<"svg">>(
  ({ isMobile, pathStroke, ...props }, ref) =>
    isMobile ? (
      <svg
        className={styles.secondaryShareIcon}
        xmlns="http://www.w3.org/2000/svg"
        width={29}
        height={25}
        fill="rgba(0, 0, 0, 0)"
        {...props}
        ref={ref}
      >
        <path
          stroke={pathStroke ? pathStroke : "#fafafa"}
          strokeWidth={2}
          d="m1.22 24 .058-.03c1.563-2.02 3.099-3.695 5.263-4.826l.001-.001c2.003-1.043 4.457-1.555 7.572-1.65l1.03-.03v6.448c0 .018.006.036.016.05l-.837.548.837-.548a.08.08 0 0 0 .034.03.068.068 0 0 0 .04.005.073.073 0 0 0 .037-.02l11.845-11.411a.086.086 0 0 0 .02-.03l.918.39-.919-.39a.096.096 0 0 0-.02-.103L15.272 1.021V1.02a.074.074 0 0 0-.037-.019.068.068 0 0 0-.04.005.08.08 0 0 0-.034.03l-.837-.549.837.548a.093.093 0 0 0-.015.051v6.412l-.954.045c-4.745.22-8.141 1.976-10.283 5.125-1.798 2.645-2.765 6.396-2.765 11.245 0 .026.01.05.026.065a.072.072 0 0 0 .05.022Z"
        />
      </svg>
    ) : (
      <svg
        className={styles.secondaryShareIcon}
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={18}
        fill="rgba(0, 0, 0, 0)"
        {...props}
      >
        <path
          stroke={pathStroke ? pathStroke : "#fafafa"}
          strokeWidth={2}
          d="M11.001 13.315v3.364l7.909-7.68L11 1.319V5.666l-.953.044c-3.316.156-5.65 1.386-7.12 3.566-1.161 1.721-1.836 4.139-1.92 7.271.99-1.228 2.027-2.273 3.427-3.01h.001c1.483-.78 3.284-1.152 5.534-1.222l1.031-.031v1.031Z"
        />
      </svg>
    )
)

SecondaryShare.displayName = "IconSecondaryShare"

export default SecondaryShare
