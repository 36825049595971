import Slider from "@mui/material/Slider"
import styles from "../styles/FilterComponents.module.scss"
import classnames from "classnames";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setTemporaryPeriod} from "../../../store/slices/filters.slice";
import ClearButton from "./ClearButton";

const minDistance = 1

interface IProps {
  isMobile: boolean;
  className?: string;
  setPeriodValue?: any;
  period?: any;
  clearFiltersHandler: () => void;
}

const PeriodModule = ({
  isMobile,
  className,
  setPeriodValue,
  period,
  clearFiltersHandler
}: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const handleChange1 = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (activeThumb === 0) {
      const val = [Math.min(newValue[0], period[1] - minDistance), period[1]]
      setPeriodValue(val);
      dispatch(setTemporaryPeriod(val))
    } else {
      const val = [period[0], Math.max(newValue[1], period[0] + minDistance)]
      setPeriodValue(val)
      dispatch(setTemporaryPeriod(val))
    }
  }

  return (
    <div className={
      `${isMobile ? styles.periodWrapperMobile : classnames(styles.periodWrapper, className)}`
    }>
      <div className={`${isMobile ? styles.titleMobile : styles.title}`}>
        <span>
          {t('Period')}
          <ClearButton
            clearFiltersHandler={clearFiltersHandler}
            hideBtn={period[0] === 1980 && period[1] === 2024}
          />
        </span>
      </div>
      <div className={styles.dateWrapper}>
        <div className={`${isMobile ? styles.dateItemMobile : styles.dateItem}`}>{period[0]}</div>
        <div className={`${isMobile ? styles.dateItemMobile : styles.dateItem}`}>{period[1]}</div>
      </div>
      <Slider
        min={1980}
        max={2024}
        className="period-controller"
        getAriaLabel={() => "Minimum distance"}
        value={period}
        onChange={handleChange1}
        disableSwap
      />
    </div>
  )
}

export default PeriodModule
