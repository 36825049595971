import useInterfaceContext from "../../components/providers/interface";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {useEffect, useState} from "react";
import {
  resetGenres,
  // resetPeriod,
  setFilterChanged,
  setGenres,
  setPeriod,
  setTemporaryCounter,
  setTemporaryGenres
} from "../../store/slices/filters.slice";
import styles from "./styles/FilterComponents.module.scss";
import Header from "../../components/header/Header";
import {ROUTES} from "../../constants/routes";
// import PeriodModule from "./components/PeriodModule";
import GenresModule from "./components/GenresModule";
import Button from "../../components/shared/Button/Button";
import Footer from "../../components/footer/Footer";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Filters = () => {
  const { isMobile } = useInterfaceContext();
  const dispatch = useAppDispatch()
  const genres = useAppSelector((state: any) => state.filters.genres)
  const period = useAppSelector((state: any) => state.filters.period)
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [periodValue, setPeriodValue] = useState<number[]>([1980, 2024]);
  const [genresValue, setGenresValue] = useState<any>([])

  useEffect(() => {
    setPeriodValue(period);
  }, [period]);

  useEffect(() => {
    setGenresValue(genres);
  }, [genres]);

  useEffect(() => {
    if (genresValue.length || periodValue[0] !== 1980 || periodValue[1] !== 2024) {
      dispatch(setFilterChanged(true))
    } else {
      dispatch(setFilterChanged(false))
    }
    dispatch(setTemporaryCounter({genres: genresValue, period: periodValue}))
  }, [periodValue, genresValue, dispatch]);

  const submitFilters = () => {
    dispatch(setPeriod(periodValue))
    dispatch(setGenres(genresValue))

    const timeout =  setTimeout(() => {
      navigate(ROUTES.FOR_YOU);
      clearTimeout(timeout);
    }, 300)
  }

  const setGenresValueHandler = (itemId: any) => {
    setGenresValue((currentIds: any) => {
      if (currentIds.includes(itemId)) {
        return currentIds.filter((currentId: any) => currentId !== itemId);
      } else {
        return [...currentIds, itemId];
      }
    });
    dispatch(setTemporaryGenres(itemId))
  }

  const clearGenresHandler = () => {
    setGenresValue([]);
    dispatch(resetGenres());
  }

  // const clearPeriodHandler = () => {
  //   setPeriodValue([1980, 2024]);
  //   dispatch(resetPeriod());
  // }

  return (
    <div className={styles.filtersModalWrapper}>
      <Header source={ROUTES.FILTERS} clickHandler={() => {}} className={styles.headerWrapperFilters}/>
      <div className={`${isMobile ? styles.contentWrapperMobile : styles.contentWrapper}`}>
        {/*<PeriodModule*/}
        {/*  isMobile={isMobile}*/}
        {/*  setPeriodValue={setPeriodValue}*/}
        {/*  period={periodValue}*/}
        {/*  clearFiltersHandler={clearPeriodHandler}*/}
        {/*/>*/}
        <GenresModule
          isMobile={isMobile}
          setGenresValue={setGenresValueHandler}
          genres={genresValue}
          clearFiltersHandler={clearGenresHandler}
        />
        <div className={styles.btnsWrapper}>
          {!isMobile && <Button submit={submitFilters} title={t('apply')} className={styles.submitButton}/>}
        </div>
      </div>
      <div style={{height: '50px'}}/>
      <Footer source={''}/>
    </div>
  )
};

export default Filters;
