import { useGetIdsListQuery } from "../store/api/filmAPI";
import useLikes from "../utils/hooks/useLikes";

const useLikeClick = () => {
  const token = localStorage.getItem("accessToken");

  const { like, unLike } = useLikes();

  const likesList = useGetIdsListQuery({
    mainFilter: "/movies/likes/ids",
    queries: {}
  },
  {
    skip: !token,
  });

  const likeHandler = async (e: any, item: any, liked: boolean) => {
    e.stopPropagation();
    if (!token) return;

    if (liked) {
      await unLike(item.id);
    } else {
      await like(item.id);
    }
    likesList.refetch();
  }

  return {
    likesList,
    likeHandler
  }
};

export default useLikeClick;
