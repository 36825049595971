import { ComponentProps } from "react";
import styles from "../../../pages/foryou/_styles/InfoBlock.module.scss";

interface IProps extends ComponentProps<"svg"> {
  isMobile?: boolean;
  inFavourites?: boolean;
}

const SavedDetails = ({ isMobile, inFavourites, ...props }: IProps) =>
  !isMobile ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={22}
      fill={inFavourites ? '#FAFAFA' : 'rgba(0, 0, 0, 0)'}
      className={styles.savedIcon}
      {...props}
    >
      <path
        stroke="#FAFAFA"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 21V1h15v20l-7.5-3.69L1 21Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={19}
      height={22}
      fill={inFavourites ? '#FAFAFA' : 'none'}
      {...props}
    >
      <path
        stroke="#FAFAFA"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1.333 21V1h16v20l-8-3.69-8 3.69Z"
      />
    </svg>
  )
;
export default SavedDetails;
