import {ChangeEvent, useEffect, useRef, useState} from "react";
import styles from './InputWithState.module.scss';
import {useTranslation} from "react-i18next";

interface InputComponentProps {
  type: string;
  handler: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
  label: string;
}

const InputWithState = ({ type, handler, placeholder, value, label }: InputComponentProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditable, setIsEditable] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    if (isEditable) {
      inputRef.current?.focus();
    }
  }, [isEditable]);

  const toggleEdit = (value: boolean) => {
    setIsEditable(value);
  };

  return (
    <>
      <div className={styles.label}>{label}</div>
      <div className={styles.inputWrapper}>
        {isEditable ? (
          <input
            ref={inputRef}
            onBlur={() => toggleEdit(false)}
            className={styles.input}
            type={type}
            onChange={handler}
            placeholder={placeholder}
            value={value}
            autoFocus={true}
          />
        ) : (
          <div className={styles.input}>{value || placeholder}</div>
        )}
        {!isEditable && <button className={styles.disableInputButton} onClick={() => toggleEdit(true)}>
          {t('change')}
        </button>}
      </div>
    </>
  );
};

export default InputWithState;
