import {useEffect} from "react";
import styles from "./MobileComponents.module.css";
import Slider from "@mui/material/Slider";

interface IProps {
  maxValue: any;
  time: any;
  player: any,
  setTime: (value: number) => void;
  isMobile?: boolean;
}

const MobileProgressController = ({ maxValue, time, player, setTime, isMobile }: IProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        setTime(time + 15);
        if (player) {
          player.seekTo(time + 15, 'seconds');
        }
      } else if (event.key === 'ArrowLeft') {
        setTime(time - 15);
        if (player) {
          player.seekTo(time - 15, 'seconds');
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [time, setTime, player]);

  return (
    <div className={`${styles.progressControllerWrapper} ${isMobile ? styles.progressControllerWrapperMobile : ''}`}>
      <Slider
        className={isMobile ? `progress-controller-mobile` : `progress-controller`}
        min={0}
        max={maxValue as number}
        defaultValue={0}
        value={time}
        onChange={(_, value) => {
          if (player) {// @ts-ignore
            player.seekTo(value, 'seconds');
          }
          setTime(value as number);
        }}
      />
    </div>
  )
};

export default MobileProgressController;
