import React from "react";
import styles from "./PrePaymentPage.module.scss";
import useWindowSize from "../../../hooks/useWindowSize";
import { ROUTES } from "../../../constants/routes";
import Header from "../../../components/header/Header";
import AdminSideBar from "../../../components/admin/AdminSideBar/AdminSideBar";
import CounterLayout from "../../../components/shared/CounterLayout";
import { GridSection } from "../../../components/admin/GridSection/GridSection";
import { TimelineFilter } from "../../../components/admin/TimelineFilter/TimelineFilter";
import { CountriesFilter } from "../../../components/admin/CountriesFilter/CountriesFilter";
import classnames from "classnames";
import Button from "../../../components/shared/Button/Button";
import AdminFooter from "../../../components/admin/AdminFooter/AdminFooter";
import Footer from "../../../components/footer/Footer";

const PAYMENTS = [
  { title: "Нові", counter: "1 072" },
  { title: "Всі", counter: "1 050" },
  { title: "Старі", counter: "1 050" },
];

const MONTH_DATA = [
  { title: "Місячні", counter: "222" },
  { title: "Місячні", counter: "333" },
  { title: "Місячні", counter: "444" },
];

const QUARTER_DATA = [
  { title: "Квартальні", counter: "222" },
  { title: "Квартальні", counter: "333" },
  { title: "Квартальні", counter: "444" },
];

const YEAR_DATA = [
  { title: "Річні", counter: "222" },
  { title: "Річні", counter: "333" },
  { title: "Річні", counter: "444" },
];

const INCOME = [
  { title: "Всі пердплати", income: "34 722₴", totalIncome: "894,82$" },
  { title: "Нові пердплати", income: "34 722₴", totalIncome: "894,82$" },
  { title: "Старі пердплати", income: "34 722₴", totalIncome: "894,82$" },
];

const PrePaymentPage = () => {
  const { windowSize } = useWindowSize();
  const isNotMobile = windowSize !== "mobile";

  return (
    <section className={styles.wrapper}>
      <Header className={styles.headerWrapper} backButtonClassName={styles.backButton} />
      {isNotMobile && <AdminSideBar />}
      <CounterLayout title="$10 724" subtitle="Місячний дохід">
        <div className={styles.contentWrapper}>
          <CountriesFilter />
          <TimelineFilter />
          <GridSection sectionTitle="Передплати" data={PAYMENTS} withBorderBottom={false} variant="md" />
          <div className={styles.timeDataWrapper}>
            <GridSection withSectionTitle={false} data={MONTH_DATA} withBorderBottom={false} variant="sm" />
            <GridSection withSectionTitle={false} data={QUARTER_DATA} withBorderBottom={false} variant="sm" />
            <GridSection withSectionTitle={false} data={YEAR_DATA} variant="sm" />
          </div>
          <section className={classnames(styles.incomeWrapper, styles.withBorder)}>
            <h3 className={styles.sectionTitle}>Дохід</h3>
            <div className={styles.cardsList}>
              {INCOME.map(({ title, income, totalIncome }) => (
                <div key={title} className={styles.cardWrapper}>
                  <p className={styles.title}>{title}</p>
                  <p className={styles.income}>{income}</p>
                  <p className={styles.totalIncome}>{totalIncome}</p>
                </div>
              ))}
            </div>
          </section>
          <section className={classnames(styles.incomeWrapper, styles.withBorder)}>
            <h3 className={styles.sectionTitle}>Дохід за типами</h3>
            <div className={styles.cardsList}>
              {INCOME.map(({ title, income, totalIncome }) => (
                <div key={title} className={styles.cardWrapper}>
                  <p className={styles.title}>{title}</p>
                  <p className={styles.income}>{income}</p>
                  <p className={styles.totalIncome}>{totalIncome}</p>
                </div>
              ))}
            </div>
          </section>
          <section>
            <div className={styles.incomeWrapper}>
              <h3 className={styles.sectionTitle}>Загальний дохід</h3>
              <div className={styles.totalIncomeCounterWrapper}>
                <p className={styles.totalIncomeCounter}>34 722₴</p>
                <p className={styles.totalIncomeTotalCounter}>894,82$</p>
              </div>
            </div>
          </section>
          <Button className={styles.reportButton} title="Сформувати звіт" submit={() => alert("Button clicked!")} />
        </div>
      </CounterLayout>
      {isNotMobile ? <Footer source={ROUTES.PRE_PAYMENT} /> : <AdminFooter />}
    </section>
  );
};

export default PrePaymentPage;
