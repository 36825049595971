import btn from "./button.svg";
import kb from "./keybord.svg";
import space from "./space.svg";
import reactions from "./reactions.svg";
import user from "./user.svg";
import swipe from "./swipe.svg";

import React from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import styles from "./tips.module.scss";
import SkipIcon from "./SkipIcon";
import Prev from "./Prev";
import Next from "./Next";

const BTN = () => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";
  const loaderSize = isMobile
    ? {width: '170px', height: '53px'} : {width: '75px', height: '75px'}
  return (
    <div>
      <object type="image/svg+xml" data={btn} style={loaderSize}>svg-animation</object>
    </div>
  )
}
const SWIPE = () => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";
  const loaderSize = isMobile
    ? {width: '210px', height: '145px'} : {width: '75px', height: '75px'}
  return (
    <div>
      <object type="image/svg+xml" data={swipe} style={loaderSize}>svg-animation</object>
    </div>
  )
}
const KB = () => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";
  const loaderSize = isMobile
    ? {width: '170px', height: '53px'} : {width: '110px', height: '110px'}
  return (
    <div>
      <object type="image/svg+xml" data={kb} style={loaderSize}>svg-animation</object>
    </div>
  )
}
const SPACE = () => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";
  const loaderSize = isMobile
    ? {width: '170px', height: '53px'} : {width: '186px', height: '35px'}
  return (
    <div>
      <object type="image/svg+xml" data={space} style={loaderSize}>svg-animation</object>
    </div>
  )
}
const REACTIONS = () => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";
  const loaderSize = isMobile
    ? {width: '300px', height: '55px'} : {width: '440px', height: '55px'}
  return (
    <div>
      <object type="image/svg+xml" data={reactions} style={loaderSize}>svg-animation</object>
    </div>
  )
}
const USER = () => {
  const loaderSize = {width: '85px', height: '85px'}
  return (
    <div>
      <object type="image/svg+xml" data={user} style={loaderSize}>svg-animation</object>
    </div>
  )
}

const TipsComponent = ({setGuide}: { setGuide: any }) => {
  const [currentTip, setCurrentTip] = React.useState(1);
  const { windowSize } = useWindowSize();
  const isMobile = windowSize === "mobile";

  const handleIncrement = () => {
    if (currentTip < 3) {
      setCurrentTip((prev) => prev + 1);
    }
  };

  const handleDecrement = () => {
    if (currentTip > 1) {
      setCurrentTip((prev) => prev - 1);
    }
  };

  const finishGuideHandler = (tip: any, skip?: any) => {
    if (tip === 3 || skip) {
      setGuide(false);
      localStorage.setItem('guide', '1');
    }
  }

  const firstSectionContent = (isMob: any) => {
    if (!isMob) {
      return (
        <div>
          <div className={styles.heading}>
            Для пошуку фільмів, можна натиснути <span>кнопку «вниз» або стрілку «вниз» чи «пробіл»</span>
          </div>
          <div className={styles.firstSectionIcons}>
            <BTN/>
            <p>,</p>
            <KB/>
            <p>або</p>
            <SPACE/>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className={styles.heading}>
          Для пошуку фільмів, зробіть свайп <span>«вниз»</span> або <span>«вгору»</span>
        </div>
        <div className={styles.firstSectionIcons}>
          <SWIPE />
        </div>
      </div>
    )
  }
  return (
    <div className={styles.tipsWrapper}>
      {currentTip === 1 && firstSectionContent(isMobile)}
      {currentTip === 2 && (
        <div>
          <div className={styles.heading}>
            <span>Реакції, відгуки, обрані та поділитися</span> допомагають підібрати для вас фільм.
          </div>
          <div className={styles.firstSectionIcons}>
          <REACTIONS/>
          </div>
        </div>
      )}
      {currentTip === 3 && (
        <div>
          <div className={styles.heading}>
            <span>Увійдіть</span> до профілю, що би користуватися усіма функціями <span>HIVIEWER</span>
          </div>
          <div className={styles.firstSectionIcons}>
            <USER />
          </div>
        </div>
      )}
      <div className={styles.navWrapper}>
        <div onClick={handleDecrement}><Prev currentStep={currentTip}/></div>
        {`${currentTip} / 3`}
        <div onClick={handleIncrement}><Next currentStep={currentTip}/></div>
      </div>
      <div
        onClick={() => finishGuideHandler(currentTip, true)}
        className={styles.skipWrapper}
      >{currentTip === 3 ? 'Готово' : 'Пропустити'} <SkipIcon/></div>
    </div>
  )
}

export default TipsComponent;
