import { ComponentProps } from "react";

interface IProps extends ComponentProps<"svg"> {}

const ArrowDown = ({ ...props }: IProps) => (
  <svg width="40" height="21" viewBox="0 0 40 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20 17.411L2.55606 0.435995C2.41548 0.296236 2.24859 0.185747 2.06503 0.110918C1.88147 0.0360889 1.6849 -0.00159271 1.48668 5.33408e-05C1.28846 0.00169939 1.09254 0.0426445 0.910254 0.120512C0.727965 0.198379 0.562928 0.311626 0.424693 0.453701C0.286457 0.595776 0.177769 0.763855 0.104917 0.948214C0.0320659 1.13257 -0.00350246 1.32955 0.000270795 1.52775C0.00404405 1.72594 0.047084 1.92142 0.1269 2.10288C0.206716 2.28433 0.321723 2.44815 0.465265 2.58486L18.9546 20.5753C19.2345 20.8476 19.6095 21 20 21C20.3905 21 20.7655 20.8476 21.0454 20.5753L39.5347 2.58485C39.6783 2.44815 39.7933 2.28433 39.8731 2.10287C39.9529 1.92142 39.996 1.72594 39.9997 1.52775C40.0035 1.32955 39.9679 1.13257 39.8951 0.948212C39.8222 0.763853 39.7135 0.595774 39.5753 0.453699C39.4371 0.311625 39.272 0.198378 39.0897 0.12051C38.9075 0.0426428 38.7115 0.00169776 38.5133 5.17223e-05C38.3151 -0.00159431 38.1185 0.0360873 37.935 0.110916C37.7514 0.185745 37.5845 0.296234 37.4439 0.435993L20 17.411Z"
      fill="#FAFAFA"
    />
  </svg>
);

export default ArrowDown;
