const Sale = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
    >
      <circle cx={7.924} cy={7.924} r={5.486} fill="#FAFAFA"/>
      <path
        fill="#EF3500"
        d="M5.714 6.286a.571.571 0 1 1 0-1.143.571.571 0 0 1 0 1.143Zm4.572 3.428a.571.571 0 1 0 0 1.143.571.571 0 0 0 0-1.143ZM16 8c0 .746-.536 1.305-1.01 1.799-.27.281-.548.571-.653.826-.097.234-.103.62-.108.996-.011.697-.023 1.487-.572 2.036-.55.55-1.34.56-2.036.572-.375.005-.762.011-.996.108-.255.105-.545.384-.826.653C9.305 15.464 8.746 16 8 16s-1.305-.536-1.799-1.01c-.281-.27-.571-.548-.826-.653-.234-.097-.62-.103-.996-.108-.697-.011-1.487-.023-2.036-.572-.55-.55-.56-1.34-.572-2.036-.005-.375-.011-.762-.108-.996-.105-.255-.384-.545-.653-.826C.536 9.305 0 8.746 0 8s.536-1.305 1.01-1.799c.27-.281.548-.571.653-.826.097-.234.103-.62.108-.996.011-.697.023-1.487.572-2.036.55-.55 1.34-.56 2.036-.572.375-.005.762-.011.996-.108.255-.105.545-.384.826-.653C6.695.536 7.254 0 8 0s1.305.536 1.799 1.01c.281.27.571.548.826.653.234.097.62.103.996.108.697.011 1.487.023 2.036.572.55.55.56 1.34.572 2.036.005.375.011.762.108.996.105.255.384.545.653.826C15.464 6.695 16 7.254 16 8ZM5.714 7.429a1.714 1.714 0 1 0 0-3.429 1.714 1.714 0 0 0 0 3.429Zm5.547-1.882a.573.573 0 0 0-.808-.808l-5.714 5.714a.571.571 0 1 0 .808.808l5.714-5.714ZM12 10.286a1.714 1.714 0 1 0-3.428 0 1.714 1.714 0 0 0 3.428 0Z"
      />
    </svg>
  )
}

export default Sale;
