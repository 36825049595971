import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import styles from './Pay.module.scss';
import {useTranslation} from "react-i18next";
import MasterLogo from "../../components/icons/profile/masterLogo";
import VisaLogo from "../../components/icons/profile/visaLogo";
import NewCardForm from "../profile/components/NewCardForm";
import IconProfileSecurity from "../../components/icons/profile/security";
import Button from "../../components/shared/Button/Button";
import Info from "../../components/icons/profile/Info";
import CustomSelect from "../../components/shared/CustomSelect/CustomSelect";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../constants/routes";

const cardsMock = 1;
const options = [
  { value: '4242424242424242', label: '4242424242424242', icon: 'master' },
  { value: '1212323212321232', label: '1212323212321232', icon: 'visa' },
  { value: '8787878787878787', label: '8787878787878787', icon: 'master' },
];

const Pay = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState('');

  const newCardCondition = (!cardsMock || selectedItem === t('creditCard'));
  const listOfCardsCondition = cardsMock;
  return (
    <>
      <div className={styles.payPageWrapper}>
        <Header/>
        <div className={styles.payForm}>
          <div className={styles.payTitle}>{t('payTitle')}</div>
          <div className={styles.payDescription}>1 {t('yearFor')} 384 {t('uah')}, {t('then')} - 49 {t('perMth')}</div>

          {listOfCardsCondition ? (
            <div className={styles.customSelectWrapper}>
              <CustomSelect
                savingKey={""}
                inputValuesHandler={() => {}}
                options={options}
                cardsFlag={true}
                outsideHandler={setSelectedItem}
              />
            </div>
          ) : null}
          {newCardCondition && <div className={styles.cardBlockTitle}>
            <div>{t('cardNumber')}</div>
            <div className={styles.cardBlockLogo}>
              <VisaLogo/>
              <MasterLogo/>
            </div>
          </div>}
          {newCardCondition && <NewCardForm showTitle/>}
          <div className={styles.submitButtonWrapper}>
            <div className={styles.submitButtonDescription}>
              <IconProfileSecurity />
              {t('paymentSecurity')}
            </div>
            <Button
              title={t('connect')}
              submit={() => navigate(ROUTES.GREETINGS)}
              className={styles.submitButton}
            />
          </div>
          {listOfCardsCondition ? (
            <div className={styles.payInfo}>
              <Info />
              <div>{t('payInfo1')} <span>"{t('oneMonth')}"</span> {t('payInfo2')} <span>"{t('oneYear')}"</span></div>
            </div>
          ) : null}
          <div className={styles.payPolicy}>{t('payPolicy')}</div>
        </div>
        <Footer source={''}/>
      </div>
    </>
  )
}

export default Pay;
