import {FC, PropsWithChildren} from "react";
import styles from "./ModalWrapper.module.scss";
import CloseModalIcon from "../../icons/profile/closeModal";
import {useDispatch} from "react-redux";
import {setOpenModal} from "../../../store/slices/modals.slice";

const ModalWrapper: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalContentWrapper}>
        <div
          className={styles.closeIconWrapper}
          onClick={() => dispatch(setOpenModal(''))}
        ><CloseModalIcon /></div>
        {children}
      </div>
    </div>
  )
}

export default ModalWrapper;
