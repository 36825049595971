const StarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
    >
      <path
        fill="#00C29F"
        d="m8 11.145 2.832 1.585-.633-3.182 2.382-2.203-3.222-.382L8 4.017v7.128Zm0 1.528-4.702 2.633 1.05-5.286L.392 6.361l5.351-.634L8 .833l2.258 4.894 5.35.634-3.956 3.659 1.05 5.286L8 12.672Z"
      />
    </svg>
  )
}

export default StarIcon
