import styles from "./SupportChatPage.module.scss";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TestimonialInput from "../../components/shared/TestimonialInput/TestimonialInput";
import React from "react";
import {useTranslation} from "react-i18next";
import AvatarBg from "../../components/icons/avatarbg";
import axiosInstance from "../../api/axios";
import {useAppSelector} from "../../store/store";
import {useDispatch} from "react-redux";
import {setSuccessToast} from "../../store/slices/modals.slice";
import SuccessToast from "../../components/shared/SuccessToast/SuccessToast";

const SupportChatPage = () => {
  const [text, setText] = React.useState('');
  const {t} = useTranslation();
  const userData = useAppSelector((state: any) => state.auth.userData);
  const dispatch = useDispatch();

  const sendHandler = async () => {
    try {
      await axiosInstance.post(`/support/`, {
        message: text
      })
      setText('');
      dispatch(setSuccessToast(true));
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div className={styles.supportPageWrapper}>
      <Header className={styles.headerSupport}/>
      <SuccessToast />
      <div className={styles.supportContent}>
        <div className={styles.supportTitle}>Підтримка користувачів</div>
        <div className={styles.supportSubTitle}>Зворотній зв'язок</div>
        <div className={styles.supportDescription}>
          Якщо виникли складнощі – скористайтеся формою.<br/>
          Ми обов'язково вирішимо будь-які питання.
        </div>

        <div className={styles.avatarBlock}>
          <div className={styles.avatarMain}>
            <AvatarBg/>
            <span>{userData?.username?.[0] || 'U'}</span>
          </div>
          <div>
            <div className={styles.avatarBlockName}>{userData?.username || 'User'}</div>
            <div className={styles.avatarBlockId}>{userData?.email || ''}</div>
          </div>
        </div>

        <div className={styles.textWrapper}>
          <TestimonialInput
            placeholder={t('addComment')}
            valueHandler={setText}
            sendHandler={sendHandler}
            avatarSize={0}
          />
        </div>
        <div className={styles.subDescription}>
          Відповідь буде надіслана Вам за вказаною при реєстрації поштою.
        </div>
      </div>
      <div style={{height: '50px'}}/>
      <Footer source={''}/>
    </div>
  )
};

export default SupportChatPage;
