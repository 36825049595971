const UploadPhoto = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
    >
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 8h.01m5.954 4.806a9 9 0 1 0-8.567 8.185"
      />
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m4 15 4-4c.928-.893 2.072-.893 3 0l4 4"
      />
      <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m14 14 1-1c.928-.893 2.072-.893 3 0m-2 6.33h6m-3-3v6"
      />
    </svg>
  )
};

export default UploadPhoto;
