interface IProps {
  className?: string
}

const FilterHeaderFilter = ({className}: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={20}
      fill="none"
    >
      <path
        className={className}
        stroke="#888"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M19.058 7.723c.948-.534 1.423-.801 1.682-1.232.26-.43.26-.949.26-1.987v-.69c0-1.326 0-1.99-.44-2.402C20.122 1 19.415 1 18 1H4c-1.414 0-2.121 0-2.56.412C1 1.824 1 2.488 1 3.815v.69c0 1.037 0 1.556.26 1.986.26.43.733.698 1.682 1.232l2.913 1.64c.636.358.955.537 1.183.735.474.411.766.895.898 1.49.064.284.064.618.064 1.285v2.67c0 .909 0 1.364.252 1.718.252.355.7.53 1.594.88 1.879.734 2.818 1.101 3.486.683.668-.417.668-1.372.668-3.282v-2.67c0-.666 0-1 .064-1.285a2.68 2.68 0 0 1 .899-1.49"
      />
    </svg>
  )
};

export default FilterHeaderFilter;
