import { ComponentProps } from "react";

interface IProps extends ComponentProps<"svg"> {}

const Filter = ({ ...props }: IProps) => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.058 7.72312C20.006 7.18912 20.481 6.92212 20.74 6.49112C21 6.06112 21 5.54212 21 4.50412V3.81412C21 2.48812 21 1.82412 20.56 1.41212C20.122 1.00012 19.415 1.00012 18 1.00012H4C2.586 1.00012 1.879 1.00012 1.44 1.41212C1 1.82412 1 2.48812 1 3.81512V4.50512C1 5.54212 1 6.06112 1.26 6.49112C1.52 6.92112 1.993 7.18912 2.942 7.72312L5.855 9.36312C6.491 9.72112 6.81 9.90012 7.038 10.0981C7.512 10.5091 7.804 10.9931 7.936 11.5881C8 11.8721 8 12.2061 8 12.8731V15.5431C8 16.4521 8 16.9071 8.252 17.2611C8.504 17.6161 8.952 17.7911 9.846 18.1411C11.725 18.8751 12.664 19.2421 13.332 18.8241C14 18.4071 14 17.4521 14 15.5421V12.8721C14 12.2061 14 11.8721 14.064 11.5871C14.1896 11.0043 14.5059 10.48 14.963 10.0971"
      stroke="#FAFAFA"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default Filter;
