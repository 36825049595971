import styles from "./Favourites.module.scss";
import SideBar from "../../components/sideBar/SideBar";
import React, {useCallback, useEffect, useState} from "react";
import PeriodModule from "../filters/components/PeriodModule";
import useInterfaceContext from "../../components/providers/interface";
import {useGetFavouritesFilmsListQuery} from "../../store/api/filmAPI";
import {EN, UA} from "../../constants/local";
import GenresModule from "../filters/components/GenresModule";
import Footer from "../../components/footer/Footer";
import useWindowSize from "../../hooks/useWindowSize";
import Loader from "../../components/loader/Loader";
import Header from "../../components/header/Header";
import {useTranslation} from "react-i18next";
import {debounce} from "../../utils/helpers";
import {useDispatch} from "react-redux";
import {setSearch} from "../../store/slices/filters.slice";
import {useAppSelector} from "../../store/store";
import MoviesList from "../search/MoviesList/MoviesList";
import SearchFieldIcon from "../../components/icons/searchIcons/searchField";
import Input from "../../components/shared/Input";
import useLikeClick from "../../hooks/useLikeClick";
import useFavouriteClick from "../../hooks/useFavouriteClick";
import SavedSmall from "../../components/icons/services/SavedSmall";
import {Link} from "react-router-dom";
import {ROUTES} from "../../constants/routes";

interface IQuery {
  language: string;
  sort_by: string;
  include_video: boolean;
  "release_date_gte"?: string;
  "release_date_lte"?: string;
  search?: string;
  with_genres?: string;
}

const Favourites = () => {
  const accessToken = localStorage.getItem("accessToken");
  const search = useAppSelector((state: any) => state.filters.search)
  const refreshFlag = useAppSelector((state: any) => state.auth.token)


  const [filmsList, setFilmsList] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [inputValue, setInputValue] = useState(search || '');
  const [isFocused, setIsFocused] = useState(false);

  const { isMobile } = useInterfaceContext();
  const { windowSize } = useWindowSize();
  const { t, i18n } = useTranslation();
  const { likesList, likeHandler } = useLikeClick();
  const { favoritesList, favouritesHandler } = useFavouriteClick();

  const dispatch = useDispatch();

  const [periodValue, setPeriodValue] = useState<number[]>([1980, 2024]);
  const [periodValueUI, setPeriodValueUI] = useState<number[]>([1980, 2024]);
  const [genresValue, setGenresValue] = useState<number[]>([])

  const clearGenresHandler = () => {
    setGenresValue([]);
  }

  const clearPeriodHandler = () => {
    setPeriodValue([1980, 2024]);
    setPeriodValueUI([1980, 2024]);
  }

  const setGenresValueHandler = (itemId: any) => {
    setGenresValue((currentIds) => {
      if (currentIds.includes(itemId)) {
        return currentIds.filter((currentId: any) => currentId !== itemId);
      } else {
        return [...currentIds, itemId];
      }
    });
  }

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setPeriodForRequest = useCallback(
    debounce((value: number[]) => {
      setPeriodValue(value);
    }, 400),
    []
  );

  const setPeriodHandler = (periodValue: number[]) => {
    setPeriodValueUI(periodValue);
    setPeriodForRequest(periodValue);
  };

  const queryHandler = () => {
    const query: IQuery = {
      language: i18n.language === 'en' ? EN : UA,
      sort_by: "popularity.desc",
      include_video: true,
    };

    if (periodValue[0] !== 1980 || periodValue[1] !== 2024) {
      query["release_date_gte"] = `${periodValue[0]}-01-01`;
      query["release_date_lte"] = `${periodValue[1]}-12-31`;
    }

    if (searchValue) {
      query.search = searchValue;
    }

    if (genresValue.length) {
      query.with_genres = genresValue.join('|');
    }

    return query;
  };

  const {data, isLoading, isFetching, error, refetch} = useGetFavouritesFilmsListQuery({
    mainFilter: '/movies/favorites',
    queries: {
      ...queryHandler(),
      page: page
    },
  });

  useEffect(() => {
    let timeout: any;
    if (refreshFlag) {
      timeout = setTimeout(() => {
        refetch();
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [refreshFlag, refetch]);

  useEffect(() => {
    if (!data || isFetching) return;
    if ((data?.results && data?.results.length) && (page > 1)) {
      setFilmsList((prevState) => [...prevState, ...data.results]);
    } else {
      setFilmsList(data.results);
    }
  }, [data, isFetching, page]);


  const setPageHandler = () => {
    setPage((prevState) => prevState + 1);
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    dispatch(setSearch(event.target.value));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchValue(inputValue);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  useEffect(() => {
    setPage(1);
  }, [genresValue, periodValue, searchValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <section>
        <Header className={styles.headerWrapper} />
        {windowSize !== "mobile" ? <SideBar /> : null}
        {accessToken ? (
          <div className={styles.container}>
            <div className={styles.leftColumnWrapper}>
              <div className={styles.leftColumnTopContent}>
                <MoviesList
                  data={filmsList}
                  error={error}
                  setPageHandler={setPageHandler}
                  isFetching={isFetching}
                  isLastData={data.next === null}
                  likeHandler={likeHandler}
                  likesList={likesList?.data?.results}
                  favoritesList={favoritesList?.data?.results}
                  favouritesHandler={favouritesHandler}
                  title={t('selected')}
                  isFavoritePage={true}
                />
              </div>
            </div>
            {windowSize !== "mobile" && (
              <div className={styles.contentWrapper}>
                <div className={`${styles.searchFieldWrapper} ${isFocused ? styles.focused : ''}`}>
                  <SearchFieldIcon />
                  <Input
                    placeHolder={t('search')}
                    className={styles.input}
                    labelClassName={styles.label}
                    value={inputValue}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleInputChange}
                  />
                </div>
                <PeriodModule
                  setPeriodValue={setPeriodHandler}
                  isMobile={!isMobile}
                  className={styles.periodModule}
                  period={periodValueUI}
                  clearFiltersHandler={clearPeriodHandler}
                />
                <GenresModule
                  setGenresValue={setGenresValueHandler}
                  isMobile={!isMobile}
                  className={styles.genresModule}
                  genres={genresValue}
                  clearFiltersHandler={clearGenresHandler}
                />
              </div>
            )}
          </div>
        ) : (
          <div className={styles.unautorisedWrapper}>
            <SavedSmall />
            <div className={styles.unautorisedTitle}>{t('loginOrReg')}</div>
            <div className={styles.unautorisedText}>{t('loginOrRegText')}</div>
            <Link to={ROUTES.LOGIN}>
              <div className={styles.unautorisedBtn}>{t('auth')}</div>
            </Link>
          </div>
        )}
        <Footer source={''}/>
      </section>
    </>
  );
};

export default Favourites;
