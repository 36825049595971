import useWindowSize from "../../hooks/useWindowSize";
import {useAppSelector} from "../../store/store";
import styles from "./Header.module.scss";
import CloseIcon from "../icons/HeaderClose";
import {useNavigate} from "react-router-dom";
import CheckMark from "../icons/forYou/CheckMark";
import {resetFilters, setGenres, setPeriod} from "../../store/slices/filters.slice";
import {ROUTES} from "../../constants/routes";
import {useDispatch} from "react-redux";

const MobileCloseModule = () => {
  const { windowSize } = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const filterChanged = useAppSelector((state: any) => state.filters.filterChanged)
  const temporaryCounter = useAppSelector((state: any) => state.filters.temporaryCounter)
  const tempGenres = useAppSelector((state: any) => state.filters.tempGenres)
  const tempPeriod = useAppSelector((state: any) => state.filters.tempPeriod)


  const submitFilters = () => {
    dispatch(setPeriod(tempPeriod))
    dispatch(setGenres(tempGenres))

    const timeout =  setTimeout(() => {
      navigate(ROUTES.FOR_YOU);
      clearTimeout(timeout);
    }, 300)
  }

  return filterChanged ? (
    <div onClick={submitFilters} className={styles.mobileApplyFilterButton}>
      <CheckMark />
      <span>{temporaryCounter}</span>
    </div>
  ) : (
    <button
      onClick={() => {
        dispatch(resetFilters())
        navigate(-1)
      }}
      className={`${windowSize === "mobile" ? styles.mobileFilterButton : styles.desctopFilterButton}`}
    >
      <CloseIcon isMobile={windowSize === "mobile"}/>
    </button>
  )
}

export default MobileCloseModule
